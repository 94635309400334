import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "py-0",
  "data-kt-customer-payment-method": "row"
}
const _hoisted_2 = { class: "py-3 d-flex align-items-center flex-wrap" }
const _hoisted_3 = ["data-bs-toggle", "href", "aria-controls"]
const _hoisted_4 = { class: "me-3 rotate-90" }
const _hoisted_5 = { class: "svg-icon svg-icon-3" }
const _hoisted_6 = ["id"]
const _hoisted_7 = { class: "py-5" }
const _hoisted_8 = {
  key: 0,
  class: "separator separator-dashed"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "d-flex align-items-center collapsible rotate collapsed w-100",
          "data-bs-toggle": _ctx.$slots.hidden ? 'collapse' : '',
          href: `#${_ctx.id}`,
          role: "button",
          "aria-expanded": "false",
          "aria-controls": _ctx.id
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, {
                src: 
                !_ctx.$slots.hidden
                  ? 'media/icons/duotune/general/gen042.svg'
                  : 'media/icons/duotune/arrows/arr071.svg'
              
              }, null, 8, ["src"])
            ])
          ]),
          _renderSlot(_ctx.$slots, "header")
        ], 8, _hoisted_3)
      ]),
      _createElementVNode("div", {
        id: _ctx.id,
        class: "fs-6 px-5 px-xl-10 collapse",
        "data-bs-parent": "#kt_customer_view_payment_method",
        style: {}
      }, [
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "hidden")
        ])
      ], 8, _hoisted_6)
    ]),
    (_ctx.showSeparator)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8))
      : _createCommentVNode("", true)
  ], 64))
}