
import useMedia from "@/core/composables/useMedia";

import { ref } from "vue";
export default {
  props: {
    src: {
      type: String,
    },
    mediaId: { type: Number },
    fileDescription: { type: String },
    required: { type: Boolean },
    label: { type: String, default: "Upload File" },
    allowRemove: { type: Boolean, default: false },
  },
  emits: ["file-selected", "file-removed"],
  setup(props, { emit }) {
    const uploader = ref();
    const { validateFile, uploadFile, toBase64 } = useMedia();

    const changeImage = async (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (!input.files?.length) {
        return;
      }
      const file = input.files[0];
      if (validateFile(file)) {
        const b64 = await toBase64(file);
        const payload: any = {
          file,
          description: props.fileDescription,
          alt: props.fileDescription,
          file_name: file.name,
          fileBlob: b64 as string,
        };

        // const payload: PreMedia = {
        //   fileBlob: b64 as string,
        //   description: props.fileDescription,
        //   alt: props.fileDescription,
        // };
        // if (props.mediaId) {
        //   payload.id = props.mediaId;
        // }
        const uploadPromise = uploadFile(payload);
        emit("file-selected", { payload, uploadPromise });

        // emit("file-uploaded", uploaded);
      }
    };
    const triggerUpload = () => {
      if (uploader.value) {
        uploader.value.click();
      }
    };
    const removeImage = () => {
      emit("file-removed");
    };
    return { uploader, triggerUpload, changeImage, removeImage };
  },
};
