
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { formatDate } from "@/filters";
import PageTitle from "@/components/PageTitle.vue";
import { KYCDocType } from "@/assets/ts/_utils/enums/doc-type.enum";
import useKYCs from "@/core/composables/useKYCs";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import KYCForm from "@/components/KYCForm/index.vue";
import { defaultBGCData } from "@/store/models/form";

export default defineComponent({
  name: "admin-kyc",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const {
      loading: loadingKYCs,
      fetchApplicantKYC,
      cleanKYCFormData,
    } = useKYCs();

    const kyc = ref<any>({
      citizenship_country: "",
      doc_type: KYCDocType.NATIONAL_IDENTITY,
      official_doc: defaultBGCData.kyc.official_doc,
      official_doc_back: defaultBGCData.kyc.official_doc_back,
      live_photo: defaultBGCData.kyc.live_photo,
      id_number: "",
    });

    const applicant = ref<any>(null);

    onMounted(async () => {
      setCurrentPageBreadcrumbs("KYC", ["KYC"]);

      const resp = await fetchApplicantKYC(route.params.userId as string);
      console.log("🚀 ~ onMounted ~ resp:", resp);
      const kycFormData: any = { current_step: resp.current_step, id: resp.id };
      Object.keys(kyc.value).forEach((k) => {
        kycFormData[k] = resp[k];
      });
      kycFormData.official_doc_file = resp.official_doc_file;
      kycFormData.official_doc_back_file = resp.official_doc_back_file;
      kycFormData.live_photo_file = resp.live_photo_file;
      kyc.value = cleanKYCFormData(kycFormData);
      console.log("🚀 ~ onMounted ~ resp.user:", resp.user);
      applicant.value = resp.user;
    });
    // console.log("🚀 ~ setup ~ applicant:", applicant);

    return {
      formatDate,
      applicant,
      kyc,
      loadingKYCs,
    };
  },
  components: { PageTitle, KYCForm },
});
