
import { PropType } from "vue";

export default {
  props: {
    label: String,
    value: { type: String as PropType<any> },
    showSeparator: { type: Boolean, default: true },
  },
  setup() {
    return {};
  },
};
