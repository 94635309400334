export enum FeeType {
  PRICE = 1,
  PERCENTAGE = 2,
}

export interface Fee {
  id: number;
  name: string;
  fee_type: number;
  from_total: boolean;
  is_active: boolean;
  amount: string | number;
}
