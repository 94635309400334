import { RouteRecordRaw } from "vue-router";
import NewApplicant from "@/components/customer/NewApplicant.vue";
import Invited from "@/components/customer/Invited.vue";
import Progress from "@/components/customer/Progress.vue";
import CustomerVerifications from "@/components/customer/CustomerVerifications.vue";
import CustomerSetupVerification from "@/components/customer/CustomerSetupVerification.vue";
import PaymentSuccess from "@/components/customer/PaymentSuccess.vue";
import KYCProgress from "@/components/customer/KYCProgress.vue";
import Dashboard from "@/components/customer/Dashboard.vue";
import FormReview from "@/components/customer/FormReview.vue";
import KYCReview from "@/components/customer/KYCReview.vue";
import ComingSoon from "@/components/ComingSoon.vue";
import Profile from "@/components/customer/Profile.vue";
import ProfileView from "@/views/profile/Profile.vue";
import IDVerification from "@/components/customer/IDVerification/IDVerification.vue";
import IDVerificationHistory from "@/components/customer/IDVerification/IDVerificationHistory.vue";
import AMLVerification from "@/components/customer/AMLVerification/AMLVerification.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "applicant/add",
    name: "customer-addemployee",
    component: NewApplicant,
  },
  {
    path: "applicant/invited/:inviteStatus?",
    name: "customer-invited",
    component: Invited,
  },
  {
    path: "progress/:progressStatus?",
    name: "customer-applicant-progress",
    component: Progress,
  },
  {
    path: "kycProgress/:progressStatus?",
    name: "customer-applicant-kyc-progress",
    component: KYCProgress,
  },
  {
    path: "dashboard",
    name: "customer-dashboard",
    component: Dashboard,
  },
  {
    path: "form/review/:formId",
    name: "customer-form-review",
    component: FormReview,
  },
  {
    path: "kyc/review/:kycId",
    name: "customer-kyc-review",
    component: KYCReview,
  },
  {
    path: "profile",
    name: "customer-profile",
    component: ProfileView,
    children: [
      {
        path: "overview",
        name: "customer-profile-overview",
        component: () => import("@/views/profile/Overview.vue"),
        meta: {
          pageTitle: "Overview",
        },
      },
      {
        path: "users",
        name: "customer-profile-users",
        component: () => import("@/components/customer/Users.vue"),
        meta: {
          pageTitle: "Users",
        },
      },
      {
        path: "subscriptions",
        name: "customer-profile-subscriptions",
        component: () => import("@/components/customer/Subscriptions.vue"),
        meta: {
          pageTitle: "Subscriptions",
        },
      },
      {
        path: "api-keys",
        name: "customer-profile-api-keys",
        component: () => import("@/components/customer/APIKeys.vue"),
        meta: {
          pageTitle: "API Keys",
        },
      },
      {
        path: "billing",
        name: "customer-profile-billing",
        component: () => import("@/components/customer/Billing.vue"),
        meta: {
          pageTitle: "Billing",
        },
      },
    ],
  },
  {
    path: "idVerifications/verify",
    name: "customer-id-verifications",
    component: IDVerification,
  },
  {
    path: "aml-verifications/verify",
    name: "customer-aml-verifications",
    component: AMLVerification,
  },
  {
    path: "idVerifications/:progressStatus?",
    name: "customer-id-verifications-history",
    component: IDVerificationHistory,
  },

  {
    path: "setup-verification",
    name: "customer-setup-verification",
    component: CustomerSetupVerification,
  },
  {
    path: "verifications/:progressStatus?",
    name: "customer-verifications",
    component: CustomerVerifications,
  },
  {
    path: "payment-callback",
    name: "customer-payment-callback",
    component: PaymentSuccess,
  },
  {
    path: "",
    name: "customer-home",
    redirect: { name: "customer-dashboard" },
  },
];

export default routes;
