
import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import PlanCondensedCard from "@/components/PlanCondensedCard.vue";
import { extractElementFormError } from "@/core/helpers/errors";

export default defineComponent({
  name: "add-address-modal",
  components: { PlanCondensedCard },
  props: {
    rowId: { type: Number, default: 0 },
    existingData: Object,
    isReview: { type: Boolean, default: false },
    amlPlans: { type: Array as PropType<Plan[]> },
  },
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const addAddressModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const includeAml = ref(false);

    const applicantForm = reactive<any>({
      first_name: "",
      last_name: "",
      email: "",
      date_of_birth: "",
      aml_plan: null,
    });

    const rules = ref({
      first_name: [
        {
          required: true,
          message: "First Name is required",
          trigger: "change",
        },
      ],
      last_name: [
        {
          required: true,
          message: "Last Name is required",
          trigger: "change",
        },
      ],
      email: [
        {
          type: "email",
          required: true,
          message: "Please input correct email address",
          trigger: "change",
        },
      ],
      date_of_birth: [
        {
          required: false,
        },
      ],
    });

    const submit = (e) => {
      e.preventDefault();
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid, fields: any) => {
        if (valid) {
          loading.value = true;
          emit("applicantUpdate", {
            rowId: props.rowId,
            formData: applicantForm,
          });
          loading.value = false;
          hideModal(addAddressModalRef.value);
        } else {
          Swal.fire({
            text: extractElementFormError(fields),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
        console.log(applicantForm);
      });
    };

    watch(
      () => props.existingData,
      (curVal, oldVal) => {
        if (curVal && curVal != oldVal) {
          applicantForm.first_name = curVal.first_name;
          applicantForm.last_name = curVal.last_name;
          applicantForm.email = curVal.email;
          applicantForm.date_of_birth = curVal.date_of_birth;
          applicantForm.aml_plan = curVal.aml_plan;
          includeAml.value = !!curVal.aml_plan;
        }
      }
    );

    watch(
      () => includeAml.value,
      (curVal, oldVal) => {
        if (!curVal) {
          applicantForm.aml_plan = null;
        }
      }
    );

    return {
      applicantForm,
      rules,
      submit,
      formRef,
      loading,
      addAddressModalRef,
      includeAml,
    };
  },
});
