import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { mapDataToLast12Months } from "@/filters";
import {
  CustomerDashboard,
  CustomerDashboardUnTransformed,
} from "@/store/models/cutomer";
import { PlanType } from "@/assets/ts/_utils/enums/plan.enum";

@Module
export default class CustomerModule extends VuexModule {
  errors = {};
  dashboardUnTransformed: CustomerDashboardUnTransformed = {
    approved: [],
    inProgress: [],
    inReview: [],
    submitted: [],
    declined: [],
    invited: [],
    kycApproved: [],
    kycInProgress: [],
    kycInReview: [],
    kycSubmitted: [],
    kycDeclined: [],
  };
  dashboard: CustomerDashboard = {
    approved: [],
    inProgress: [],
    inReview: [],
    submitted: [],
    declined: [],
    invited: [],
    kycApproved: [],
    kycInProgress: [],
    kycInReview: [],
    kycSubmitted: [],
    kycDeclined: [],
  };
  plans = [];
  dashboardFetched = false;
  fees = [];
  savedApplicants = { plan: 1, applicants: [] };

  /**
   * Get authentification errors
   * @returns array
   */
  get getCustomerErrors() {
    return this.errors;
  }

  get getCustomerDashboard() {
    return this.dashboard;
  }

  get getCustomerPlans() {
    return this.plans;
  }

  get getPaymentFees() {
    return this.fees;
  }
  get getSavedApplicants() {
    return this.savedApplicants;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_ERROR](error) {
    if (error && typeof error !== "string") {
      this.errors = { ...error };
    } else {
      this.errors = { error: ["Server Error. Please try again in a while"] };
    }
  }

  @Mutation
  [Mutations.SET_CUSTOMER_PLANS](plans) {
    this.plans = plans;
  }

  @Mutation
  [Mutations.SET_FEES](fees) {
    this.fees = fees;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_DASHBOARD_FETCHED](dashboardFetched) {
    this.dashboardFetched = dashboardFetched;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_DASHBOARD](dashboard) {
    this.dashboardUnTransformed = dashboard[0] ?? {};
    if (this.dashboardUnTransformed != undefined) {
      this.dashboard.approved = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).approved
      );
      this.dashboard.declined = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).declined
      );
      this.dashboard.inProgress = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).inProgress
      );
      this.dashboard.inReview = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).inReview
      );
      this.dashboard.invited = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).invited
      );
      this.dashboard.submitted = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).submitted
      );
      this.dashboard.kycApproved = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).kycApproved
      );
      this.dashboard.kycDeclined = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).kycDeclined
      );
      this.dashboard.kycInProgress = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).kycInProgress
      );
      this.dashboard.kycInReview = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).kycInReview
      );
      this.dashboard.kycSubmitted = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).kycSubmitted
      );
    }
  }

  @Mutation
  [Mutations.SET_CUSTOMER_DASHBOARD2](dashboard) {
    this.dashboardUnTransformed = dashboard[0] ?? {};
    if (this.dashboardUnTransformed != undefined) {
      this.dashboard.approved = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).approved
      );
      this.dashboard.declined = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).declined
      );
      this.dashboard.inProgress = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).inProgress
      );
      this.dashboard.invited = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).invited
      );
    }
  }

  @Mutation
  [Mutations.SET_APPLICANTS](applicants) {
    this.savedApplicants = applicants;
  }

  @Action
  [Actions.GET_CUSTOMER_DASHBOARD](customerId) {
    // if (this.dashboardFetched) {
    //   return;
    // }
    return ApiService.get("customer/dashboard", `?customer=${customerId}`)
      .then((response: any) => {
        this.context.commit(
          Mutations.SET_CUSTOMER_DASHBOARD,
          response.data.results
        );
        this.context.commit(Mutations.SET_CUSTOMER_DASHBOARD_FETCHED, true);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.GET_CUSTOMER_DASHBOARD2](customerId) {
    // if (this.dashboardFetched) {
    //   return;
    // }
    return ApiService.get("customer/dashboard-2", `?customer=${customerId}`)
      .then((response: any) => {
        this.context.commit(
          Mutations.SET_CUSTOMER_DASHBOARD2,
          response.data.results
        );
        this.context.commit(Mutations.SET_CUSTOMER_DASHBOARD_FETCHED, true);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.GET_CUSTOMER_PLANS](planType = PlanType.KYC) {
    return ApiService.query("plans", {
      params: { plan_type: planType },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CUSTOMER_PLANS, data.results);
        return data.results;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.GET_FEES]() {
    return ApiService.query("fees/", { params: { is_active: true } })
      .then(({ data }) => {
        console.log(data, "THEFEES");
        this.context.commit(Mutations.SET_FEES, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.SAVE_APPLICANTS](data) {
    this.context.commit(Mutations.SET_APPLICANTS, data);
  }
}
