import { FeeType } from "@/assets/ts/_utils/models/FeeModel";

const useCurrencies = () => {
  const getPlanPriceByCurrency = (plan, currencyCode) => {
    const price = plan?.prices
      ? plan?.prices.find(
          (price) => price.currency_verbose?.code === currencyCode
        )?.price
      : plan?.price;

    return +price || +(plan?.price ?? 0) || 0;
  };

  const getFeePriceByCurrency = (fee, currencyCode) => {
    if (fee.fee_type === FeeType.PERCENTAGE) return 0;

    const price = fee?.prices
      ? fee?.prices.find(
          (price) => price.currency_verbose?.code === currencyCode
        )?.price
      : fee.amount;

    return +price || +(fee?.amount ?? 0) || 0;
  };

  return { getPlanPriceByCurrency, getFeePriceByCurrency };
};

export default useCurrencies;
