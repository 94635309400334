import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-xxl-4" }
const _hoisted_3 = { class: "col-xxl-4" }
const _hoisted_4 = { class: "col-xxl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Statistics = _resolveComponent("Statistics")!
  const _component_Trends = _resolveComponent("Trends")!
  const _component_ReportChart = _resolveComponent("ReportChart")!
  const _component_BarChart = _resolveComponent("BarChart")!
  const _component_CustomerVerifications = _resolveComponent("CustomerVerifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Statistics, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "danger",
          "chart-height": "200",
          "stroke-color": "#cb1e46"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Trends, {
          "widget-classes": "card-xxl-stretch mb-5 mb-xl-10",
          "chart-color": "primary"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ReportChart, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "success",
          "chart-height": "150"
        }),
        _createVNode(_component_BarChart, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "info",
          "chart-height": "168"
        })
      ])
    ]),
    _createVNode(_component_CustomerVerifications)
  ], 64))
}