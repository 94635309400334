
import { defineComponent, ref, PropType, computed, watch } from "vue";
import { onMounted } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import useNotify from "@/core/composables/notify";

enum View {
  FORM = "FORM",
  PLANS = "PLANS",
}

export default defineComponent({
  name: "stripe-modal",
  components: {},
  props: {
    reference: { type: String },
    clientSecret: { type: String },
  },
  emits: ["success", "fail"],
  setup(props, { emit }) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const stripeModalRef = ref<null | HTMLElement>(null);
    const stripe = ref<any>(null);
    const elements = ref<any>(null);
    const submitting = ref(false);
    const stripeLoaded = ref(false);
    const { toastErrorRaw } = useNotify();

    watch(
      () => props.clientSecret,
      (curVal, oldVal) => {
        if (curVal && stripe.value) {
          const options = {
            clientSecret: curVal,
            confirmParams: {
              return_url: `${process.env.VUE_APP_URL}/customer/payment-callback`,
            },
          };

          elements.value = stripe.value.elements(options);
          const paymentElement = elements.value.create("payment");
          paymentElement.mount("#payment-element");
        }
      }
    );

    onMounted(async () => {
      const stripeInstance = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
      stripeLoaded.value = true;
      stripe.value = stripeInstance;
    });

    const handleSubmit = async (e) => {
      e.preventDefault();
      submitting.value = true;

      if (stripe.value) {
        const resp = await stripe.value.confirmPayment({
          elements: elements.value,
          redirect: "if_required",
        });

        if (resp.error === undefined) {
          emit("success", resp);
        } else {
          toastErrorRaw(resp.error);
          submitting.value = false;
        }
      }
    };

    return {
      submitButtonRef,
      modalRef,
      stripeModalRef,
      View,
      handleSubmit,
      submitting,
    };
  },
});
