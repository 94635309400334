
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { watch } from "vue";
import useNotify from "@/core/composables/notify";
import useIDVerifications from "@/core/composables/useIDVerifications";
import useClients from "@/core/composables/useClients";
import usePayments from "@/core/composables/usePayments";
import Button from "@/components/buttons/Button.vue";
import Spinner from "@/components/Spinner.vue";
import { onMounted } from "vue";

export default defineComponent({
  name: "customer-payment-callback",
  components: { Button, Spinner },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const { toastErrorRaw } = useNotify();
    const { createApplicants } = useClients();
    const { createIDVerifications } = useIDVerifications();
    const { createPayment, getPayment, getProviderPayment } = usePayments();
    const hasProcessedPayment = ref(false);
    const loading = ref(true);
    const message = ref("");

    const processPayment = async () => {
      try {
        const { payment_intent } = route.query;
        const payment = await getProviderPayment(payment_intent);

        if (payment?.status === "succeeded") {
          message.value = "Payment Successful";
          const {
            applicants = [],
            company_id,
            id_verifications = [],
          } = payment.metadata || {};
          const existingPayment = await getPayment(payment_intent);
          if (!existingPayment) {
            await createPayment({
              company: company_id,
              reference_number: payment_intent,
              amount: payment.amount,
              gateway: "STRIPE",
            });
          }

          if (applicants.length) {
            await createApplicants(JSON.parse(applicants));
          } else if (id_verifications.length) {
            await createIDVerifications(JSON.parse(id_verifications));
          }

          hasProcessedPayment.value = true;
        } else if (payment.status === "failed") {
          message.value = "Payment Failed";
        }
      } catch (error) {
        toastErrorRaw(error);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      //check if current user is authenticated
      processPayment();
    });

    const goToVerifications = () =>
      router.push({ name: "client-verifications" });

    return { goToVerifications, loading, message };
  },
});
