import { computed, getCurrentInstance, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Fee, FeeType } from "@/assets/ts/_utils/models/FeeModel";
import useCurrencies from "./useCurrencies";

const useFees = () => {
  const app = getCurrentInstance();

  const data = ref<Fee[]>([]);
  const loading = ref<boolean>(false);
  const error = ref<string>("");
  const { getFeePriceByCurrency } = useCurrencies();

  const load = () => {
    ApiService.get("fees")
      .then((resp) => {
        data.value = resp.data;
      })
      .catch(({ response }) => {
        error.value = response;
      });
  };

  load();

  const applyFees = (price, currency?) => {
    let total = 0;
    let totalPercent = 0;

    data.value.forEach((fee) => {
      if (fee.fee_type === FeeType.PRICE) {
        if (currency) {
          total += getFeePriceByCurrency(fee, "usd");
        } else {
          total += +fee.amount;
        }
      } else {
        totalPercent += +fee.amount;
      }
    });

    const percentFeePrice =
      totalPercent && price ? (totalPercent / 100) * price : 0;

    return percentFeePrice + total;
  };

  const feeBreakdown = computed(() =>
    data.value
      .map(
        (fee) =>
          `${
            fee.name
          } - ${app?.appContext.config.globalProperties.$filters.formatFee(
            fee
          )}`
      )
      .join(", ")
  );

  return { data, loading, error, load, applyFees, feeBreakdown };
};

export default useFees;
