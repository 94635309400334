import { User } from "@/store/models/user";
import { flatten } from "lodash";

export const hasPerms = (user: User, perms: string[]) => {
  if (user.is_superuser) return true;

  const userGroupPerms = flatten(
    (user.groups || []).map((group) =>
      group.permissions.map((perm) => perm.codename)
    )
  );
  const userPerms = (user.user_permissions || []).map((perm) => perm.codename);
  const allPerms = Array.from(new Set(userGroupPerms.concat(userPerms)));

  return perms.every((perm) => allPerms.includes(perm));
};

export enum PERMS {
  ADD_API_KEY = "add_apikey",
  ADD_USER = "add_user",
}
