
import { defineComponent, onMounted, ref, computed } from "vue";
import { Field, ErrorMessage, useField } from "vee-validate";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {
  docTypes,
  ghanaDocTypes,
  KYCDocType,
} from "@/assets/ts/_utils/enums/doc-type.enum";
import { GHANA_CODE } from "@/constants";

export default defineComponent({
  name: "step-1",
  components: {
    Field,
    ErrorMessage,
  },
  setup(props) {
    let countries = ref([]);
    const date_of_birth = ref("");
    const store = useStore();

    const { value: doc_type } = useField("doc_type");
    const { value: country } = useField("citizenship_country");

    const isIdCard = computed(
      () =>
        doc_type.value === KYCDocType.NATIONAL_IDENTITY ||
        doc_type.value === KYCDocType.GHANA_CARD
    );
    const isGhana = computed(
      () =>
        country.value === GHANA_CODE ||
        country.value === "Ghana" ||
        country.value === "ghana"
    );

    const isGhanaCard = computed(
      () => doc_type.value === KYCDocType.GHANA_CARD
    );
    const docTypesOptions = computed(() =>
      isGhana.value ? ghanaDocTypes : docTypes
    );

    onMounted(async () => {
      await store.dispatch(Actions.GET_COUNTRIES);
      countries.value = store.getters.getCountries;
    });

    return {
      countries,
      isIdCard,
      docTypesOptions,
      date_of_birth,
      isGhanaCard,
    };
  },
});
