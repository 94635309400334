import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_6 = {
  key: 0,
  class: "card-body pt-0"
}
const _hoisted_7 = ["onClick", "title", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
            class: "form-control form-control-solid w-250px ps-15",
            placeholder: "Search Submitted Clients"
          }, null, 544), [
            [_vModelText, _ctx.search]
          ])
        ])
      ])
    ]),
    (_ctx.tableData.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_Datatable, {
            "table-data": _ctx.tableData,
            "table-header": _ctx.tableHeader,
            "enable-items-per-page-dropdown": true
          }, {
            "cell-email": _withCtx(({ row: customer }) => [
              _createTextVNode(_toDisplayString(customer.email), 1)
            ]),
            "cell-first_name": _withCtx(({ row: customer }) => [
              _createTextVNode(_toDisplayString(customer.first_name), 1)
            ]),
            "cell-last_name": _withCtx(({ row: customer }) => [
              _createTextVNode(_toDisplayString(customer.last_name), 1)
            ]),
            "cell-created": _withCtx(({ row: customer }) => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(customer.created)), 1)
            ]),
            "cell-status": _withCtx(({ row: customer }) => [
              _createElementVNode("span", {
                class: _normalizeClass(["badge", _ctx.getBadgeClass(_ctx.formStatuses[customer.status - 1])])
              }, _toDisplayString(_ctx.formStatuses[customer.status - 1]), 3)
            ]),
            "cell-actions": _withCtx(({ row: customer }) => [
              _createElementVNode("a", {
                href: "#",
                onClick: _withModifiers(($event: any) => (_ctx.reviewForm(customer.id, customer.status, $event)), ["stop"]),
                class: "btn btn-sm btn-light btn-active-light-primary",
                title: customer.status == 1 ? 'Pending Submission' : '',
                disabled: customer.status == 1
              }, " Review ", 8, _hoisted_7)
            ]),
            _: 1
          }, 8, ["table-data", "table-header"])
        ]))
      : _createCommentVNode("", true)
  ]))
}