import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import FormModule from "@/store/modules/FormModule";
import AdminModule from "@/store/modules/AdminModule";
import CustomerModule from "@/store/modules/CustomerModule";
import KYCModule from "@/store/modules/KYCModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    FormModule,
    AdminModule,
    CustomerModule,
    KYCModule
  },
});

export default store;
