
import { defineComponent, ref, computed, PropType } from "vue";

export default defineComponent({
  components: {},
  props: {
    variant: {
      type: String as PropType<
        "primary" | "warning" | "danger" | "info" | "success"
      >,
      default: "primary",
    },
    size: {
      type: String as PropType<"sm" | "lg">,
    },
    disabled: { type: Boolean },
    label: { type: String },
    uploadCriticalText: { type: String },
    exampleSheet: { type: String },
  },
  setup(props) {
    const buttonClasses = computed(() => {
      let btnClasses = "";
      if (props.variant) {
        btnClasses += ` btn-${props.variant}`;
      }
      if (props.size) {
        btnClasses += ` btn-${props.size}`;
      }

      return btnClasses;
    });
    return { buttonClasses };
  },
});
