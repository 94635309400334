
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { User } from "@/store/models/user";
import { UserType, userTypes } from "@/constants";
import { getUserTypePrefix } from "@/filters";
import { watch } from "vue";

export default defineComponent({
  name: "profile",
  components: {},
  setup() {
    const store = useStore();
    const user = ref<User | null>(null);

    onMounted(async () => {
      if (store.getters.isUserAuthenticated) {
        user.value = store.getters.currentUser;
      }
    });

    const profileUrl = computed(() => {
      const prefix = getUserTypePrefix(store);
      const link = `/${prefix}/profile/overview`;
      return link;
    });

    const usersUrl = computed(() => {
      const prefix = getUserTypePrefix(store);
      const link = `/${prefix}/profile/users`;
      return link;
    });

    const subscriptionsUrl = computed(() => {
      const prefix = getUserTypePrefix(store);
      const link = `/${prefix}/profile/subscriptions`;
      return link;
    });

    const keysUrl = computed(() => {
      const prefix = getUserTypePrefix(store);
      const link = `/${prefix}/profile/api-keys`;
      return link;
    });

    const billingsUrl = computed(() => {
      const prefix = getUserTypePrefix(store);
      const link = `/${prefix}/profile/billing`;
      return link;
    });

    const isCompanyUser = computed(() => {
      return [UserType.CUSTOMER, UserType.COMPANY_ADMIN].includes(
        store.getters.currentUser?.user_type
      );
    });

    const isClientUser = computed(() => {
      return [UserType.CLIENT].includes(store.getters.currentUser?.user_type);
    });

    watch(
      () => store.getters.currentUser,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          user.value = curVal;
        }
      }
    );

    return {
      user,
      userTypes,
      profileUrl,
      usersUrl,
      subscriptionsUrl,
      keysUrl,
      billingsUrl,
      isCompanyUser,
      isClientUser,
    };
  },
});
