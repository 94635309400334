import { RouteRecordRaw } from "vue-router";
import FormSubmission from "@/components/admin/FormSubmission.vue";
import KYCSubmission from "@/components/admin/KYCSubmission.vue";
import FormReview from "@/components/admin/FormReview.vue";
import KYCReview from "@/components/admin/KYCReview.vue";
import Invitations from "@/components/admin/Invitations.vue";
import PeronalUsers from "@/components/admin/PersonalUsers.vue";
import Profile from "@/components/admin/Profile.vue";
import Companies from "@/components/admin/Companies.vue";
import CompanyDetails from "@/components/admin/CompanyDetails.vue";
import AddUser from "@/components/admin/AddUser.vue";
import Dashboard from "@/components/admin/Dashboard.vue";
import ComingSoon from "@/components/ComingSoon.vue";
import IDVerificationHistory from "@/components/customer/IDVerification/IDVerificationHistory.vue";
import KYC from "@/components/admin/KYC.vue";
import ProfileView from "@/views/profile/Profile.vue";
import AMLVerificationHistory from "@/components/customer/AMLVerification/AMLVerificationHistory.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "formSubmission/:status?",
    name: "admin-form-submission",
    component: FormSubmission,
  },
  {
    path: "kycSubmission/:status?",
    name: "admin-kyc-submission",
    component: KYCSubmission,
  },
  {
    path: "form/review/:formId",
    name: "admin-form-review",
    component: FormReview,
  },
  {
    path: "kyc/review/:kycId",
    name: "admin-kyc-review",
    component: KYCReview,
  },
  {
    path: "profile",
    name: "admin-profile",
    component: ProfileView,
    children: [
      {
        path: "overview",
        name: "profile-overview",
        component: () => import("@/views/profile/Overview.vue"),
        meta: {
          pageTitle: "Overview",
        },
      },
      {
        path: "users",
        name: "profile-users",
        component: () => import("@/components/customer/Users.vue"),
        meta: {
          pageTitle: "Users",
        },
      },
      {
        path: "subscriptions",
        name: "profile-subscriptions",
        component: () => import("@/components/customer/Subscriptions.vue"),
        meta: {
          pageTitle: "Subscriptions",
        },
      },
    ],
  },
  // {
  //   path: "profile",
  //   name: "admin-profile",
  //   component: Profile,
  // },
  {
    path: "users/addNewUser",
    name: "admin-users-add",
    component: AddUser,
  },
  {
    path: "users/:userType?",
    name: "admin-users",
    component: PeronalUsers,
  },
  {
    path: "subscriptions/add",
    name: "admin-subscriptions-add",
    component: ComingSoon,
  },
  {
    path: "subscriptions/view",
    name: "admin-subscriptions",
    component: ComingSoon,
  },
  {
    path: "companies",
    name: "admin-companies",
    component: Companies,
  },
  {
    path: "companies/add",
    name: "admin-companies-add",
    component: ComingSoon,
  },
  {
    path: "company/:companyId",
    name: "admin-company-details",
    component: CompanyDetails,
  },
  {
    path: "invitations/:status?",
    name: "admin-invitations",
    component: Invitations,
  },
  {
    path: "customer/customerDetails",
    name: "admin-customer-details",
    component: ComingSoon,
  },
  {
    path: "kycs/:userId",
    name: "admin-kycs",
    component: KYC,
  },
  {
    path: "dashboard",
    name: "admin-dashboard",
    component: Dashboard,
  },
  {
    path: "",
    name: "admin-home",
    redirect: { name: "admin-dashboard" },
  },
  {
    path: "idVerifications/:progressStatus?",
    name: "admin-id-verifications-history",
    component: IDVerificationHistory,
  },
  {
    path: "amlVerifications/:progressStatus?",
    name: "admin-aml-verifications-history",
    component: AMLVerificationHistory,
  },
];

export default routes;
