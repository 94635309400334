
import { defineComponent, ref, onMounted, watch } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import EmploymentModal from "@/components/client/BackgroundCheck/modals/EmploymentModal.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { IEmployment } from "@/store/models/form";

export default defineComponent({
  name: "step-5",
  components: {
    Datatable,
    EmploymentModal,
  },
  props: {
    isReview: { type: Boolean, default: false },
  },
  setup() {
    const tableHeader = ref([
      {
        name: "Job Title",
        key: "job_title",
        sortable: true,
      },
      {
        name: "Currenlty Employed",
        key: "currently_employeed",
        sortable: true,
      },
      {
        name: "Employer",
        key: "employer",
        sortable: true,
      },
      {
        name: "Reason for living",
        key: "reason_for_leaving",
        sortable: true,
      },
      {
        name: "Actions",
        key: "edit",
      },
    ]);

    const tableData = ref<Array<IEmployment>>([]);
    const store = useStore();

    const initEducationFormData = ref({
      employer: "Employer",
      phone: "phone",
      duties: "duties",
      reason_for_leaving: "",
      job_title: "",
      currently_employeed: false,
      supervisor_name: "",
      contact_supervisor: "",
      starting_pay: 0,
      final_pay: 0,
      address: {
        street_address: "",
        city: "Melbourne",
        state: "Victoria",
        zip_code: "3000",
      },
    });

    let educationFormData = ref(initEducationFormData);

    const reloadTableData = async () => {
      const formId = store.getters.getFormId;
      if (formId) {
        tableData.value = [];
        await store.dispatch(Actions.GET_EMPLOYMENT_HISTORY);
        const data = store.getters.getEmpHistory;
        tableData.value = data;
      }
    };

    onMounted(() => {
      reloadTableData();
    });

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const addRecord = () => {
      educationFormData.value = initEducationFormData.value;
    };

    const editRecord = async (id, event) => {
      event.preventDefault();
      educationFormData.value =
        tableData.value.find((x) => x.id == id) ?? educationFormData.value;
    };

    const deleteRecord = async (id, event) => {
      event.preventDefault();
      await store.dispatch(Actions.DELETE_EMPLOYMENT_HISTORY, id);
      reloadTableData();
    };

    watch(
      () => store.getters.getEmpHistory,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          tableData.value = [];
          setTimeout(() => {
            tableData.value = curVal;
          }, 2000);
        }
      }
    );

    return {
      tableData,
      tableHeader,
      deleteCustomer,
      educationFormData,
      editRecord,
      deleteRecord,
      addRecord,
    };
  },
});
