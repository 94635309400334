import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container px-0" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12 col-lg-6 mx-auto" }
const _hoisted_4 = { class: "pb-10 pb-lg-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryCard = _resolveComponent("SummaryCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_SummaryCard, {
            title: "Selected Plan",
            subtitle: $props.plan?.name,
            "right-text": 
              _ctx.$filters.formatCurrency($setup.getPlanPriceByCurrency($props.plan, 'usd'))
            ,
            icon: "/media/icons/duotune/finance/fin006.svg",
            variant: "bg-light-warning",
            color: "warning"
          }, null, 8, ["subtitle", "right-text"]),
          _createVNode(_component_SummaryCard, {
            title: "Number of Verifications",
            subtitle: $props.verifications?.length,
            "right-text": _ctx.$filters.formatCurrency($props.totalPrice),
            icon: "/media/icons/duotune/general/gen024.svg",
            variant: "bg-light-info",
            color: "info"
          }, null, 8, ["subtitle", "right-text"]),
          _createVNode(_component_SummaryCard, {
            title: "Fee Breakdown",
            subtitle: $setup.feeBreakdown,
            "right-text": _ctx.$filters.formatCurrency($props.totalFees),
            icon: "/media/icons/duotune/finance/fin010.svg",
            variant: "bg-light-danger",
            color: "danger"
          }, null, 8, ["subtitle", "right-text"]),
          _createVNode(_component_SummaryCard, {
            title: "Subtotal",
            subtitle: "Charge amount - Total Price + Total Fees",
            "right-text": _ctx.$filters.formatCurrency($props.subtotal),
            icon: "/media/icons/duotune/finance/fin010.svg",
            variant: "bg-light-success",
            color: "success",
            "right-variant": "badge-primary"
          }, null, 8, ["right-text"])
        ])
      ])
    ])
  ]))
}