

export const changeDateFormat = (inputDate: string) => {
  const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
  const match = dateRegex.exec(inputDate);

  if (match) {
    // Reformat the date as YYYY-MM-DD
    const convertedDate = `${match[3]}-${match[2]}-${match[1]}`;
    return convertedDate;
  }

  // If the input date does not match the expected format, return it unchanged
  return inputDate;
};

