export enum IDVerificationStatus {
  PENDING = 1,
  VALID = 2,
  INVALID = 3,
}

export enum AMLVerificationStatus {
  PENDING = 1,
  FOUND = 2,
  NOT_FOUND = 3,
  PARTIALLY_FOUND = 4,
}
