export interface IStep1 {
  test: string;
}

export interface IStep2 {
  first_name: string;
  middle_name: string;
  last_name: string;
  no_middle_name: boolean;
  street_address: string;
  zip_code: string;
  city: string;
  start_living_date: string;
  state: string;
  phone: string;
  ext: string;
  email: string;
  date_of_birth: string;
  SSN: string;
  ressn: string;
  no_ssn: boolean;
  birth_country: string;
  citizenship_country: string;
}

export interface IStep3 {
  self_reported_one: boolean;
  self_reported_two: boolean;
  law_violation: boolean;
  crime_city: string;
  crime_occurance_date: string | null;
  crime_state: string | null;
  crime_explanation: string | null;
}

export interface IStep4 {
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  saveCard: string;
}

export interface BlobMeta {
  id: number;
  src: string;
  url?: string;
  alt_text: string;
  media_description?: string;
}

export interface KYC extends IStep2, IStep3 {
  id: number;
  doc_type: string;
  id_number?: string;
  official_doc: BlobMeta;
  official_doc_back: BlobMeta;
  live_photo: BlobMeta;
}

export interface IAddress {
  id: number;
  street_address: string;
  zip_code: string;
  city: string;
  start_living_date: string;
  left_date: string;
  state: string;
}

export interface IEducation {
  id: number;
  institute_name: string;
  address: IAddress;
  start_date: string;
  left_date: string;
  degree_name: string;
}

export interface IEmployment {
  id: number;
  employer: string;
  phone: string;
  address: IAddress;
  duties: string;
  reason_for_leaving: string;
  job_title: string;
  currently_employeed: boolean;
  supervisor_name: string;
  contact_supervisor: string;
  starting_pay: number;
  final_pay: number;
}

export interface IAddressHistory {
  id: number;
  address: IAddress;
}

export interface IScreeningConcent {
  no_drawn_signature: boolean;
  my_signature: boolean;
  first_name: string;
  email: string;
  accpet_personal_identification: boolean;
  accept_electronic_sign: boolean;
}

export interface PersonalInfo
  extends IStep1,
    IStep2,
    IStep3,
    IStep4,
    IScreeningConcent {
  kyc: any;
  // kyc: KYC;
}

const defaultBGCData: PersonalInfo = {
  test: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  no_middle_name: false,
  street_address: "",
  zip_code: "",
  city: "",
  start_living_date: "",
  state: "",
  phone: "",
  ext: "",
  email: "",
  date_of_birth: "",
  SSN: "",
  ressn: "",
  no_ssn: false,
  birth_country: "",
  citizenship_country: "",
  self_reported_one: false,
  self_reported_two: false,
  law_violation: false,
  crime_city: "",
  crime_occurance_date: null,
  crime_state: null,
  crime_explanation: null,
  no_drawn_signature: false,
  my_signature: false,
  accpet_personal_identification: false,
  accept_electronic_sign: false,
  // additional_info: "",
  kyc: {
    id: 0,
    citizenship_country: "",
    doc_type: "",
    official_doc: {
      id: 0,
      src: "",
      alt_text: "",
      media_description: "",
    },
    official_doc_back: {
      id: 0,
      src: "",
      alt_text: "",
      media_description: "",
    },
    live_photo: {
      id: 0,
      src: "",
      alt_text: "",
      media_description: "",
    },
  },
  nameOnCard: "",
  cardNumber: "",
  cardExpiryMonth: "",
  cardExpiryYear: "",
  cardCvv: "",
  saveCard: "",
};

export { defaultBGCData };
