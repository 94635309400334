import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadInput = _resolveComponent("UploadInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UploadInput, {
      class: "mb-10",
      title: "selfie Upload",
      subtitle: "Upload your selfie",
      list: [
        'Ensure you are in a well-lit area',
        'Remove anything that covers your face (e.g., glasses, hats, or masks)',
        'Avoid having bright lights directly behind you',
        'Ensure your phone or PC is straight in front of your face (avoid angles',
      ],
      "onUpdate:file": _ctx.handleSelfieUpload,
      "onUpdate:fileObject": _cache[0] || (_cache[0] = ($event) => _ctx.updateImageObject($event, 'selfie')),
      name: "frontImage",
      mode: "selfie"
    }, null, 8, ["list", "onUpdate:file"])
  ]))
}