import { RouteRecordRaw } from "vue-router";
import Main from "@/components/client/Main.vue";
import BackgroundCheck from "@/components/client/BackgroundCheck/BackgroundCheck.vue";
import ESignDisclosure from "@/components/client/ESignDisclosure.vue";
import KYC_OLD from "@/components/client/KYC/KYC_Old.vue";
import KYC from "@/components/client/KYC/KYC.vue";
import Dashboard from "@/components/client/Dashboard.vue";
import Profile from "@/components/client/Profile.vue";
import ProfileView from "@/views/profile/Profile.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "document-check",
    name: "document-check",
    component: Main,
  },
  {
    path: "e-sign-disclosure",
    name: "e-sign-disclosure",
    component: ESignDisclosure,
  },
  {
    path: "kyc_old",
    name: "kyc_old",
    component: KYC_OLD,
  },
  {
    path: "kyc",
    name: "kyc",
    component: KYC,
  },
  {
    path: "dashboard",
    name: "client-dashboard",
    component: Dashboard,
  },
  {
    path: "captures/:token",
    name: "captures",
    component: () => import("@/views/Captures.vue"),
  },
  {
    path: "",
    name: "client-home",
    redirect: { name: "client-dashboard" },
  },
];

export default routes;
