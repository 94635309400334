
import { defineComponent, ref, onMounted, watch } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Badge from "@/components/Badge.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { formatDate, getBadgeClass, getStatusInt } from "@/filters";
import { debounce } from "lodash";
import useClients from "@/core/composables/useClients";
import useIDVerifications from "@/core/composables/useIDVerifications";
import { ProgressStatus } from "@/assets/ts/_utils/enums/progress-status.enum";
import ViewVerificationModal from "../modals/ViewVerificationModal.vue";
import { PlanType, PlanTypes } from "@/assets/ts/_utils/enums/plan.enum";
import { ADMIN, REVIEWER } from "@/constants";
import {
  IDVerification,
  AMLVerification,
} from "@/assets/ts/_utils/models/IDVerification";
import { computed } from "vue";
import useNotify from "@/core/composables/notify";
import AMLVerificationModal from "@/components/modals/AMLVerificationModal.vue";

interface IClient {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  last_modified: string;
}

export default defineComponent({
  name: "customer-progress",
  components: {
    Datatable,
    Badge,
    ViewVerificationModal,
    AMLVerificationModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const selectedVerification = ref<null | number>(null);
    const selectedAMLVerification = ref<AMLVerification | null>(null);
    const activeIDVerification = ref<IDVerification | null>(null);
    const {
      loading: loadingClients,
      loadingVerification,
      fetchClientVerifications,
      fetchClientVerification,
    } = useClients();
    const { loading: loadingIDVerification, fetchIDVerification } =
      useIDVerifications();
    const { toastError, toastSuccess, toastErrorRaw } = useNotify();

    const tableHeaderWithoutActions = ref([
      {
        name: "Client",
        key: "email",
        sortable: true,
      },
      {
        name: "Verification Type",
        key: "plan",
      },
      {
        name: "Company",
        key: "company",
      },
      {
        name: "Created Date",
        key: "created",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
      },
    ]);
    const tableHeaderWithActions = ref([
      ...tableHeaderWithoutActions.value,
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<IClient>>([]);

    const tableKey = ref(1);

    const verificationType = ref<PlanType | null>(null);

    const fetchData = async (searchVal?: string) => {
      let apiConfig: any = {};
      const { progressStatus } = route.params || {};
      if (searchVal) {
        apiConfig.search = searchVal;
      }

      if (progressStatus) {
        if (progressStatus === "inProgress") {
          apiConfig.query = `?status_in=${ProgressStatus.SUBMITTED}&status_in=${ProgressStatus.IN_PROGRESS}&status_in=${ProgressStatus.IN_REVIEW}`;
        } else {
          const status = getStatusInt(progressStatus);
          if (status) {
            apiConfig.status = status;
          }
        }
      }
      if (verificationType.value) {
        apiConfig["plan__plan_type"] = verificationType.value;
      }

      const resp = await fetchClientVerifications(apiConfig);
      tableData.value = resp;
      tableKey.value = new Date().getTime();
    };

    const resetTable = () => {
      tableData.value = [];
      tableKey.value = new Date().getTime();
    };

    onMounted(async () => {
      fetchData();
    });

    watch(
      () => route.params,
      (curVal, oldVal) => {
        if (curVal != oldVal && store.getters.currentUser.id) {
          resetTable();
          fetchData();
        }
      }
    );

    const isAdmin = computed(() => {
      return [ADMIN, REVIEWER].includes(store.getters.currentUser.user_type);
    });

    const getReviewPath = (plan, verificationId) => {
      const reviewPath =
        plan === PlanType.KYC
          ? "kyc-review"
          : plan === PlanType.BGC
          ? "form-review"
          : "";
      const paramIdKey = plan === PlanType.KYC ? "kycId" : "formId";
      if (isAdmin.value) {
        return {
          name: `admin-${reviewPath}`,
          params: { [paramIdKey]: verificationId },
        };
      }
      return {
        name: `customer-${reviewPath}`,
        params: { [paramIdKey]: verificationId },
      };
    };

    const reviewForm = (client, event) => {
      event.preventDefault();
      const { verification, status, plan_verbose } = client;

      if (
        !verification ||
        !plan_verbose ||
        status == ProgressStatus.IN_PROGRESS
      ) {
        return;
      }

      if (plan_verbose.plan_type === PlanType.ID_VERIFICATION) {
        // open modal

        return;
      }

      router.push(getReviewPath(plan_verbose.plan_type, verification.id));
    };

    const search = ref<string>("");

    const searchItems = debounce(() => {
      fetchData(search.value);
    }, 1000);

    const getClientInitials = (client) => {
      let initials = "";
      if (client.first_name) {
        initials += client.first_name[0];
      }
      if (client.last_name) {
        initials += client.last_name[0];
      }

      return initials || "-";
    };

    const viewVerification = (verificationId) => {
      selectedVerification.value = verificationId;
    };

    const handleVerificationUpdated = (verification) => {
      selectedVerification.value = null;
      toastSuccess({ text: "ID Verification has been updted successfully" });

      // const newTableData = cloneDeep(tableData.value);
      // const idx = newTableData.findIndex((d) => d.id === verification.id);
      // if (idx >= 0) {
      //   newTableData[idx] = {
      //     ...verification,
      //     uploaded_id: newTableData[idx].uploaded_id,
      //   };
      //   tableData.value = newTableData;
      //   toastSuccess({ text: "ID Verification has been updted successfully" });
      //   tableKey.value = new Date().getTime();
      // }
    };

    const handleFetchClientVerification = async (
      id: number,
      type: "aml" | "id"
    ) => {
      const verification = await fetchClientVerification(id);
      if (type === "aml") {
        selectedAMLVerification.value = verification;
      }
    };

    watch(
      () => selectedVerification.value,
      async (curVal, oldVal) => {
        if (curVal) {
          const verification = await fetchIDVerification(curVal);
          activeIDVerification.value = verification;
        }
      }
    );

    watch(
      () => verificationType.value,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          debounce(() => fetchData(), 300)();
        }
      }
    );

    return {
      tableData,
      search,
      searchItems,
      reviewForm,
      formatDate,
      getBadgeClass,
      tableHeaderWithoutActions,
      tableHeaderWithActions,
      tableKey,
      loadingClients,
      getClientInitials,
      selectedVerification,
      isAdmin,
      handleVerificationUpdated,
      PlanType,
      viewVerification,
      activeIDVerification,
      loadingIDVerification,
      verificationType,
      PlanTypes,
      selectedAMLVerification,
      handleFetchClientVerification,
      loadingVerification,
    };
  },
});
