
import { IDVerificationType } from "@/assets/ts/_utils/enums/id-verification-type.enum";
import {
  IDVerification,
  IDVerificationWithAML,
} from "@/assets/ts/_utils/models/IDVerification";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import { computed, PropType, getCurrentInstance } from "vue";
import SummaryCard from "@/components/customer/SummaryCard.vue";
import { Fee } from "@/assets/ts/_utils/models/FeeModel";
import { Subscription } from "@/assets/ts/_utils/models/SubscriptionModel";
import { AppConfig } from "@/assets/ts/_utils/models/AppConfigModel";
import useCurrencies from "@/core/composables/useCurrencies";

export default {
  components: { SummaryCard },
  props: {
    verificationType: {
      type: String as PropType<IDVerificationType | null>,
    },
    verifications: {
      type: Array as PropType<IDVerificationWithAML[]>,
    },
    plan: {
      type: Object as PropType<Plan | null>,
    },
    totalPrice: {
      type: Number,
    },
    totalFees: {
      type: Number,
    },
    subtotal: {
      type: Number,
    },
    fees: {
      type: Array as PropType<Fee[]>,
      default: () => [],
    },
    useActiveSubscription: {
      type: Boolean,
    },
    subscription: {
      type: Object as PropType<Subscription | null>,
    },
    incPTotal: {
      type: Number,
    },
    amlTotal: { type: Number, default: 0 },
  },

  setup(props) {
    const app = getCurrentInstance();
    const { getPlanPriceByCurrency } = useCurrencies();

    const feeBreakdown = computed(() =>
      props.fees
        .map(
          (fee) =>
            `${
              fee.name
            } - ${app?.appContext.config.globalProperties.$filters.formatFee(
              fee,
              "usd"
            )}`
        )
        .join(", ")
    );

    const amlApplicants = computed(
      () => props.verifications?.filter((a) => !!a.aml_plan).length
    );

    return { feeBreakdown, getPlanPriceByCurrency, amlApplicants };
  },
};
