import {
  CLIENT,
  ADMIN,
  CUSTOMER,
  REVIEWER,
  COMPANY_ADMIN,
  COMPANY_REVIEWER,
} from "@/constants";

export const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString("en-US");
};

export const getBadgeClass = (status) => {
  switch (status) {
    case "Approved":
      return "badge-light-success";
    case "Declined":
      return "badge-light-danger";
    case "In Progress":
      return "badge-light-warning";
    default:
      return "badge-light-primary";
  }
};

export const getStatusInt = (status) => {
  switch (status) {
    case "approved":
      return 2;
    case "inProgress":
      return 1;
    case "inReview":
      return 4;
    case "declined":
      return 3;
    case "submitted":
      return 5;
    default:
      return "";
  }
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getLast12Months = () => {
  const theMonths: Array<string> = monthNames;
  const today = new Date();
  const currentMonth = today.getMonth();
  const prevMonths: Array<string> = [];
  for (let i = 0; i <= currentMonth; i++) {
    prevMonths.push(theMonths[i]);
  }
  const nextMonths: Array<string> = [];
  for (let i = currentMonth + 1; i < 12; i++) {
    nextMonths.push(theMonths[i]);
  }
  return nextMonths.concat(prevMonths);
};

export const mapDataToLast12Months = (data) => {
  const last12Months = getLast12Months();
  const result: Array<number> = [];
  for (let i = 0; i < 12; i++) {
    result.push(0);
  }
  data.forEach((el) => {
    const parsedMonth = new Date(el.month).getMonth();
    const parsedMonthName = monthNames[parsedMonth];
    const parsedMonthIndex = last12Months.findIndex(
      (x) => x == parsedMonthName
    );
    result[parsedMonthIndex] = el.c;
  });

  return result;
};

export const arraySum = (data) => {
  return data.reduce((partialSum, x) => partialSum + x, 0);
};

export const rediredAsPerLoggedInUser = (store, router) => {
  let nextName = "customer-dashboard";
  const role = store.getters.currentUser
    ? store.getters.currentUser.user_type
    : "";
  if (role === CUSTOMER) {
    nextName = "customer-dashboard";
  } else if (role === CLIENT) {
    nextName = "client-dashboard";
  } else if (role === ADMIN || role === REVIEWER) {
    nextName = "admin-dashboard";
  }
  // Go to appropriate page as per user role
  router.push({ name: nextName });
};

export const rediredToProfileAsPerLoggedInUser = (store, router) => {
  let nextName = "customer-dashboard";
  const role = store.getters.currentUser
    ? store.getters.currentUser.user_type
    : "";
  if (role === CUSTOMER) {
    nextName = "customer-profile";
  } else if (role === CLIENT) {
    nextName = "client-profile";
  } else if (role === ADMIN) {
    nextName = "admin-profile";
  }
  // Go to appropriate page as per user role
  router.push({ name: nextName });
};
export const getUserTypePrefix = (store) => {
  let nextName = "customer";
  const role = store.getters.currentUser
    ? store.getters.currentUser.user_type
    : "";
  if ([CUSTOMER, COMPANY_ADMIN, COMPANY_REVIEWER].includes(role)) {
    nextName = "customer";
  } else if (role === CLIENT) {
    nextName = "client";
  } else if (role === ADMIN) {
    nextName = "admin";
  }
  // Go to appropriate page as per user role
  return nextName;
};
