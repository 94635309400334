import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = { class: "col-xl-3" }
const _hoisted_3 = { class: "col-xl-3" }
const _hoisted_4 = { class: "col-xl-3" }
const _hoisted_5 = { class: "col-xl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsWidget5 = _resolveComponent("StatisticsWidget5")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: { name: 'admin-form-submission', params: { status: 'approved' } } }, {
        default: _withCtx(() => [
          _createVNode(_component_StatisticsWidget5, {
            "widget-classes": "card-xl-stretch mb-xl-8",
            "svg-icon": "media/icons/duotune/general/gen032.svg",
            color: "white",
            "icon-color": "primary",
            title: _ctx.arraySum(_ctx.store.getters.getAdminDashboard.approved),
            description: "Approved Applicants"
          }, null, 8, ["title"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, { to: { name: 'admin-companies' } }, {
        default: _withCtx(() => [
          _createVNode(_component_StatisticsWidget5, {
            "widget-classes": "card-xl-stretch mb-xl-8",
            "svg-icon": "media/icons/duotune/ecommerce/ecm008.svg",
            color: "dark",
            "icon-color": "white",
            title: _ctx.arraySum(_ctx.store.getters.getAdminDashboard.companies),
            description: "Companies signed up so far"
          }, null, 8, ["title"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_link, { to: { name: 'admin-form-submission', params: { status: 'declined' } } }, {
        default: _withCtx(() => [
          _createVNode(_component_StatisticsWidget5, {
            "widget-classes": "card-xl-stretch mb-xl-8",
            "svg-icon": "media/icons/duotune/finance/fin006.svg",
            color: "warning",
            "icon-color": "white",
            title: _ctx.arraySum(_ctx.store.getters.getAdminDashboard.declined),
            description: "Declined"
          }, null, 8, ["title"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_router_link, { to: { name: 'admin-form-submission', params: { status: 'inReview' } } }, {
        default: _withCtx(() => [
          _createVNode(_component_StatisticsWidget5, {
            "widget-classes": "card-xl-stretch mb-xl-8",
            "svg-icon": "media/icons/duotune/graphs/gra007.svg",
            color: "info",
            "icon-color": "white",
            title: _ctx.arraySum(_ctx.store.getters.getAdminDashboard.inReview),
            description: "In Review"
          }, null, 8, ["title"])
        ]),
        _: 1
      })
    ])
  ]))
}