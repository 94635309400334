
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KTFooter",
  setup() {
    const mrbUrl = process.env.VUE_APP_HOME_PAGE_URL;
    const mrbUrlContact = process.env.VUE_APP_HOME_PAGE_URL_CONTACT;
    const mrbUrlAbout = process.env.VUE_APP_HOME_PAGE_URL_ABOUT;
    const mrbUrlTerms = process.env.VUE_APP_HOME_PAGE_URL_TERMS;
    const mrbUrlPrivacy = process.env.VUE_APP_HOME_PAGE_URL_PRIVACY;
    return {
      footerWidthFluid,
      mrbUrl,
      mrbUrlContact,
      mrbUrlAbout,
      mrbUrlTerms,
      mrbUrlPrivacy,
    };
  },
});
