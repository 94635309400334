import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import {
  CLIENT,
  ADMIN,
  CUSTOMER,
  REVIEWER,
  COMPANY_ADMIN,
  COMPANY_REVIEWER,
} from "@/constants";

export async function Client(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const allowedRoles = [CLIENT];
  if ("id_token" in localStorage) {
    await store.dispatch(Actions.USER_DATA);
    const role = store.getters.currentUser
      ? store.getters.currentUser.user_type
      : "";
    if (role !== null && role !== "" && allowedRoles.includes(role)) {
      next();
    } else {
      next({
        path: "/sign-in",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next({
      path: "/sign-in",
      query: { redirect: to.fullPath },
    });
  }
}

export async function Admin(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const allowedRoles = [ADMIN, REVIEWER];
  if ("id_token" in localStorage) {
    await store.dispatch(Actions.USER_DATA);
    const role = store.getters.currentUser
      ? store.getters.currentUser.user_type
      : "";
    if (role !== null && role !== "" && allowedRoles.includes(role)) {
      next();
    } else {
      next({
        path: "/sign-in",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next({
      path: "/sign-in",
      query: { redirect: to.fullPath },
    });
  }
}

export async function Customer(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const allowedRoles = [CUSTOMER, COMPANY_REVIEWER, COMPANY_ADMIN];
  if ("id_token" in localStorage) {
    await store.dispatch(Actions.USER_DATA);

    const role = store.getters.currentUser
      ? store.getters.currentUser.user_type
      : "";
    if (role !== null && role !== "" && allowedRoles.includes(role)) {
      next();
    } else {
      next({
        path: "/sign-in",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next({
      path: "/sign-in",
      query: { redirect: to.fullPath },
    });
  }
}

export async function loginGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if ("id_token" in localStorage) {
    let url = "/sign-in";
    await store.dispatch(Actions.USER_DATA);
    const role = store.getters.currentUser
      ? store.getters.currentUser.user_type
      : "";
    if ([CUSTOMER, COMPANY_ADMIN, COMPANY_REVIEWER].includes(role)) {
      url = "/customer/";
    } else if (role === CLIENT) {
      url = "/client/";
    } else if (role === ADMIN) {
      url = "/admin/";
    }
    next(url);
  } else {
    next();
  }
}
