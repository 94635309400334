export enum PlanType {
  KYC = 1,
  BGC = 2,
  ID_VERIFICATION = 3,
  OTHER_CARD_VERIFICATIONS = 4,
  API_KEYS = 5,
  AML_VERIFICATION = 6,
}

export const PlanTypes = Object.freeze({
  [PlanType.KYC]: "KYC",
  // [PlanType.BGC]: "Background Check",
  [PlanType.ID_VERIFICATION]: "ID Verification",
  [PlanType.AML_VERIFICATION]: "AML Verification",

  // [PlanType.ID_VERIFICATION]: "Ghana Card Verification",
  // [PlanType.OTHER_CARD_VERIFICATIONS]: "Other ID Verification",
});
