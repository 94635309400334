import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-0" }
const _hoisted_3 = { class: "card-px text-center py-20 my-10" }
const _hoisted_4 = { class: "fs-2x fw-bolder mb-10" }
const _hoisted_5 = { class: "text-gray-400 fs-5 fw-bold mb-13" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "text-center px-5" }
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_5, [
          _createElementVNode("span", { innerHTML: _ctx.description }, null, 8, _hoisted_6)
        ]),
        _createVNode(_component_router_link, {
          to: { name: 'kyc' },
          class: "btn btn-primary er fs-6 px-8 py-4"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("img", {
          src: _ctx.image,
          alt: "",
          class: "mw-100 mh-300px"
        }, null, 8, _hoisted_8)
      ])
    ])
  ]))
}