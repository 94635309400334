
import { defineComponent, ref, PropType, watch } from "vue";

import { hideModal } from "@/core/helpers/dom";
import { AppModal } from "@/assets/ts/_utils/enums/modals.enum";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import { cloneDeep } from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import useNotify from "@/core/composables/notify";
import useCurrencies from "@/core/composables/useCurrencies";

// import PlansList from "./PlansList.vue";

export default defineComponent({
  name: "plan-modal",
  // components: { PlansList },
  emits: ["update:plan", "plan-removed"],
  props: {
    plans: { type: Array as PropType<Plan[]>, default: () => [] },
    plan: { type: Object as PropType<Plan | null> },
  },
  setup(props, { emit }) {
    const customPlanModalRef = ref<HTMLDivElement | null>(null);
    const selected = ref<Plan | null | undefined>(cloneDeep(props.plan));
    const submitting = ref<boolean>(false);
    const { toastErrorRaw, toastError } = useNotify();
    const { getPlanPriceByCurrency, getFeePriceByCurrency } = useCurrencies();

    const selectPlan = (plan) => {
      if (selected.value == plan) {
        selected.value = null;
      } else {
        selected.value = cloneDeep(plan);
      }
    };

    const submit = () => {
      emit("update:plan", selected.value);
      hideModal(AppModal.PLANS_MODAL);
    };

    watch(
      () => props.plan,
      (curVal, oldVal) => {
        selected.value = curVal;
      }
    );

    const removePlan = (plan) => {
      Swal.fire({
        text: `Remove custom plan ?`,
        icon: "question",
        buttonsStyling: false,
        confirmButtonText: "Remove",
        showCloseButton: true,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.patch(`plans/${plan.id}/`, {
            is_active: false,
          } as any)
            .then(({ data }) => {
              emit("plan-removed", data);
            })
            .catch((error) => {
              toastErrorRaw(error);
            });
        }
      });
    };

    return {
      customPlanModalRef,
      selected,
      selectPlan,
      submitting,
      submit,
      removePlan,
      getPlanPriceByCurrency,
    };
  },
});
