import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { UserAuthInfo, User } from "../models/user";
import { CUSTOMER, ADMIN, REVIEWER, CLIENT } from "@/constants";

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  roles: {
    client: boolean;
    customer: boolean;
    admin: boolean;
    reviewer: boolean;
  } = {
    client: false,
    customer: false,
    admin: false,
    reviewer: false,
  };

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  get getRoles() {
    return this.roles;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    if (error && typeof error !== "string") {
      this.errors = { ...error };
    } else {
      this.errors = { error: ["Server Error. Please try again in a while"] };
    }
  }

  @Mutation
  [Mutations.SET_AUTH_TOKEN](token) {
    this.isAuthenticated = true;
    this.errors = {};
    JwtService.saveToken(token);
  }

  @Mutation
  [Mutations.SET_AUTH_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_USER_ROLES](userType: number) {
    switch (userType) {
      case ADMIN: {
        this.roles.admin = true;
        break;
      }
      case CUSTOMER: {
        this.roles.customer = true;
        break;
      }
      case CLIENT: {
        this.roles.client = true;
        break;
      }
      case REVIEWER: {
        this.roles.reviewer = true;
        break;
      }
      default: {
        this.roles.admin = false;
        this.roles.customer = false;
        this.roles.client = false;
        this.roles.reviewer = false;
        break;
      }
    }
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    ApiService.removeAuthorizationHeader();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("token-auth/", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH_TOKEN, data.token);
        ApiService.setHeader();
        return ApiService.get("user-data").then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH_USER, data[0]);
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.USER_DATA]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("user-data")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH_USER, data[0]);
          this.context.commit(Mutations.SET_USER_ROLES, data[0].user_type);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response?.data);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("signup/", credentials)
      .then(() => {
        //this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.REGISTER_REVIEWER](credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("reviewer/", credentials)
        .then(() => {
          //this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response?.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    ApiService.removeAuthorizationHeader();
    return ApiService.post("password_reset/", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.CONFIRM_RESET_PASSWORD](payload) {
    ApiService.removeAuthorizationHeader();
    return ApiService.post("password_reset/confirm/", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.CONFIRM_CLIENT_SIGNUP](payload) {
    ApiService.removeAuthorizationHeader();
    return ApiService.post("client-signup/", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
        this.context.commit(Mutations.SET_AUTH_TOKEN, data.token);
        ApiService.setHeader();
        return ApiService.get("user-data").then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH_USER, data[0]);
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.CONFIRM_REVIEWER_SIGNUP](payload) {
    ApiService.removeAuthorizationHeader();
    return ApiService.post("reviewer-signup/", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
        this.context.commit(Mutations.SET_AUTH_TOKEN, data.token);
        ApiService.setHeader();
        return ApiService.get("user-data").then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH_USER, data[0]);
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.SEND_EMAIL_OTP](payload) {
    ApiService.removeAuthorizationHeader();
    return ApiService.post("signup/send-email-otp/", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.VERIFY_EMAIL_OTP](payload) {
    ApiService.removeAuthorizationHeader();
    return ApiService.post("signup/verify-email/", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    this.context.commit(Mutations.SET_ERROR, {});
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("verify_token/", payload)
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.UPDATE_USER](payload) {
    return ApiService.patch(
      `users/${this.context.getters.currentUser?.id}/`,
      payload
    )
      .then(({ data }) => {
        console.log(data, "updated_user");
        this.context.commit(Mutations.SET_AUTH_USER, {
          ...this.context.getters.currentUser,
          ...data,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }
}
