import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex flex-stack mt-12" }
const _hoisted_2 = { class: "text-gray-700 fw-semibold fs-6 me-2" }
const _hoisted_3 = { class: "d-flex align-items-senter" }
const _hoisted_4 = { class: "text-gray-900 fw-bolder fs-6" }
const _hoisted_5 = {
  key: 0,
  class: "py-4"
}
const _hoisted_6 = {
  key: 1,
  class: "separator separator-dashed my-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString($props.label), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString($props.value), 1)
      ])
    ]),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true),
    ($props.showSeparator)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
      : _createCommentVNode("", true)
  ], 64))
}