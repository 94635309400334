
import { defineComponent } from "vue";

export default defineComponent({
  name: "review-item-header",
  props: {
    title: String,
    date: String,
    status: String,
    passed: Boolean,
  },
});
