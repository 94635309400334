
import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import useCurrencies from "@/core/composables/useCurrencies";
import { PropType } from "vue";

export default {
  props: {
    id: { type: Number },
    plan: { type: Object as PropType<Plan>, required: true },
    customClass: { type: String },
    selected: { type: Boolean },
    allowRemove: { type: Boolean, default: false },
  },
  emits: ["selected", "remove"],
  setup(props) {
    const { getPlanPriceByCurrency, getFeePriceByCurrency } = useCurrencies();

    return { getPlanPriceByCurrency };
  },
};
