
import { defineComponent } from "vue";
import { docTypes as KYCDocTypes } from "@/assets/ts/_utils/enums/doc-type.enum";
import { mapGetters } from "vuex";
import { findCountryByCode } from "@/core/helpers/countries";

export default defineComponent({
  name: "review-photo-match",
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return { KYCDocTypes };
  },
  computed: {
    ...mapGetters({
      countries: "getCountries",
    }),
    formToUse() {
      if (this.form?.kyc) {
        return this.form.kyc;
      }
      return this.form;
    },
    first_name() {
      return this.form?.first_name || this.form?.user?.first_name;
    },
    last_name() {
      return this.form?.last_name || this.form?.user?.last_name;
    },

    meta() {
      let kycMeta = this.form?.meta || this.form?.doc_metadata;
      if (kycMeta) {
        kycMeta = JSON.parse(kycMeta);
      }
      return kycMeta;
    },
    sex() {
      if (!this.meta?.sex) return this.form?.gender || "N/A";
      return this.meta.sex === "F"
        ? "Female"
        : this.meta.sex === "M"
        ? "Male"
        : this.meta.sex;
    },
    country() {
      if (this.meta?.country) {
        return this.meta.country;
      }
      if (this.form?.citizenship_country && this.countries) {
        return (
          findCountryByCode(
            this.countries || [],
            this.form?.citizenship_country
          )?.name || "N/A"
        );
      }
      return "N/A";
    },
    frontDoc() {
      const front =
        this.formToUse?.official_doc_file || this.formToUse?.official_doc;
      if (this.formToUse?.official_doc_file) {
        front.src = this.formToUse?.official_doc_file.url;
      }
      return front;
    },
    backDoc() {
      const back =
        this.formToUse?.official_doc_back_file ||
        this.formToUse?.official_doc_back;
      if (this.formToUse?.official_doc_back_file) {
        back.src = this.formToUse?.official_doc_back_file.url;
      }
      return back;
    },
    selfie() {
      const img = this.formToUse?.live_photo_file || this.formToUse?.live_photo;
      if (this.formToUse?.live_photo_file) {
        img.src = this.formToUse?.live_photo_file.url;
      }
      return img;
    },
  },
});
