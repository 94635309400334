
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import { useForm } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import Step1 from "@/components/client/KYC/steps/Step1.vue";
import Step2 from "@/components/client/KYC/steps/Step2.vue";
import Step3 from "@/components/client/KYC/steps/Step3.vue";
import Step4 from "@/components/client/KYC/steps/Step4.vue";
import Step0 from "@/components/client/KYC/steps/Step0.vue";
import { IN_PROGRESS, SUBMITTED } from "@/constants";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { KYCDocType } from "@/assets/ts/_utils/enums/doc-type.enum";
import useNotify from "@/core/composables/notify";
import useBGCs from "@/core/composables/useBGCs";
import useMedia from "@/core/composables/useMedia";

interface IStep1 {
  citizenship_country: string;
  doc_type: string;
  id_number?: string;
}

interface IStep2 {
  official_doc: string;
  official_doc_back: string;
}

interface IStep3 {
  live_photo: string;
}

interface CreateAccount extends IStep1, IStep2, IStep3 {}

const GHANA_CODE = "288";

export default defineComponent({
  name: "KYC",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step0,
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const isSubmitting = ref(false);
    const loading = ref(false);
    const store = useStore();
    const router = useRouter();
    const { toastError, toast, toastErrorRaw } = useNotify();
    const { generateDocName } = useBGCs();
    const { uploading, uploadFile, dataURLtoFile } = useMedia();

    let kycDataSubmitted = ref(false);
    const isVerifyingCard = ref(false);
    const updatedKYCDocs = ref<string[]>([]);
    const updatedKYCFileObjects = ref<{
      front?: string;
      back?: string;
      selfie?: string;
    }>({});

    const formData = ref<CreateAccount>({
      citizenship_country: "",
      doc_type: KYCDocType.NATIONAL_IDENTITY,
      official_doc: "",
      official_doc_back: "",
      live_photo: "",
      id_number: "",
    });

    const isPassport = computed(
      () => formData.value.doc_type === KYCDocType.PASSPORT
    );

    onMounted(async () => {
      loading.value = true;
      await store.dispatch(Actions.FETCH_KYCS, store.getters.currentUser.id);

      const kyc = store.getters.getKyc;
      kycDataSubmitted.value = kyc?.status && kyc?.status !== IN_PROGRESS;

      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );

      if (_stepperObj.value) {
        const newCurrStep = kyc?.current_step || 0;
        const totalNoSteps = totalSteps.value || 0;

        currentStepIndex.value =
          newCurrStep >= totalNoSteps ? totalNoSteps - 1 : newCurrStep;
        _stepperObj.value.updateCurrentStepIndex(currentStepIndex.value);
        _stepperObj.value.goNext();
      }
      loading.value = false;
    });

    const createAccountSchema = [
      Yup.object({}),
      Yup.object({
        citizenship_country: Yup.string().required().label("Country"),
        doc_type: Yup.string().required().label("Document Type"),
        id_number: Yup.string()
          .label("Enter your ID Number for verification")
          .trim()
          // .min(11, "Min characters must be 11")
          // .max(15, "Characters cannot be more than 15")
          .when("doc_type", {
            is: (doc_type) => doc_type == KYCDocType.NATIONAL_IDENTITY,
            then: Yup.string().required("ID Number is required"),
            otherwise: Yup.string(),
          }),
      }),
      Yup.object({
        official_doc: Yup.string()
          .required()
          .label("Front Image")
          .typeError("Front image is required"),
        official_doc_back: Yup.string().label("Back Image").nullable(),
      }),
      Yup.object({
        live_photo: Yup.string()
          .required("Photo is required")
          .label("Selfie Image"),
      }),
    ];

    const currentSchema = computed(() => {
      return createAccountSchema[currentStepIndex.value];
    });

    const {
      resetForm,
      handleSubmit,
      setFieldValue,
      setFieldTouched,
      validateField,
    } = useForm<IStep1 | IStep2>({
      validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const verifyCardId = async (idNumber) => {
      try {
        isVerifyingCard.value = true;
        const resp = await store.dispatch(Actions.VERIFY_CARD_ID, idNumber);
        return resp?.is_valid || false;
      } catch (error) {
        toastErrorRaw(error);
      } finally {
        isVerifyingCard.value = false;
      }
    };

    const handleStep = handleSubmit(async (values) => {
      // if (
      //   currentStepIndex.value === 1 &&
      //   (values as IStep1).doc_type === KYCDocType.NATIONAL_IDENTITY &&
      //   (values as IStep1).citizenship_country === GHANA_CODE
      // ) {
      //   const isValidID = await verifyCardId((values as IStep1).id_number);
      //   if (!isValidID) {
      //     return toastError({ text: "Unable to verify ID card number" });
      //   }
      // }

      formData.value = {
        ...formData.value,
        ...values,
      };

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const showErrorResponse = (error) => {
      Swal.fire({
        text: error,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Try again!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      });
    };

    const redirectToHomePage = () => {
      Swal.fire({
        text: "You have successfully submitted your KYC data",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        router.push({ name: "client-dashboard" });
      });
    };

    const formSubmit = async () => {
      //reset error
      isSubmitting.value = true;
      try {
        store.commit(Mutations.SET_KYC_ERROR, {});

        await createMediaAndKyc();
      } catch (error) {
        toastErrorRaw(error);
      } finally {
        isSubmitting.value = false;
      }
    };

    const createMediaAndKyc = async () => {
      let promises: any[] = [];
      const kycInfoParams: any = {
        user: store.getters.currentUser.id,
        status: SUBMITTED,
        ...formData.value,
      };

      // if (updatedKYCDocs.value.includes("front")) {
      //   const officialDocFilename = generateDocName(
      //     "official_doc",
      //     store.getters.currentUser.username
      //   );

      //   const frontDoc = {
      //     file: dataURLtoFile(formData.value.official_doc, officialDocFilename),
      //     file_name: officialDocFilename,
      //     description: `Official Doc for ${store.getters.currentUser.username}`,
      //     alt: `Official Doc for ${store.getters.currentUser.username}`,
      //   };
      //   promises.push(uploadFile(frontDoc));
      // }

      // if (updatedKYCDocs.value.includes("selfie")) {
      //   const selfieFilename = generateDocName(
      //     "live_photo",
      //     store.getters.currentUser.username
      //   );

      //   const selfie = {
      //     file: dataURLtoFile(formData.value.live_photo, selfieFilename),
      //     file_name: selfieFilename,
      //     description: `Live photo for ${store.getters.currentUser.username}`,
      //     alt: `Live photo for ${store.getters.currentUser.username}`,
      //   };

      //   promises.push(uploadFile(selfie));
      // }

      // if (updatedKYCDocs.value.includes("back")) {
      //   const officialDocBackFilename = generateDocName(
      //     "official_doc_back",
      //     store.getters.currentUser.username
      //   );

      //   const backDoc = {
      //     file: dataURLtoFile(
      //       formData.value.official_doc_back,
      //       officialDocBackFilename
      //     ),
      //     file_name: officialDocBackFilename,
      //     description: `Official Doc Back for ${store.getters.currentUser.username}`,
      //     alt: `Official Doc Back for ${store.getters.currentUser.username}`,
      //   };
      //   promises.push(uploadFile(backDoc));
      // }

      try {
        const { official_doc, official_doc_back, live_photo, ...data } =
          kycInfoParams;
        console.log("🚀 ~ createMediaAndKyc ~ kycInfoParams:", kycInfoParams);
        await store.dispatch(Actions.SAVE_KYC, {
          ...data,
          official_doc_file: official_doc,
          official_doc_back_file: official_doc_back,
          live_photo_file: live_photo,
        });
        checkError();
        updatedKYCDocs.value = [];
      } catch (error) {
        console.log("🚀 ~ createMediaAndKyc ~ error:", error);
      }

      // return Promise.all(promises).then(
      //   async ([officialDocFile, selfieFile, officialDocBackFile]) => {
      //     if (officialDocFile) {
      //       kycInfoParams.official_doc_file = officialDocFile.id;
      //     }
      //     if (selfieFile) {
      //       kycInfoParams.live_photo_file = selfieFile.id;
      //     }

      //     if (officialDocBackFile) {
      //       kycInfoParams.official_doc_back_file = officialDocBackFile.id;
      //     }

      //     const { official_doc, official_doc_back, live_photo, ...data } =
      //       kycInfoParams;

      //     await store.dispatch(Actions.SAVE_KYC, data);

      //     checkError();
      //     updatedKYCDocs.value = [];
      //   }
      // );
    };

    const checkError = () => {
      const [errorName] = Object.keys(store.getters.getKycErrors);
      const error = store.getters.getKycErrors[errorName];

      if (error) {
        showErrorResponse(error);
        return;
      }

      redirectToHomePage();
    };

    const updateFrontField = (fieldValue) => {
      setFieldValue("official_doc" as never, fieldValue as never);
      setFieldTouched("official_doc" as never, true);
      validateField("official_doc" as never);
      updatedKYCDocs.value.push("front");
    };

    const updateBackField = (fieldValue) => {
      setFieldValue("official_doc_back" as never, fieldValue as never);
      updatedKYCDocs.value.push("back");
    };

    const updateLiveField = (fieldValue) => {
      setFieldValue("live_photo" as never, fieldValue as never);
      setFieldTouched("live_photo" as never, true);
      validateField("live_photo" as never);
      updatedKYCDocs.value.push("selfie");
    };

    watch(
      () => isSubmitting.value,
      (curVal, oldVal) => {
        if (curVal) {
          toast({
            title: "Processing KYC",
            text: "Your documents are now being uploaded and KYC processed",
            timer: 15000,
            timerProgressBar: true,
            icon: "info",
            showConfirmButton: false,
          });
        } else {
          if (Swal.isVisible()) {
            Swal.close();
          }
        }
      }
    );

    watch(
      () => isVerifyingCard.value,
      (curVal, oldVal) => {
        if (curVal) {
          toast({
            title: "Verifying ID",
            text: "Your ID is being verified for processing.",
            timer: 2000,
            timerProgressBar: true,
            icon: "info",
            showConfirmButton: false,
          });
        } else {
          if (Swal.isVisible()) {
            Swal.close();
          }
        }
      }
    );

    const updateFileObject = ({
      value,
      type,
    }: {
      value: string;
      type: "front" | "back" | "selfie";
    }) => {
      updatedKYCFileObjects.value[type] = value;
    };

    return {
      store,
      horizontalWizardRef,
      previousStep,
      handleStep,
      formSubmit,
      totalSteps,
      currentStepIndex,
      updateFrontField,
      updateBackField,
      updateLiveField,
      kycDataSubmitted,
      isSubmitting,
      isPassport,
      loading,
      updatedKYCDocs,
      updateFileObject,
      updatedKYCFileObjects,
    };
  },
});
