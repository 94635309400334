import { DashboardStats } from "@/constants";

const CustomerMainMenuConfig = [
  // {
  //   pages: [
  //     {
  //       heading: "Dashboard",
  //       route: "/customer/dashboard",
  //       svgIcon: "media/icons/duotune/art/art002.svg",
  //       fontIcon: "bi-app-indicator",
  //     },
  //   ],
  // },
  {
    heading: "Get Started",
    pages: [
      // {
      //   heading: "Get Started",
      //   route: "/customer",
      //   svgIcon: "media/icons/duotune/communication/com006.svg",
      //   fontIcon: "bi-person",
      // },
      {
        heading: "Dashboard",
        route: "/customer/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        sectionTitle: "Get Started",
        route: "/customer/setup-verification",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Add Verification",
            route: "/customer/setup-verification",
            svgIcon: "media/icons/duotune/communication/com006.svg",
            fontIcon: "bi-person",
          },
          {
            heading: "In Progress",
            route: "/customer/verifications/inProgress",
            counter: DashboardStats.VERIFICATION_PENDING_SUBMISSION, // enum str misleading. Actually contains submitted, in review as well as pending submissions
            svgIcon: "media/icons/duotune/communication/com006.svg",
            fontIcon: "bi-person",
          },
          {
            heading: "Approved",
            route: "/customer/verifications/approved",
            counter: DashboardStats.VERIFICATION_APPROVED,
            svgIcon: "media/icons/duotune/communication/com006.svg",
            fontIcon: "bi-person",
          },
          {
            heading: "Declined",
            route: "/customer/verifications/declined",
            counter: DashboardStats.VERIFICATION_DECLINED,
            svgIcon: "media/icons/duotune/communication/com006.svg",
            fontIcon: "bi-person",
          },
          {
            heading: "All Verifications",
            route: "/customer/verifications",
            svgIcon: "media/icons/duotune/communication/com006.svg",
            fontIcon: "bi-person",
          },
        ],
      },
    ],
  },
  {
    heading: "Account",
    route: "/customer",
    pages: [
      {
        sectionTitle: "Account",
        route: "/customer/api-keys",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "API Keys",
            route: "/customer/profile/api-keys",
          },
          {
            heading: "User Management",
            route: "/customer/profile/users",
          },
          {
            heading: "Subscriptions",
            route: "/customer/profile/subscriptions",
          },
          {
            heading: "Billing",
            route: "/customer/profile/billing",
          },
        ],
      },
    ],
  },

  // {
  //   heading: "Applicants",
  //   route: "/customer",
  //   pages: [
  //     {
  //       sectionTitle: "KYC Verification",
  //       route: "/customer/applicant",
  //       svgIcon: "media/icons/duotune/communication/com006.svg",
  //       fontIcon: "bi-person",
  //       sub: [
  //         {
  //           heading: "Verifications",
  //           route: "/customer/applicant/add",
  //         },
  //         // {
  //         //   heading: "Accepted Applicants",
  //         //   route: "/customer/applicant/invited/approved",
  //         // },
  //         // {
  //         //   heading: "Pending Candidates",
  //         //   route: "/customer/kycProgress/inProgress",
  //         //   counter: DashboardStats.KYC_VERIFICATION_PENDING_SUBMISSION,
  //         // },
  //         {
  //           heading: "In Progress",
  //           route: "/customer/kycProgress/inProgress",
  //           counter: DashboardStats.KYC_VERIFICATION_PENDING_REVIEW,
  //         },
  //         // {
  //         //   heading: "In Review",
  //         //   route: "/customer/kycProgress/inReview",
  //         //   counter: DashboardStats.KYC_VERIFICATION_IN_REVIEW
  //         // },
  //         {
  //           heading: "Approved",
  //           route: "/customer/kycProgress/approved",
  //           counter: DashboardStats.KYC_VERIFICATION_APPROVED,
  //         },
  //         {
  //           heading: "Declined",
  //           route: "/customer/kycProgress/declined",
  //           counter: DashboardStats.KYC_VERIFICATION_DECLINED,
  //         },
  //         {
  //           heading: "My Applicants",
  //           route: "/customer/applicant/invited",
  //         },
  //       ],
  //     },
  //     {
  //       sectionTitle: "ID Verifications",
  //       route: "/customer/idVerifications",
  //       svgIcon: "media/icons/duotune/communication/com006.svg",
  //       fontIcon: "bi-person",
  //       sub: [
  //         {
  //           heading: "Verify IDs",
  //           route: "/customer/idVerifications/verify",
  //         },
  //         {
  //           heading: "In Progress",
  //           route: "/customer/idVerifications/pending",
  //         },
  //         {
  //           heading: "Approved",
  //           route: "/customer/idVerifications/valid",
  //         },
  //         {
  //           heading: "Declined",
  //           route: "/customer/idVerifications/invalid",
  //         },
  //         {
  //           heading: "All Verifications",
  //           route: "/customer/idVerifications/all",
  //         },
  //       ],
  //     },
  //     // {
  //     //   sectionTitle: "KYC Verifications",
  //     //   route: "/customer/kycProgress",
  //     //   svgIcon: "media/icons/duotune/communication/com006.svg",
  //     //   fontIcon: "bi-person",
  //     //   sub: [
  //     //     {
  //     //       heading: "Pending Candidates",
  //     //       route: "/customer/kycProgress/inProgress",
  //     //       counter: DashboardStats.KYC_VERIFICATION_PENDING_SUBMISSION,
  //     //     },
  //     //     {
  //     //       heading: "In Progress",
  //     //       route: "/customer/kycProgress/submitted",
  //     //       counter: DashboardStats.KYC_VERIFICATION_PENDING_REVIEW,
  //     //     },
  //     //     // {
  //     //     //   heading: "In Review",
  //     //     //   route: "/customer/kycProgress/inReview",
  //     //     //   counter: DashboardStats.KYC_VERIFICATION_IN_REVIEW
  //     //     // },
  //     //     {
  //     //       heading: "Approved",
  //     //       route: "/customer/kycProgress/approved",
  //     //       counter: DashboardStats.KYC_VERIFICATION_APPROVED,
  //     //     },
  //     //     {
  //     //       heading: "Declined",
  //     //       route: "/customer/kycProgress/declined",
  //     //       counter: DashboardStats.KYC_VERIFICATION_DECLINED,
  //     //     },
  //     //   ],
  //     // },
  //   ],
  // },
];

export default CustomerMainMenuConfig;
