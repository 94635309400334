
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { Field, ErrorMessage, useField } from "vee-validate";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {
  docTypes,
  ghanaDocTypes,
  KYCDocType,
} from "@/assets/ts/_utils/enums/doc-type.enum";
import { GHANA_CODE } from "@/constants";

export default defineComponent({
  name: "step-1",
  components: {
    Field,
    ErrorMessage,
  },
  props: ["kyc"],
  setup(props) {
    let formData = ref({});

    let countries = ref([]);
    const store = useStore();

    const { value: doc_type } = useField("doc_type");
    const { value: country } = useField("citizenship_country");

    const isIdCard = computed(
      () =>
        doc_type.value === KYCDocType.NATIONAL_IDENTITY ||
        doc_type.value === KYCDocType.GHANA_CARD
    );
    const isGhana = computed(
      () =>
        country.value === GHANA_CODE ||
        country.value === "Ghana" ||
        country.value === "ghana"
    );

    const isGhanaCard = computed(
      () => doc_type.value === KYCDocType.GHANA_CARD
    );

    const docTypesOptions = computed(() =>
      isGhana.value ? ghanaDocTypes : docTypes
    );

    watch(
      () => props.kyc,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          formData.value = {
            citizenship_country: curVal.citizenship_country,
            doc_type: curVal.doc_type,
            id_number: curVal.id_number,
          };
        }
      }
    );

    onMounted(async () => {
      await store.dispatch(Actions.GET_COUNTRIES);
      countries.value = store.getters.getCountries;
    });

    return {
      countries,
      isIdCard,
      docTypesOptions,
      formData,
      isGhanaCard,
    };
  },
});
