
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import CustomerMainMenuConfig from "@/core/config/CustomerMainMenuConfig";
import AdminMainMenuConfig from "@/core/config/AdminMainMenuConfig";
import KycClientMainMenuConfig from "@/core/config/KycClientMainMenuConfig";
import ReviewMainMenuConfig from "@/core/config/ReviewMainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import { version } from "@/core/helpers/documentation";
import { CLIENT, ADMIN, CUSTOMER, REVIEWER } from "@/constants";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const store = useStore();

    const getConfigAsPerRole = computed(() => {
      const role = store.getters.currentUser
        ? store.getters.currentUser.user_type
        : "";
      if (role === CUSTOMER) {
        return CustomerMainMenuConfig;
      } else if (role === CLIENT) {
        //TOOD: if kyc enabled add to config
        return KycClientMainMenuConfig;
      } else if (role === ADMIN) {
        return AdminMainMenuConfig;
      } else if (role === REVIEWER) {
        return ReviewMainMenuConfig;
      }
      return MainMenuConfig;
    });

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      translate,
      version,
      getConfigAsPerRole,
    };
  },
});
