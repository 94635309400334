import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59849961"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feature-text" }
const _hoisted_2 = {
  key: 1,
  class: "mt-2 text-muted d-block feature-text"
}
const _hoisted_3 = {
  key: 2,
  class: "d-flex gap-3 mt-4 flex-wrap w-75"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["feature-card mb-6 p-4 ribbon rounded", { selected: $props.selected }]),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('selected')))
  }, [
    ($props.allowRemove)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "svg-icon svg-icon-1 svg-icon-danger feature-delete",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('remove')), ["stop"]))
        }, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen042.svg" })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["ribbon-label rounded", [!$props.selected ? 'bg-primary' : 'bg-light-primary text-primary']])
    }, _toDisplayString(_ctx.$filters.formatCurrency(
          $props.plan.is_custom
            ? $props.plan.currency_prices_by_features?.usd || $props.plan.price_by_features
            : $setup.getPlanPriceByCurrency($props.plan, "usd")
        )), 3),
    _createElementVNode("div", _hoisted_1, _toDisplayString($props.plan?.name), 1),
    ($props.plan?.detail)
      ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString($props.plan?.detail), 1))
      : _createCommentVNode("", true),
    ($props.plan.features.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.plan.features, (feature) => {
            return (_openBlock(), _createElementBlock("span", {
              class: "badge badge-light-primary",
              key: feature.id
            }, _toDisplayString(feature.name), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}