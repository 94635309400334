import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import useNotify from "./notify";
import { SubscriptionScope } from "@/assets/ts/_utils/enums/subscription.enum";

const useSubscriptions = () => {
  const loading = ref<boolean>(false);

  const fetchActiveSubscription = async (
    params = { scope: SubscriptionScope.ID_VERIFICATION }
  ) => {
    const { toastErrorRaw } = useNotify();

    try {
      loading.value = true;
      const resp = await ApiService.query("active-subscription/", { params });
      return resp.data;
    } catch (err: any) {
      toastErrorRaw(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchSubscriptions = async () => {
    const { toastErrorRaw } = useNotify();

    try {
      loading.value = true;
      const resp = await ApiService.get("subscriptions");
      return resp.data.results;
    } catch (err: any) {
      toastErrorRaw(err);
    } finally {
      loading.value = false;
    }
  };

  const isSubscriptionExceeded = (subscription, total) => {
    return subscription.count_used + total > subscription.limit;
  };

  return {
    loading,
    fetchActiveSubscription,
    fetchSubscriptions,
    isSubscriptionExceeded,
  };
};

export default useSubscriptions;
