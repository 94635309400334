
import { defineComponent, onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import CustomerMainMenuConfig from "@/core/config/CustomerMainMenuConfig";
import AdminMainMenuConfig from "@/core/config/AdminMainMenuConfig";
import KycClientMainMenuConfig from "@/core/config/KycClientMainMenuConfig";
import { CLIENT, ADMIN, CUSTOMER, REVIEWER } from "@/constants";
import { DashboardStats } from "@/constants";
import { arraySum } from "@/filters";
import { Actions } from "@/store/enums/StoreEnums";
import ReviewMainMenuConfig from "@/core/config/ReviewMainMenuConfig";
import { AppEvent } from "@/assets/ts/_utils/enums/custom-events.enum";
import useEmitter from "@/core/composables/emitter";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const store = useStore();
    const emitter = useEmitter();

    const scrollElRef = ref<null | HTMLElement>(null);

    const getConfigAsPerRole = computed(() => {
      const role = store.getters.currentUser
        ? store.getters.currentUser.user_type
        : "";
      if (role === CUSTOMER) {
        return CustomerMainMenuConfig;
      } else if (role === CLIENT) {
        //TOOD: if kyc enabled add to config
        return KycClientMainMenuConfig;
      } else if (role === ADMIN) {
        return AdminMainMenuConfig;
      } else if (role === REVIEWER) {
        return ReviewMainMenuConfig;
      }
      return MainMenuConfig;
    });

    onMounted(async () => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
      const role = store.getters.currentUser
        ? store.getters.currentUser.user_type
        : "";
      if (role == ADMIN) {
        await store.dispatch(
          Actions.GET_ADMIN_DASHBOARD,
          store.getters.currentUser.id
        );
      } else if (role === CUSTOMER) {
        await store.dispatch(
          Actions.GET_CUSTOMER_DASHBOARD2,
          store.getters.currentUser.id
        );
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const getCounter = (counter) => {
      const role = store.getters.currentUser
        ? store.getters.currentUser.user_type
        : "";
      if (counter) {
        switch (counter) {
          case DashboardStats.VERIFICATION_PENDING_SUBMISSION:
            return role == ADMIN
              ? arraySum(store.getters.getAdminDashboard.inProgress)
              : arraySum(store.getters.getCustomerDashboard.inProgress);
          case DashboardStats.VERIFICATION_PENDING_REVIEW:
            return role == ADMIN
              ? arraySum(store.getters.getAdminDashboard.submitted)
              : arraySum(store.getters.getCustomerDashboard.submitted);
          case DashboardStats.VERIFICATION_IN_REVIEW:
            return role == ADMIN
              ? arraySum(store.getters.getAdminDashboard.inReview)
              : arraySum(store.getters.getCustomerDashboard.inReview);
          case DashboardStats.VERIFICATION_DECLINED:
            return role == ADMIN
              ? arraySum(store.getters.getAdminDashboard.declined)
              : arraySum(store.getters.getCustomerDashboard.declined);
          case DashboardStats.VERIFICATION_APPROVED:
            return role == ADMIN
              ? arraySum(store.getters.getAdminDashboard.approved)
              : arraySum(store.getters.getCustomerDashboard.approved);
          case DashboardStats.COMPANIES:
            return role == ADMIN
              ? arraySum(store.getters.getAdminDashboard.companies)
              : 0;
          case DashboardStats.INVITED_DONE:
            return role == ADMIN
              ? arraySum(store.getters.getAdminDashboard.acceptedInvites)
              : arraySum(store.getters.getCustomerDashboard.invited);
          case DashboardStats.INVITED_PENDING_REVIEW:
            return role == ADMIN
              ? arraySum(store.getters.getAdminDashboard.invited)
              : arraySum(store.getters.getCustomerDashboard.invited);
          //kyc
          case DashboardStats.KYC_VERIFICATION_PENDING_SUBMISSION:
            return role == ADMIN
              ? arraySum(store.getters.getAdminDashboard.kycInProgress)
              : arraySum(store.getters.getCustomerDashboard.kycInProgress);
          case DashboardStats.KYC_VERIFICATION_PENDING_REVIEW:
            return role == ADMIN
              ? arraySum(store.getters.getAdminDashboard.kycSubmitted)
              : arraySum(store.getters.getCustomerDashboard.kycSubmitted);
          case DashboardStats.KYC_VERIFICATION_IN_REVIEW:
            return role == ADMIN
              ? arraySum(store.getters.getAdminDashboard.kycInReview)
              : arraySum(store.getters.getCustomerDashboard.kycInReview);
          case DashboardStats.KYC_VERIFICATION_DECLINED:
            return role == ADMIN
              ? arraySum(store.getters.getAdminDashboard.kycDeclined)
              : arraySum(store.getters.getCustomerDashboard.kycDeclined);
          case DashboardStats.KYC_VERIFICATION_APPROVED:
            return role == ADMIN
              ? arraySum(store.getters.getAdminDashboard.kycApproved)
              : arraySum(store.getters.getCustomerDashboard.kycApproved);
          default:
            return 0;
        }
      }
    };
    const handleRouterSubLinkClick = (subLink) => {
      emitter.emit(AppEvent.SUB_LINK_CLICKED, subLink);
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
      getConfigAsPerRole,
      getCounter,
      handleRouterSubLinkClick,
    };
  },
});
