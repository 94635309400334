import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createBlock as _createBlock, vShow as _vShow, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6 align-items-center" }
const _hoisted_3 = ["data-bs-target"]
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_6 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_7 = ["onClick", "title", "disabled"]
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_UpdateKYCStatusModal = _resolveComponent("UpdateKYCStatusModal")!
  const _component_AdminCreateClient = _resolveComponent("AdminCreateClient")!
  const _component_KYCReview = _resolveComponent("KYCReview")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "btn btn-primary",
          "data-bs-toggle": "modal",
          "data-bs-target": `#${_ctx.$modals.ADMIN_CLIENT_CREATE_MODAL}`
        }, " Add Applicant ", 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              class: "form-control form-control-solid w-250px ps-15",
              placeholder: "Search Kyc Applicants"
            }, null, 544), [
              [_vModelText, _ctx.search]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["card-body pt-0", { 'opacity-50': _ctx.loadingKYCs }])
      }, [
        (_openBlock(), _createBlock(_component_Datatable, {
          "table-data": _ctx.tableData,
          "enable-items-per-page-dropdown": true,
          "table-header": 
          _ctx.allowReviewByStatus
            ? _ctx.tableHeaderWithActions
            : _ctx.tableHeaderWithoutActions
        ,
          key: _ctx.tableKey,
          loading: _ctx.loadingKYCs
        }, _createSlots({
          "cell-email": _withCtx(({ row: customer }) => [
            _createTextVNode(_toDisplayString(customer.user.email), 1)
          ]),
          "cell-first_name": _withCtx(({ row: customer }) => [
            _createTextVNode(_toDisplayString(customer.user.first_name), 1)
          ]),
          "cell-last_name": _withCtx(({ row: customer }) => [
            _createTextVNode(_toDisplayString(customer.user.last_name), 1)
          ]),
          "cell-created": _withCtx(({ row: customer }) => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(customer.created)), 1)
          ]),
          "cell-status": _withCtx(({ row: customer }) => [
            _createElementVNode("span", {
              class: _normalizeClass(["badge", _ctx.getBadgeClass(_ctx.formStatuses[customer.status - 1])])
            }, _toDisplayString(_ctx.formStatuses[customer.status - 1]), 3)
          ]),
          "cell-actions": _withCtx(({ row: customer }) => [
            (_ctx.allowedStatusesToReview.includes(customer.status))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  onClick: _withModifiers(($event: any) => (_ctx.reviewForm(customer.id, customer.status, $event)), ["stop"]),
                  class: "btn btn-sm btn-bg-light btn-active-color-primary",
                  title: customer.status == 1 ? 'Pending Submission' : '',
                  disabled: !_ctx.allowedStatusesToReview.includes(customer.status)
                }, " Open ", 8, _hoisted_7))
              : (_openBlock(), _createElementBlock("span", _hoisted_8, " Yet to be reviewed "))
          ]),
          _: 2
        }, [
          (_ctx.isOngoingStatus)
            ? {
                name: "cell-edit",
                fn: _withCtx(({ row: customer }) => [
                  _createVNode(_component_el_select, {
                    placeholder: "Perform an action",
                    size: "small",
                    type: "success",
                    class: "border-active"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_option, {
                        label: "Edit KYC",
                        onClick: ($event: any) => (
                _ctx.$router.push({
                  name: 'admin-kycs',
                  params: { userId: customer.user.id },
                })
              )
                      }, null, 8, ["onClick"]),
                      _createVNode(_component_el_option, {
                        label: "Edit Progess",
                        onClick: ($event: any) => (_ctx.openKYCUpdateModal(customer))
                      }, null, 8, ["onClick"]),
                      _createVNode(_component_el_option, {
                        label: "Change Status",
                        onClick: ($event: any) => (_ctx.openKYCUpdateModal(customer))
                      }, null, 8, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["table-data", "table-header", "loading"]))
      ], 2)
    ], 512), [
      [_vShow, !_ctx.showReviewForm]
    ]),
    _createVNode(_component_UpdateKYCStatusModal, {
      kyc: _ctx.selectedKYCForUpdate,
      onSaved: _cache[2] || (_cache[2] = () => _ctx.fetchData())
    }, null, 8, ["kyc"]),
    _createVNode(_component_AdminCreateClient, {
      onSaved: _ctx.handleClientCreated,
      "plan-type": _ctx.PlanType.KYC
    }, null, 8, ["onSaved", "plan-type"]),
    (_ctx.showReviewForm)
      ? (_openBlock(), _createBlock(_component_KYCReview, {
          key: 0,
          reviewDisable: _ctx.reviewDisable
        }, null, 8, ["reviewDisable"]))
      : _createCommentVNode("", true)
  ], 64))
}