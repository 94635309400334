
import { defineComponent } from "vue";

export default defineComponent({
  name: "page-title",
  props: {
    showHelp: { type: Boolean, default: true },
  },
  components: {},
});
