import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import AdminMain from "@/components/admin/Main.vue";
import CustomerMain from "@/components/customer/Main.vue";
import ClientMain from "@/components/client/Main.vue";
import { Client, Customer, Admin } from "@/routes/route-guards";
import customerRoutes from "@/routes/customer";
import adminRoutes from "@/routes/admin";
import clientRoutes from "@/routes/client";
import publicRoutes from "@/routes/public";
import KycClientLayoutConfig from "@/core/config/KycClientLayoutConfig";
import customerLayoutConfig from "@/core/config/CustomerLayoutConfig";
import adminLayoutConfig from "@/core/config/AdminLayoutConfig";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/admin",
    name: "admin",
    component: AdminMain,
    children: [...adminRoutes],
    beforeEnter: Admin,
  },
  {
    path: "/customer",
    name: "customer",
    component: CustomerMain,
    beforeEnter: Customer,
    children: [...customerRoutes],
  },
  {
    path: "/client",
    name: "client",
    component: ClientMain,
    beforeEnter: Client,
    children: [...clientRoutes],
  },
  ...publicRoutes,
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // reset config to initial state
  if (to && to.path.startsWith("/client")) {
    store.commit(Mutations.SET_LAYOUT_CONFIG, KycClientLayoutConfig);
  } else if (to && to.path.startsWith("/customer")) {
    store.commit(Mutations.SET_LAYOUT_CONFIG, customerLayoutConfig);
  } else if (to && to.path.startsWith("/admin")) {
    store.commit(Mutations.SET_LAYOUT_CONFIG, adminLayoutConfig);
  }

  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
