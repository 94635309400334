
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import { cloneDeep } from "lodash";

import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import KYCReview from "@/components/admin/KYCReview.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";
import { formatDate, getBadgeClass, getStatusInt } from "@/filters";
import { showModal } from "@/core/helpers/dom";
import { AppModal } from "@/assets/ts/_utils/enums/modals.enum";
import useKYCs from "@/core/composables/useKYCs";
import UpdateKYCStatusModal from "@/components/modals/forms/UpdateKYCStatusModal.vue";
import AdminCreateClient from "@/components/modals/forms/AdminCreateClient.vue";
import { PlanType } from "@/assets/ts/_utils/enums/plan.enum";
import { debounce } from "lodash";

interface IClient {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  last_modified: string;
}

export default defineComponent({
  name: "admin-kyc-submissions",
  components: {
    Datatable,
    KYCReview,
    UpdateKYCStatusModal,
    AdminCreateClient,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { fetchKYCs, loading: loadingKYCs } = useKYCs();
    const checkedCustomers = ref([]);
    const tableKey = ref(1);

    const formStatuses = ref([
      "In Progress",
      "Approved",
      "Declined",
      "Reviewing",
      "Submitted",
    ]);
    const allowedStatusesToReview: number[] = [2, 3, 4, 5];
    const selectedKYCForUpdate = ref(null);

    const isOngoingStatus = computed(() =>
      ["inProgress", "submitted", "inReview"].includes(
        route.params.status as string
      )
    );
    const tableHeaderWithoutActions = computed(() => {
      const actions = [
        {
          name: "Email",
          key: "email",
          sortable: true,
        },
        {
          name: "First Name",
          key: "first_name",
          sortable: true,
        },
        {
          name: "Last Name",
          key: "last_name",
          sortable: true,
        },
        {
          name: "Created Date",
          key: "created",
          sortable: true,
        },
        {
          name: "Status",
          key: "status",
        },
      ];

      if (isOngoingStatus.value) {
        actions.push({
          name: "Edit",
          key: "edit",
        });
      }

      return actions;
    });

    const tableHeaderWithActions = computed(() => [
      ...tableHeaderWithoutActions.value,
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const allowReviewByStatus = computed(() => {
      const statusInt = getStatusInt(route.params.status) as number;
      return allowedStatusesToReview.includes(statusInt);
    });

    let tableHeader = ref([]);

    const tableData = ref<Array<IClient>>([]);
    const initCustomers = ref<Array<IClient>>([]);
    const reviewDisable = ref(true);
    const showReviewForm = ref(false);

    const fetchData = async (searchVal?: string) => {
      let apiConfig: any = {};
      const { status } = route.params || {};
      if (searchVal) {
        apiConfig.search = searchVal;
      }

      if (status) {
        const statusInt = getStatusInt(status);
        if (statusInt) {
          apiConfig.status = statusInt;
        }
      }

      const resp = await fetchKYCs(apiConfig);
      tableData.value = resp;
      tableKey.value = new Date().getTime();
    };

    onMounted(async () => {
      fetchData();
      showReviewForm.value = false;
    });

    watch(
      () => route.params,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          showReviewForm.value = false;
          if (store.getters.currentUser?.id) {
            fetchData();
            tableKey.value = new Date().getTime();
          }
        }
      }
    );

    const reviewForm = async (id, status, event) => {
      event.preventDefault();
      if (status == 1) {
        return;
      }

      await enableFormReview(id);
      //router.push({ name: "admin-kyc-review", params: { kycId: id } });
    };

    const enableFormReview = async (formId) => {
      //reset error
      store.commit(Mutations.SET_ERROR, {});
      await store.dispatch(Actions.USER_DATA);
      var roles = store.getters.getRoles;
      if (formId) {
        await store.dispatch(Actions.FETCH_KYC_BY_ID, formId);
        await store.dispatch(Actions.FETCH_KYC_REVIEW_BY_ID, formId);
      }

      reviewDisable.value = roles.admin ? false : true;
      showReviewForm.value = true;
    };

    const search = ref<string>("");

    const searchItems = debounce(() => {
      fetchData(search.value);
    }, 1000);

    const openKYCUpdateModal = (kyc) => {
      selectedKYCForUpdate.value = kyc;
      showModal(AppModal.KYC_STATUS_MODAL);
    };

    const replaceData = async (updatedData) => {
      const idx = tableData.value.findIndex((d) => d.id === updatedData.id);
      if (idx >= 0) {
        const updatedTableData = cloneDeep(tableData.value);
        updatedTableData[idx] = updatedData;
        tableData.value = updatedTableData;
        tableKey.value = new Date().getTime();
      }
    };

    const handleClientCreated = async () => {
      if (route.params.status === "inProgress") {
        await fetchData();
        tableKey.value = new Date().getTime();
      }
    };

    return {
      tableData,
      tableHeader,
      search,
      searchItems,
      checkedCustomers,
      reviewForm,
      formStatuses,
      formatDate,
      getBadgeClass,
      allowedStatusesToReview,
      reviewDisable,
      showReviewForm,
      isOngoingStatus,
      openKYCUpdateModal,
      selectedKYCForUpdate,
      allowReviewByStatus,
      tableHeaderWithActions,
      tableHeaderWithoutActions,
      tableKey,
      loadingKYCs,
      replaceData,
      handleClientCreated,
      PlanType,
      fetchData,
    };
  },
});
