
import { defineComponent, ref, onMounted, watch } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import EducationModal from "@/components/client/BackgroundCheck/modals/EducationModal.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { IEducation } from "@/store/models/form";
import { boolean } from "yup/lib/locale";

export default defineComponent({
  name: "step-4",
  components: {
    Datatable,
    EducationModal,
  },
  props: {
    isReview: { type: Boolean, default: false },
  },
  setup() {
    const tableHeader = ref([
      {
        name: "Degree",
        key: "degree_name",
        sortable: true,
      },
      {
        name: "Institute Name",
        key: "institute_name",
        sortable: true,
      },
      {
        name: "Start Date",
        key: "start_date",
        sortable: true,
      },
      {
        name: "End Date",
        key: "left_date",
        sortable: true,
      },
      {
        name: "Actions",
        key: "edit",
      },
    ]);

    const tableData = ref<Array<IEducation>>([]);
    const store = useStore();

    const initEducationFormData = ref({
      institute_name: "Sean Bean",
      degree_name: "sean@dellito.com",
      start_date: "",
      left_date: "",
      address: {
        street_address: "",
        city: "Melbourne",
        state: "Victoria",
        zip_code: "3000",
      },
    });

    let educationFormData = ref(initEducationFormData);

    const reloadTableData = async () => {
      const formId = store.getters.getFormId;
      if (formId) {
        tableData.value = [];
        await store.dispatch(Actions.GET_EDUCATION_HISTORY);
        const data = store.getters.getEduHistory;
        tableData.value = data;
      }
    };

    onMounted(() => {
      reloadTableData();
    });

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const addRecord = () => {
      educationFormData.value = initEducationFormData.value;
    };

    const editRecord = async (id, event) => {
      event.preventDefault();
      educationFormData.value =
        tableData.value.find((x) => x.id == id) ?? educationFormData.value;
    };

    const deleteRecord = async (id, event) => {
      event.preventDefault();
      await store.dispatch(Actions.DELETE_EDUCATION_HISTORY, id);
      reloadTableData();
    };

    watch(
      () => store.getters.getEduHistory,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          tableData.value = [];
          setTimeout(() => {
            tableData.value = curVal as Array<IEducation>;
          }, 1000);
        }
      }
    );

    return {
      tableData,
      tableHeader,
      deleteCustomer,
      educationFormData,
      editRecord,
      deleteRecord,
      addRecord,
    };
  },
});
