
import Notice from "@/components/Notice.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "step-1",
  components: { Notice },
  setup() {
    const guide = ref([
      {
        title: "Filing online",
        text: "Submitting your application online is the same as mailing in a completed paper form. They both gather the same information.",
      },
      {
        title: "Provide as many responses as you can",
        text: "You should provide as many responses as you can. Incomplete fields or sections and missing information can slow down the process after you submit your form.",
      },
      {
        title: "We will automatically save your responses",
        text: "We will automatically save your information when you select next to go to a new page or navigate to another section of the form. We will save your information for 7 days from today, or from the last time you worked on the form.",
      },
      {
        title: "How to continue filling out your form",
        text: "After you start your form, you can sign in to your account to continue where you left off.",
      },
    ]);

    const privacyGuide = ref([
      {
        title: "AUTHORITIES",
        text: "The information requested on this application, and the associated evidence, is collected under the Ghana Data Protection Act.",
      },
      {
        title: "PURPOSE",
        text: "The primary purpose for providing the requested information on this application is to determine and verify the identity of individuals who are in the process of any application that requires ID verification.",
      },
      {
        title: "DISCLOSURE",
        text: "The information you provide is voluntary. However, failure to provide the requested information, including your ID number, and any requested evidence may delay a final decision or result in a rejection or denial of your application.",
      },
      {
        title: " ROUTINE USES",
        text: `
        SwiftIdentity may, where allowable under relevant confidentiality provisions,
        share the information you provide on this application and any additional
        requested evidence with other Federal and authorized local government
        agencies. SwiftIdentity follows approved routine uses, as described in the
        associated published system of records notices from the Ghana Data
        Protection Act which you can find at this
        <a
          href="https://www.swiftidentity.com/privacy-policy/"
          target="_blank"
          >link</a
        >. SwiftIdentity may also share this information as appropriate for law
        enforcement purposes or in the interest of national security.`,
      },
    ]);

    return { guide, privacyGuide };
  },
});
