
import { defineComponent, PropType, ref, watch, computed } from "vue";
import { hideModal } from "@/core/helpers/dom";
import useNotify from "@/core/composables/notify";
import Badge from "@/components/Badge.vue";
import Button from "@/components/buttons/Button.vue";
import { ProgressStatus } from "@/assets/ts/_utils/enums/progress-status.enum";
import useKYCs from "@/core/composables/useKYCs";

export default defineComponent({
  name: "update-kyc-status-modal",
  components: { Badge, Button },
  props: {
    kyc: { type: Object as PropType<any> },
  },
  emits: ["saved"],
  setup(props, { emit }) {
    const { toastSuccess } = useNotify();
    const formRef = ref<null | HTMLFormElement>(null);
    const addUserModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const status = ref<number | null>(null);
    const currentStep = ref<number | null>(null);

    const { updateKYC } = useKYCs();

    const currentStepInstance = computed(() => {
      return props.kyc.current_step || 0;
    });

    const updateStatus = async (s) => {
      if (loading.value) return;

      loading.value = true;
      status.value = s;
      const resp = await updateKYC(props.kyc.id, { status: s });
      loading.value = false;

      if (resp) {
        toastSuccess({ text: "Status updated successfully" });
        const updatedKYC = {
          ...props.kyc,
          status: s,
        };
        emit("saved", updatedKYC);
      }
    };

    const handleUpdateStep = async () => {
      if (currentStep.value) {
        loading.value = true;
        const resp = await updateKYC(props.kyc.id, {
          current_step: currentStep.value as number,
        });

        loading.value = false;

        if (resp) {
          toastSuccess({ text: "Step updated successfully" });
          const updatedKYC = {
            ...props.kyc,
            current_step: currentStep.value,
          };
          emit("saved", updatedKYC);
        }
      }
    };

    watch(
      () => props.kyc,
      (curVal, oldVal) => {
        if (curVal != oldVal && curVal) {
          status.value = curVal.status;
          const step = curVal.current_step;
          if (step) {
            currentStep.value = step;
          } else {
            currentStep.value = 0;
          }
        }
      }
    );

    const stepOptions = ref([
      { value: 0, text: "Getting Started" },
      { value: 1, text: "Identity Document" },
      { value: 2, text: "Document Upload" },
      { value: 3, text: "Picture Upload" },
    ]);

    return {
      formRef,
      loading,
      addUserModalRef,
      currentStep,
      currentStepInstance,
      updateStatus,
      status,
      ProgressStatus,
      handleUpdateStep,
      stepOptions,
    };
  },
});
