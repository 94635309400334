
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { COMPLETED, IN_REVIEW, SUBMITTED, CENCEL } from "@/constants";
import useNotify from "@/core/composables/notify";
import OCRModal from "@/components/modals/OCRModal.vue";

export default defineComponent({
  name: "admin-kyc-review",
  props: {
    reviewDisable: { type: Boolean, default: true },
  },
  components: { OCRModal },
  data() {
    return {
      tab: 1,
      screenPdfSrc: "",
      otherPdfSrc: "",
      review: {
        kyc: this.kycID,
        official_doc: false,
        official_doc_back: false,
        live_photo: false,
        doc_type: false,
        desc_official_doc: "",
        desc_self_reported_info: "",
        desc_official_doc_back: "",
        desc_live_photo: "",
        desc_doc_type: "",
      },
      reviewErrors: {},
      metadata: {},
      docTypes: ["ID Card", "Passport", "Driving License", "Residence Permit"],
    };
  },
  computed: {
    ...mapGetters({
      kyc: "getKyc",
      kycReview: "getKycReview",
      errors: "getKycErrors",
      roles: "getRoles",
    }),
    kycID() {
      return this.$route.params.kycId;
    },
    allApproved(): boolean {
      return (
        this.review.official_doc &&
        this.review.official_doc_back &&
        this.review.live_photo &&
        this.review.doc_type
      );
    },
    frontDoc() {
      const front = this.kyc?.official_doc_file || this.kyc?.official_doc;
      if (this.kyc?.official_doc_file) {
        front.src = this.kyc?.official_doc_file.url;
      }
      return front;
    },
    backDoc() {
      const back =
        this.kyc?.official_doc_back_file || this.kyc?.official_doc_back;
      if (this.kyc?.official_doc_back_file) {
        back.src = this.kyc?.official_doc_back_file.url;
      }
      return back;
    },
    selfie() {
      const img = this.kyc?.live_photo_file || this.kyc?.live_photo;
      if (this.kyc?.live_photo_file) {
        img.src = this.kyc?.live_photo_file.url;
      }
      return img;
    },
    meta() {
      if (!this.kyc?.meta) return null;
      if (typeof this.kyc.meta === "string") return JSON.parse(this.kyc.meta);
      if (typeof this.kyc.meta === "object") return this.kyc.meta;
      return null;
    },
  },
  methods: {
    ...mapActions({
      fetchKycByID: Actions.FETCH_KYC_BY_ID,
      fetchKycReviewByID: Actions.FETCH_KYC_REVIEW_BY_ID,
      fetchUser: Actions.USER_DATA,
      userData: Actions.USER_DATA,
      updateKycStatus: Actions.UPDATE_KYC_STATUS,
    }),
    ...mapMutations({
      setError: Mutations.SET_ERROR,
    }),
    getMonth(date: string) {
      const d = new Date(date);
      return `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? "0" : ""}${
        d.getMonth() + 1
      }`;
    },
    async fetchDisclosureFile(src: string) {
      const response = await ApiService.get(src, {
        responseType: "blob",
      } as any);
      if (ApiService.isAxiosResponse(response)) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        return objectUrl;
      }
      return "";
    },
    async updateOrCreateReview(
      shouldUpdateForm = false,
      notifyOnSuccess = false
    ) {
      const reviewExists = !!this.kycReview?.id;
      // const reviewMapped = this.formatReviewPayload();
      let response;
      if (!reviewExists) {
        console.log("no review yet");
        response = await await ApiService.post(
          "kycReview/",
          this.review as any
        );
      } else {
        console.log(" review dey");
        response = await ApiService.patch(
          `kycReview/${this.kycReview.id}/`,
          this.review as any
        );
      }

      if (ApiService.isAxiosError(response) && response.response) {
        this.reviewErrors = response.response.data;
      }

      if (ApiService.isAxiosResponse(response) && shouldUpdateForm) {
        // await this.updateKycStatus({
        //   id: this.kyc.id,
        //   data: { status: completed ? COMPLETED : CENCEL },
        // });

        // this.$router.push({ name: "admin-form-submission" });
        if (notifyOnSuccess) {
          const { toastSuccess } = useNotify();
          toastSuccess({ text: "Review updated successfully" });
        }
      }

      return response;
    },
    async submit(completed: boolean) {
      const response = await this.updateOrCreateReview();
      if (ApiService.isAxiosError(response) && response.response) {
        this.reviewErrors = response.response.data;
      }
      // const response = await ApiService.post("kycReview/", this.review as any);
      if (ApiService.isAxiosResponse(response)) {
        await this.updateKycStatus({
          id: this.kyc.id,
          data: { status: completed ? COMPLETED : CENCEL },
        });
        this.$emit("cancel");
        // this.$router.push({ name: "admin-kyc-submission" });
      }
    },
    async loadData() {
      if (Object.keys(this.kycReview).length !== 0) {
        //this.reviewDisable = true;
        this.review.kyc = this.kycReview.kyc;
        this.review.desc_official_doc = this.kycReview.desc_official_doc;
        this.review.desc_official_doc_back =
          this.kycReview.desc_official_doc_back;
        this.review.desc_live_photo = this.kycReview.desc_live_photo;
        this.review.desc_doc_type = this.kycReview.desc_doc_type;
        this.review.official_doc = this.kycReview.official_doc;
        this.review.official_doc_back = this.kycReview.official_doc_back;
        this.review.live_photo = this.kycReview.live_photo;
        this.review.doc_type = this.kycReview.doc_type;
      }
      if (Object.keys(this.kyc).length !== 0) {
        // if (this.kyc.status === SUBMITTED) {
        //   await this.updateKycStatus({
        //     id: this.kyc.id,
        //     data: { status: IN_REVIEW },
        //   });
        // }
        this.review.kyc = this.kyc.id;

        if (this.kyc) {
          this.metadata = JSON.parse(this.kyc.doc_metadata);
        }
      }
    },
  },
  async mounted() {
    await this.loadData();
  },
});
