
import { defineComponent } from "vue";
import ReviewItem from "./ReviewItem.vue";
import { useField } from "vee-validate";
import { computed } from "vue";
import { docTypes } from "@/assets/ts/_utils/enums/doc-type.enum";

export default defineComponent({
  name: "step-5",
  components: { ReviewItem },
  props: { uploads: Object },
  setup(props) {
    const { value: first_name } = useField("first_name");
    const { value: last_name } = useField("last_name");
    const { value: address } = useField("address");
    const { value: gender } = useField("gender");
    const { value: date_of_birth } = useField("date_of_birth");
    const { value: citizenship_country } = useField("citizenship_country");
    const { value: doc_type } = useField("doc_type");
    const { value: id_number } = useField("id_number");

    const docType = computed(() =>
      doc_type.value ? docTypes[doc_type.value as string] : ""
    );

    return {
      first_name,
      last_name,
      address,
      gender,
      date_of_birth,
      citizenship_country,
      doc_type,
      id_number,
      docType,
    };
  },
});
