
import { AppEvent } from "@/assets/ts/_utils/enums/custom-events.enum";
import {
  IDVerification,
  IDVerificationWithAML,
} from "@/assets/ts/_utils/models/IDVerification";
import useEmitter from "@/core/composables/emitter";
import { hideModal } from "@/core/helpers/dom";
import { AppModal } from "@/assets/ts/_utils/enums/modals.enum";
import { ref, PropType, watch, computed } from "vue";
import useNotify from "@/core/composables/notify";
import { cloneDeep } from "lodash";
import FileUpload from "@/components/FileUpload.vue";
import { Media, PreMedia } from "@/assets/ts/_utils/models/MediaModel";
import Notice from "@/components/Notice.vue";
import {
  CardType,
  cardOptions,
  ghanaCardOptions,
} from "@/assets/ts/_utils/enums/id-verification-type.enum";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import PlanCondensedCard from "@/components/PlanCondensedCard.vue";
import { extractElementFormError } from "@/core/helpers/errors";

export default {
  components: { FileUpload, Notice, PlanCondensedCard },
  name: "IDVerificationForm",
  props: {
    initialData: {
      type: Object as PropType<IDVerificationWithAML>,
      default: () => ({}),
    },
    isGhanaCard: {
      type: Boolean,
    },
    incpPrice: {
      type: Number,
    },
    amlPlans: { type: Array as PropType<Plan[]> },
  },
  setup(props) {
    const emitter = useEmitter();
    const { toastError } = useNotify();

    const formRef = ref<null | HTMLFormElement>(null);
    const includeAml = ref(false);

    const rules = ref({
      id_number: [
        {
          required: true,
          message: "ID Number is required",
          trigger: "change",
        },
      ],
    });

    const data = ref(props.initialData);
    const uploadedId = ref<PreMedia | Media | null | undefined>(
      cloneDeep(props.initialData?.uploaded_id)
    );
    const uploadedIdBack = ref<PreMedia | Media | null | undefined>(
      cloneDeep(props.initialData?.uploaded_id_back)
    );

    const handleSaveData = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid, fields: any) => {
        if (!props.isGhanaCard && !uploadedId.value && !uploadedIdBack.value) {
          return toastError({
            text: "Please upload an image of the ID card",
          });
        }

        if (valid) {
          const payload = {
            ...data.value,
            uploaded_id: uploadedId.value,
            uploaded_id_back: uploadedIdBack.value,
          };
          if (([null, undefined] as any[]).includes(data.value.id)) {
            emitter.emit(AppEvent.ID_VERIFICATION_ADD, payload);
          } else {
            emitter.emit(AppEvent.ID_VERIFICATION_UPDATE, payload);
          }
          hideModal(
            document.getElementById(AppModal.ID_VERIFICATION_FORM_MODAL)
          );
          formRef.value?.resetFields();
          uploadedId.value = null;
          uploadedIdBack.value = null;
        } else {
          toastError({
            text: extractElementFormError(fields),
          });
        }
      });
    };

    watch(
      () => props.initialData,
      (curVal, oldVal) => {
        data.value = cloneDeep(curVal);
        uploadedId.value = cloneDeep(curVal.uploaded_id);
        uploadedIdBack.value = cloneDeep(curVal.uploaded_id_back);
        includeAml.value = !!curVal.aml_plan;
      }
    );

    const uploadedIdSrc = computed(() => {
      if (!uploadedId.value) {
        return "";
      }
      const id = uploadedId.value as PreMedia;
      return id.fileBlob;
    });

    const uploadedIdSrcBack = computed(() => {
      if (!uploadedIdBack.value) {
        return "";
      }

      return (uploadedIdBack.value as PreMedia).fileBlob;
    });

    const handleFileSelected = ({ payload, uploadPromise }) => {
      uploadedId.value = {
        ...uploadedId.value,
        src: "",
        ...payload,
        mediaPromise: uploadPromise,
      };
    };

    const handleBackFileSelected = ({ payload, uploadPromise }) => {
      uploadedIdBack.value = {
        ...uploadedIdBack.value,
        src: "",
        ...payload,
        mediaPromise: uploadPromise,
      };
    };

    const displayedCardOptions = computed(() =>
      props.isGhanaCard ? ghanaCardOptions : cardOptions
    );
    const isPassport = computed(
      () => data.value.card_type == CardType.PASSPORT
    );

    return {
      handleSaveData,
      data,
      rules,
      formRef,
      uploadedId,
      uploadedIdSrc,
      handleFileSelected,
      handleBackFileSelected,
      cardOptions,
      uploadedIdSrcBack,
      uploadedIdBack,
      displayedCardOptions,
      includeAml,
      isPassport,
      CardType,
    };
  },
};
