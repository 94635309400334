import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import useNotify from "./notify";
import { PaginatedParams } from "@/assets/ts/_utils/models/Api";

const useClients = () => {
  const loading = ref<boolean>(false);
  const loadingVerification = ref<boolean>(false);
  const { toastErrorRaw, toastSuccess } = useNotify();

  const fetchClientVerifications = async (params: PaginatedParams = {}) => {
    const { query, ...otherParams } = params || {};
    try {
      loading.value = true;
      const resp = await ApiService.query(
        `client-verifications${query || ""}`,
        {
          params: otherParams,
          hideLoader: true,
        }
      );
      return resp.data.results;
    } catch (err: any) {
      toastErrorRaw(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchClientVerification = async (id: number) => {
    try {
      loadingVerification.value = true;
      const resp = await ApiService.getWithoutSlug(
        `applicants/${id}/verification/`
      );
      return resp.data;
    } catch (err: any) {
      toastErrorRaw(err);
    } finally {
      loadingVerification.value = false;
    }
  };

  const createApplicants = async (applicants) => {
    try {
      const resp = await ApiService.post("bulkClients/", applicants);
      return resp.data;
    } catch (error) {
      toastErrorRaw(error);
    }
  };

  return {
    loading,
    fetchClientVerifications,
    createApplicants,
    loadingVerification,
    fetchClientVerification,
  };
};

export default useClients;
