import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-stack mb-10" }
const _hoisted_2 = { class: "d-flex align-items-center me-2" }
const _hoisted_3 = { class: "symbol symbol-50px me-3" }
const _hoisted_4 = { class: "fs-6 text-gray-800 text-hover-primary fw-bold" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["symbol-label", $props.variant || 'bg-light'])
        }, [
          _createElementVNode("span", {
            class: _normalizeClass([`svg-icon-${$props.color}`, "svg-icon svg-icon-2x"])
          }, [
            _createVNode(_component_inline_svg, { src: $props.icon }, null, 8, ["src"])
          ], 2)
        ], 2)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_4, _toDisplayString($props.title), 1),
        _createElementVNode("div", {
          class: "fs-7 text-muted fw-semobold mt-1",
          innerHTML: $props.subtitle
        }, null, 8, _hoisted_5)
      ])
    ]),
    ($props.rightText)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["badge fw-semobold py-4 px-3", [$props.rightVariant || 'badge-light']])
        }, _toDisplayString($props.rightText), 3))
      : _createCommentVNode("", true)
  ]))
}