
import { AppEvent } from "@/assets/ts/_utils/enums/custom-events.enum";
import { AMLVerification } from "@/assets/ts/_utils/models/IDVerification";
import useEmitter from "@/core/composables/emitter";
import { hideModal } from "@/core/helpers/dom";
import { AppModal } from "@/assets/ts/_utils/enums/modals.enum";
import { ref, PropType, watch, computed } from "vue";
import useNotify from "@/core/composables/notify";
import useCountries from "@/core/composables/useCountries";
import { cloneDeep } from "lodash";
import { Media, PreMedia } from "@/assets/ts/_utils/models/MediaModel";
import Notice from "@/components/Notice.vue";
import {
  CardType,
  cardOptions,
  ghanaCardOptions,
} from "@/assets/ts/_utils/enums/id-verification-type.enum";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { extractElementFormError } from "@/core/helpers/errors";

export default {
  components: { Notice },
  name: "IDVerificationForm",
  props: {
    initialData: {
      type: Object as PropType<AMLVerification>,
      default: () => ({}),
    },
    isGhanaCard: {
      type: Boolean,
    },
    incpPrice: {
      type: Number,
    },
  },
  setup(props) {
    const emitter = useEmitter();
    const store = useStore();

    const { toastError } = useNotify();
    const { searchCountries, countries } = useCountries();

    const formRef = ref<null | HTMLFormElement>(null);

    const rules = ref({
      first_name: [
        {
          required: true,
          message: "First name is required",
          trigger: "change",
        },
      ],
      last_name: [
        {
          required: true,
          message: "Last name is required",
          trigger: "change",
        },
      ],
      country: [
        {
          required: true,
          message: "Country is required",
          trigger: "change",
        },
      ],
    });

    const data = ref(props.initialData);

    const handleSaveData = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid, fields: any) => {
        if (valid) {
          const payload = {
            ...data.value,
          };
          if (([null, undefined] as any[]).includes(data.value.id)) {
            emitter.emit(AppEvent.AML_VERIFICATION_ADD, payload);
          } else {
            emitter.emit(AppEvent.AML_VERIFICATION_UPDATE, payload);
          }
          hideModal(
            document.getElementById(AppModal.AML_VERIFICATION_FORM_MODAL)
          );
          formRef.value?.resetFields();
        } else {
          toastError({
            text: extractElementFormError(fields),
          });
        }
      });
    };

    watch(
      () => props.initialData,
      (curVal, oldVal) => {
        data.value = cloneDeep(curVal);
      }
    );

    return {
      handleSaveData,
      data,
      rules,
      formRef,
      cardOptions,
      searchCountries,
    };
  },
};
