
import { defineComponent, ref, PropType, computed } from "vue";

import { hideModal } from "@/core/helpers/dom";
import { AppModal } from "@/assets/ts/_utils/enums/modals.enum";
import { AMLVerification } from "@/assets/ts/_utils/models/IDVerification";
import SummaryCard from "@/components/customer/SummaryCard.vue";
import { AMLVerificationStatus } from "@/assets/ts/_utils/enums/id-verification-status.enum";
import { IDType } from "@/assets/ts/_utils/enums/id-verification-type.enum";
import Spinner from "@/components/Spinner.vue";

export default defineComponent({
  name: "aml-verification-modal",
  components: { SummaryCard, Spinner },
  props: {
    verification: {
      type: Object as PropType<AMLVerification | null>,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  setup(props) {
    const viewVerificationModalRef = ref<HTMLDivElement | null>(null);
    const submit = () => {
      hideModal(AppModal.VIEW_AML_VERIFICATION_MODAL);
    };

    // const meta = computed(() => {
    //   if (
    //     props.verification?.meta &&
    //     typeof props.verification?.meta === "string"
    //   ) {
    //     return JSON.parse(props.verification.meta);
    //   }

    //   if (
    //     props.verification?.meta &&
    //     typeof props.verification?.meta === "object"
    //   ) {
    //     return props.verification?.meta;
    //   }
    //   return {};
    // });

    const matched = computed<any>(() => {
      const obj =
        props.loading || !props.verification?.matched_aml
          ? {}
          : JSON.parse(props.verification.matched_aml);

      return obj;
    });

    return {
      viewVerificationModalRef,
      submit,
      AMLVerificationStatus,
      IDType,
      matched,
    };
  },
});
