
export default {
  props: [
    "title",
    "subtitle",
    "rightText",
    "icon",
    "variant",
    "color",
    "rightVariant",
  ],
  setup() {
    return {};
  },
};
