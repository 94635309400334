export enum AppModal {
  AML_VERIFICATION_FORM_MODAL = "kt_modal_aml_verification",
  ID_VERIFICATION_FORM_MODAL = "kt_modal_id_verification",
  PLANS_MODAL = "kt_modal_plans",
  CONSENT_MODAL = "kt_modal_consent",
  VIEW_VERIFICATION_MODAL = "kt_modal_view_verification",
  API_KEY_MODAL = "kt_modal_api_key",
  CRIMINAL_HISTORY_MODAL = "kt_modal_criminal_history",
  USER_MODAL = "kt_modal_user",
  PROFILE_MODAL = "kt_modal_profile",
  KYC_STATUS_MODAL = "kt_modal_kyc_status",
  ADMIN_CLIENT_CREATE_MODAL = "kt_modal_admin_client_create",
  STRIPE_MODAL = "kt_stripe_modal",
  VIEW_AML_VERIFICATION_MODAL = "kt_modal_view_aml_verification",
  VIEW_OCR_MODAL = "kt_modal_view_ocr",

}
