
import { PlanFeature } from "@/assets/ts/_utils/models/PlanModel";
import { PropType } from "vue";
import { Field } from "vee-validate";

export default {
  props: {
    id: { type: Number },
    name: { type: String },
    detail: { type: String },
    price: { type: Number },
    priceLabel: { type: String },
    keyFeatures: { type: Array as PropType<PlanFeature[]> },
    customClass: { type: String },
  },
  emits: ["selected"],
  components: {
    Field,
  },
  setup(props) {
    return {};
  },
};
