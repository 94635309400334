
import Button from "@/components/buttons/Button.vue";
import VueQrcode from "vue-qrcode";
import ApiService from "@/core/services/ApiService";
import useNotify from "@/core/composables/notify";
import Spinner from "@/components/Spinner.vue";
import { ref, computed } from "vue";

export default {
  props: {
    token: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      required: false,
    },
    fetchingCaptures: {
      type: Boolean,
      required: false,
    },
    showBack: {
      type: Boolean,
    },
  },
  components: {
    Button,
    VueQrcode,
    Spinner,
  },
  emits: ["on-complete", "email-sent"],
  setup(props, { emit }) {
    const { toastSuccess, toastErrorRaw } = useNotify();
    const email = ref("");
    const sendingLink = ref(false);

    const url = computed(() => {
      let url = `${process.env.VUE_APP_WEB_URL}#/client/captures/${props.token}?type=verification`;
      if (props.showBack) {
        url += `&back=true`;
      }
      return url;
    });

    const sendLink = async () => {
      try {
        sendingLink.value = true;
        const resp = await ApiService.post(`send-capture-link/`, {
          // verification_type: props.verificationType,
          // file_type: props.fileType,
          email: email.value,
          back: props.showBack,
        } as any);
        toastSuccess({ text: "Email sent successfully" });
        email.value = "";
        emit("email-sent", resp.data?.token);
      } catch (error) {
        toastErrorRaw(error);
      } finally {
        sendingLink.value = false;
      }
    };

    return { email, sendLink, sendingLink, url };
  },
};
