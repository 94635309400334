// import { defaultBGCData } from "@/store/models/form";
const replaceSrcWithUrl = (obj: any = {}) => {
  console.log("🚀 ~ replaceSrcWithUrl ~ obj:", obj);
  return { ...obj, src: obj?.url || obj?.src };
};

export const cleanData = (kyc) => {
  kyc = kyc || {};

  const frontDoc = kyc.official_doc_file || kyc.official_doc;
  // defaultBGCData.kyc.official_doc;

  const backDoc = kyc.official_doc_back_file || kyc.official_doc_back;
  // defaultBGCData.kyc.official_doc_back;

  const selfie = kyc.live_photo_file || kyc.live_photo;
  return {
    ...kyc,
    doc_type: kyc.doc_type ? kyc.doc_type.toString() : "",
    official_doc: replaceSrcWithUrl(frontDoc),
    official_doc_back: replaceSrcWithUrl(backDoc),
    live_photo: replaceSrcWithUrl(selfie),
  };
};
