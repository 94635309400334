import Swal, { SweetAlertOptions } from "sweetalert2";

const OFF_SESSION_ERR_TEXT =
  "Cannot read properties of undefined (reading 'data')";

const extractErrorText = (error: string | string[]) => {
  if (typeof error === "string") return error;
  return error.length > 0 ? error[0] : "";
};

const useNotify = () => {
  const toast = (config: SweetAlertOptions) =>
    Swal.fire({
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: "Close",
      customClass: {
        confirmButton: "btn fw-bold btn-light-danger",
      },
      ...config,
    });

  const toastSuccess = (config: SweetAlertOptions) =>
    toast({ ...config, icon: "success" });

  const toastError = (config: SweetAlertOptions) => {
    let { text } = config;
    if (Array.isArray(text)) {
      const [initialError] = text;
      if (typeof initialError === "object") {
        text = extractErrorText(initialError[Object.keys(initialError)[0]]);
      } else {
        text = initialError;
      }
    }
    toast({ ...config, text });
  };

  const toastErrorRaw = (error: any) => {
    const errorText = error?.response?.data?.detail || error?.message;
    if (errorText !== OFF_SESSION_ERR_TEXT) {
      toastError({ text: errorText });
    }
  };

  return { toast, toastSuccess, toastError, toastErrorRaw };
};

export default useNotify;
