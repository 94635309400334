<template>
  <input type="file" class="d-none" ref="filePicker" @change="parseSheet" />
  <button
    type="button"
    class="btn btn-success"
    data-bs-toggle="modal"
    data-bs-target="#kt_modal_upload_applicant"
  >
    {{ label }}
  </button>

  <UploadModal
    :help-text="uploadHelpText"
    :critical-text="uploadCriticalText"
    :example-sheet-url="exampleSheet"
  >
    <button type="button" class="btn btn-success" @click="triggerFilePicker">
      Choose File
    </button>
  </UploadModal>
</template>

<script>
import { hideModal } from "@/core/helpers/dom";
import { defineComponent, ref } from "vue";
import { read as readExcel, utils as xlsxUtils } from "xlsx";
import UploadModal from "./UploadModal.vue";
import useNotify from "@/core/composables/notify";

export default defineComponent({
  components: { UploadModal },
  props: {
    label: { type: String, default: "Bulk Upload" },
    requiredCols: { type: Array },
    uploadHelpText: { type: String },
    uploadCriticalText: { type: String },
    exampleSheet: { type: String },
  },
  setup(props, { emit }) {
    const filePicker = ref(null);
    const { toast } = useNotify();

    const triggerFilePicker = () => {
      filePicker.value.click();
    };

    const hasAllRequiredColumns = (result) => {
      if (result.length === 0) return true;
      if ((props.requiredCols || []).length === 0) return true;
      const [pick] = result;
      return props.requiredCols.every((col) => Object.keys(pick).includes(col));
    };

    const excelFileToJSON = (file) => {
      try {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = function (e) {
          const data = e.target.result;
          const workbook = readExcel(data, {
            type: "binary",
          });
          let result = [];
          const [sheetName] = workbook.SheetNames;
          const jsonSheet = xlsxUtils.sheet_to_json(workbook.Sheets[sheetName]);
          if (jsonSheet.length > 0) {
            result = jsonSheet;
          }

          if (!hasAllRequiredColumns(result)) {
            toast({
              text: `Ensure your sheet has all the required columns - ${props.requiredCols.join()}. Note the casing`,
            });
            return;
          }
          emit("change", result);
          hideModal(document.getElementById("kt_modal_upload_applicant"));
        };
      } catch (e) {
        toast({ text: e.message });
      }
    };

    const parseSheet = (e) => {
      const { files } = e.target;
      if (files.length) {
        const [file] = files;
        var filename = file.name;
        const extension = filename
          .substring(filename.lastIndexOf("."))
          .toUpperCase();
        if (extension == ".XLS" || extension == ".XLSX") {
          excelFileToJSON(files[0]);
        } else {
          toast({ text: "Please select a valid excel file." });
        }
      }
    };

    return {
      filePicker,
      triggerFilePicker,
      parseSheet,
    };
  },
});
</script>

<style lang="scss" scoped></style>
