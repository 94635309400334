<template>
  <div class="d-flex align-items-center justify-content-center" id="overlay">
    <!--begin::Spinner-->
    <span class="loader"></span>
    <!--end::Spinner-->
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "circle-loader",
});
</script>

<style scoped>
.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  border: 3px solid;
  border-color: #2625db #0000 #fff #0000;
  border-radius: 50%;
  box-sizing: border-box;
  animation: 1s rotate linear infinite;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}
.loader:before,
.loader:after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color: #fff;
  transform: translate(-10px, 19px) rotate(-35deg);
}
.loader:after {
  border-color: #2625db #0000 #0000 #0000;
  transform: translate(32px, 3px) rotate(-35deg);
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

#overlay {
  background: none repeat scroll 0 0 black;
  position: fixed;
  display: block;
  opacity: 0.5;
  z-index: 1000001;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
</style>
