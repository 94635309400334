
import { Subscription } from "@/assets/ts/_utils/models/SubscriptionModel";
import { PropType } from "vue";
import { defineComponent, ref, onMounted } from "vue";
import Switch from "./Switch.vue";

export default defineComponent({
  name: "subscription-card",
  components: { Switch },
  props: {
    customClass: { type: String, default: "" },
    note: { type: String, default: "" },
    subscription: { type: Object as PropType<Subscription>, required: true },
    active: { type: Boolean, default: false },
  },
  emits: ["selected"],
  setup(props, { emit }) {
    const selectSubscription = () => {
      console.log("choose");
      emit("selected");
    };
    onMounted(() => {
      //check if current user is authenticated
    });

    return { selectSubscription };
  },
});
