import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import useNotify from "./notify";

const useIDVerifications = () => {
  const loading = ref<boolean>(false);
  const { toastErrorRaw, toastSuccess } = useNotify();

  const fetchIDVerification = async (id: number) => {
    try {
      loading.value = true;
      const resp = await ApiService.getWithConfig(`id-verifications/${id}/`, {
        hideLoader: true,
      });
      return resp.data;
    } catch (err: any) {
      toastErrorRaw(err);
    } finally {
      loading.value = false;
    }
  };

  const createIDVerifications = async (data) => {
    try {
      const resp = await ApiService.post("bulk-id-verifications/", {
        verifications: data,
      } as any);
      return resp.data;
    } catch (error) {
      toastErrorRaw(error);
    }
  };

  return {
    loading,
    fetchIDVerification,
    createIDVerifications,
  };
};

export default useIDVerifications;
