
import { defineComponent, ref, onMounted, watch } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { formatDate, getBadgeClass } from "@/filters";
import { useRoute } from "vue-router";

interface IClient {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  last_modified: string;
}

export default defineComponent({
  name: "invitations",
  components: {
    Datatable,
    ExportCustomerModal,
    AddCustomerModal,
  },
  setup() {
    const route = useRoute();
    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        name: "Company Name",
        key: "company_name",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "First Name",
        key: "first_name",
        sortable: true,
      },
      {
        name: "Last Name",
        key: "last_name",
        sortable: true,
      },
      {
        name: "Plan",
        key: "plan",
        sortable: true,
      },
      {
        name: "Status",
        key: "invite",
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<IClient>>([]);
    const initCustomers = ref<Array<IClient>>([]);

    const fetchInvites = () => {
      tableData.value = [];
      let apiConfig = "";
      if(route.params?.status) {
        apiConfig = route.params.status == "pending" ? "?invite=false" : "?invite=true"
      }
      ApiService.get("client", apiConfig)
        .then(({ data }) => {
          console.log(data);
          tableData.value = data.results;
          setCurrentPageBreadcrumbs("Applicants", ["Invited Applicants"]);
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    onMounted(() => {
      fetchInvites();
    });

    watch(
      () => route.params,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          fetchInvites();
        }
      }
    );

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const sendInvite = (id, event) => {
      event.preventDefault();
      Swal.fire({
        text: "User will now receive email with next steps",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        ApiService.get("send-invite", id)
          .then(({ data }) => {
            tableData.value = [];
            fetchInvites();
          })
          .catch(({ response }) => {
            console.log(response);
          });
      });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<IClient> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const dummy = (event) => {
      event.preventDefault();
      console.log("test");
    };

    return {
      tableData,
      tableHeader,
      deleteCustomer,
      search,
      searchItems,
      checkedCustomers,
      deleteFewCustomers,
      sendInvite,
      formatDate,
      getBadgeClass,
    };
  },
});
