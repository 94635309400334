import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import useNotify from "./notify";
import { AppConfig } from "@/assets/ts/_utils/models/AppConfigModel";

const useAppConfig = () => {
  const loading = ref<boolean>(false);
  const data = ref<AppConfig | null>(null);

  const { toastErrorRaw } = useNotify();

  const fetchConfig = async () => {
    try {
      loading.value = true;
      const resp = await ApiService.getWithoutSlug("config/");
      data.value = resp.data;
    } catch (err: any) {
      toastErrorRaw(err);
    } finally {
      loading.value = false;
    }
  };

  fetchConfig();

  return { loading, data };
};

export default useAppConfig;
