
import {
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
} from "vue";
import { StepperComponent } from "@/assets/ts/components";
import { useForm } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import Step2 from "@/components/customer/NewApplicant/steps/Step2.vue";
import Step1 from "@/components/customer/NewApplicant/steps/Step1.vue";
import Step3 from "@/components/customer/NewApplicant/steps/Step3.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import paystack from "@/components/customer/Paystack.vue";
import { Fee, FeeType } from "@/assets/ts/_utils/models/FeeModel";
import { Actions } from "@/store/enums/StoreEnums";
import ConsentModal from "../modals/ConsentModal.vue";
import StripeModal from "../modals/StripeModal.vue";
import { Subscription } from "@/assets/ts/_utils/models/SubscriptionModel";
import useSubscriptions from "@/core/composables/useSubscriptions";
import { SubscriptionScope } from "@/assets/ts/_utils/enums/subscription.enum";
import Spinner from "@/components/Spinner.vue";
import useNotify from "@/core/composables/notify";
import useCurrencies from "@/core/composables/useCurrencies";
import { onBeforeRouteLeave } from "vue-router";
import { showModal } from "@/core/helpers/dom";
import { AppModal } from "@/assets/ts/_utils/enums/modals.enum";
import useGetPlans from "@/core/composables/getPlans";
import { PlanType } from "@/assets/ts/_utils/enums/plan.enum";

interface IStep1 {
  plan: number;
}

interface Applicant {
  first_name: string;
  last_name: string;
  email: string;
  date_of_birth: string;
  aml_plan: number | null | undefined;
  id?: number;
}

interface IStep2 {
  applicants: Applicant[];
}

interface CreateAccount extends IStep1, IStep2 {}

export default defineComponent({
  name: "NewApplicant",
  components: {
    Step1,
    Step2,
    Step3,
    // paystack,
    ConsentModal,
    Spinner,
    StripeModal,
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const store = useStore();
    const router = useRouter();
    const { toastError } = useNotify();
    const { data: amlPlans, loading: loadingPlans } = useGetPlans({
      plan_type: PlanType.AML_VERIFICATION,
    });

    const stripeSecret = ref("");

    const activeSubscription = ref<Subscription | null>(null);
    const fetchingResources = ref(true);
    const useActiveSubscription = ref(false);
    const submitted = ref(false);

    const { fetchActiveSubscription, loading: loadingActiveSubscription } =
      useSubscriptions();
    const { getPlanPriceByCurrency, getFeePriceByCurrency } = useCurrencies();

    // const publishablePayStackKey = process.env.VUE_APP_PAYSTACK_PUBLISHABLE_KEY;
    let reference = ref(Math.floor(Math.random() * 1000000000 + 1));
    let paymentMessage = ref<string>("");
    const checkoutRef = ref(null);
    const fees = ref<Fee[]>([]);
    const hasAgreed = ref(false);

    const formData = ref<CreateAccount>({
      plan: 1,
      applicants: [],
    });

    const saveTempFormData = () => {
      if (formData.value.applicants.length > 0 && !submitted.value) {
        localStorage.setItem("savedApplicants", JSON.stringify(formData.value));
      }
    };

    const beforeRouteLeave = (to, from, next) => {
      if (confirm("Are you sure you want to leave this page?")) {
        saveTempFormData();
        next();
      } else {
        next(false); // Abort navigation
      }
    };

    onBeforeRouteLeave(beforeRouteLeave);

    const handleBeforeUnload = (event) => {
      // store applicants in vuex
      saveTempFormData();
      event.preventDefault();
      event.returnValue = "Are you sure you want to leave this page?";
    };

    onBeforeUnmount(() => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    });

    onMounted(async () => {
      window.addEventListener("beforeunload", handleBeforeUnload);

      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );

      setCurrentPageBreadcrumbs("Applicants", ["Verifications"]);

      await store.dispatch(Actions.GET_CUSTOMER_PLANS);
      fees.value = await store.dispatch(Actions.GET_FEES);

      const sub = await fetchActiveSubscription({
        scope: SubscriptionScope.KYC,
      });
      activeSubscription.value = sub;
      fetchingResources.value = false;

      if ("savedApplicants" in localStorage) {
        const tempApplicants = JSON.parse(
          localStorage.getItem("savedApplicants")!
        )! || { plan: 1, applicants: [] };
        formData.value = {
          ...tempApplicants,
          applicants: tempApplicants.applicants.map((a, i) => ({
            ...a,
            id: a.id || i + 1,
          })),
        };
      }
    });

    const createAccountSchema = [
      Yup.object({
        applicants: Yup.array().of(
          Yup.object({
            first_name: Yup.string().required().label("First Name"),
            last_name: Yup.string().required().label("Last Name"),
            email: Yup.string().required().email().label("Email"),
            date_of_birth: Yup.date().label("Date of Birth").nullable(),
            aml_plan: Yup.number().label("AML Plan"),
          })
        ),
      }),
      Yup.object({
        plan: Yup.string().required().label("Plan"),
      }),
    ];

    const currentSchema = computed(() => {
      return createAccountSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit, setFieldValue } = useForm<IStep1 | IStep2>(
      {
        validationSchema: currentSchema,
      }
    );

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const processPayment = async (elem?) => {
      paymentMessage.value = "Payment is successful";
      createClient(null, elem?.paymentIntent?.id);
    };

    const onApplicantsUpdate = (data) => {
      formData.value.applicants = [];
      data.forEach((element) => {
        formData.value.applicants.push({
          first_name: element.first_name,
          last_name: element.last_name,
          email: element.email,
          date_of_birth: element.date_of_birth,
          aml_plan: element.aml_plan,
          id: element.id,
        });
      });
    };

    // const paystackClose = async () => {
    //   paymentMessage.value = "Payment failed";
    // };

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      const beforeStep = currentStepIndex.value;
      const nexStepIncrement =
        beforeStep === 0 && useActiveSubscription.value ? 2 : 1;

      currentStepIndex.value += nexStepIncrement;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goto(beforeStep + nexStepIncrement + 1);
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      const beforeStep = currentStepIndex.value;

      const nexStepDecr =
        beforeStep === (totalSteps.value || 0) - 1 &&
        useActiveSubscription.value
          ? 2
          : 1;

      currentStepIndex.value -= nexStepDecr;

      _stepperObj.value.goto(beforeStep - nexStepDecr + 1);
    };

    const handleSelectSubscription = () => {
      if (useActiveSubscription.value) {
        useActiveSubscription.value = false;
        formData.value.plan = 1;
      } else {
        useActiveSubscription.value = true;
        const plans = store.getters.getCustomerPlans;
        console.log("plans :>> ", plans);
        const subscriptionPlan = plans.find(
          (p) => p.id === activeSubscription.value?.plan
        );

        console.log("object :>> ", subscriptionPlan);

        if (subscriptionPlan) {
          formData.value = { ...formData.value, plan: subscriptionPlan.id };
          setFieldValue("plan" as never, subscriptionPlan.id as never);
        }
      }
    };

    const getApplicantsData = (sub?) => {
      const user = store.getters.currentUser;
      let clientData: any = {
        ...formData.value,
      };
      let toBeSavedClients: any = [];
      clientData.applicants.forEach((element) => {
        toBeSavedClients.push({
          company: user.id,
          first_name: element.first_name,
          last_name: element.last_name,
          email: element.email,
          plan: clientData.plan,
          subscription: sub,
          source: "SWIFT",
          aml_plan: element.aml_plan,
        });
      });
      return toBeSavedClients;
    };

    const createClient = (sub?, providerPaymentID?) => {
      const user = store.getters.currentUser;
      const applicants = getApplicantsData(sub);
      let clientPaymentData: any = {
        company: user.id,
        reference_number: providerPaymentID || reference.value,
        amount: billedAmount.value,
        gateway: "STRIPE",
        currency: "Dollar",
      };
      ApiService.post("bulkClients/", applicants)
        .then(({ data }) => {
          console.log(data);
          submitted.value = true;
          if (!sub) {
            ApiService.post("payments/", clientPaymentData)
              .then(({ data }) => {
                console.log(data);
                redirectToCustomeInvitedPage();
              })
              .catch(({ response }) => {
                console.log(response);
                showErrorResponse(response.data);
              });
          } else {
            redirectToCustomeInvitedPage();
          }
        })
        .catch(({ response }) => {
          console.log(response);
          showErrorResponse(response.data);
        });
    };

    const showErrorResponse = (error) => {
      toastError({
        text: error,
        confirmButtonText: "Try again!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      });

      // Swal.fire({
      //   text: error,
      //   icon: "error",
      //   buttonsStyling: false,
      //   confirmButtonText: "Try again!",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-danger",
      //   },
      // });
    };

    const redirectToCustomeInvitedPage = () => {
      localStorage.setItem(
        "savedApplicants",
        JSON.stringify({ plan: 1, applicants: [] })
      );
      Swal.fire({
        text: "Client Creation was successful",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        router.push({ name: "customer-verifications" });
      });
    };

    const selectedPlanPrice = computed(() => {
      const plan = store.getters.getCustomerPlans.find(
        (pl) => pl.id === formData.value.plan
      );
      if (plan) {
        return getPlanPriceByCurrency(plan, "usd");
      }

      return 0;
    });

    const selectedPlan = computed(() => {
      const plan = store.getters.getCustomerPlans.find(
        (pl) => pl.id === formData.value.plan
      );
      return plan;
    });

    const formSubmit = async () => {
      try {
        if (!useActiveSubscription.value) {
          reference.value = Math.floor(Math.random() * 1000000000 + 1);
          // (checkoutRef.value as any).payWithPaystack();
          // Trigger stripe
          // get new client secret
          const resp = await ApiService.post("initialize-payment/", {
            amount: totalPrice.value + totalFees.value,
            description: `Payment for ${formData.value.applicants.length} KYC verification(s)`,
            meta: {
              applicants: JSON.stringify(getApplicantsData()),
            },
          } as any);
          // open modal
          stripeSecret.value = resp.data;
          showModal(AppModal.STRIPE_MODAL);
        } else {
          await createClient(activeSubscription.value!.id);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    };

    const getPlanPrice = (plan) =>
      (!plan.is_custom
        ? getPlanPriceByCurrency(plan, "usd")
        : plan.currency_prices_by_features?.usd || plan.price_by_features) || 0; // todo price by features USD

    const totalPrice = computed(() => {
      const plan = store.getters.getCustomerPlans.find(
        (pl) => pl.id === formData.value.plan
      );
      const price = plan ? getPlanPrice(plan) : 0;
      return price * formData.value.applicants.length;
    });

    const totalFees = computed(() => {
      let total = 0;
      let totalPercent = 0;

      fees.value.forEach((fee) => {
        if (fee.fee_type === FeeType.PRICE) {
          total += +getFeePriceByCurrency(fee, "usd");
        } else {
          totalPercent += +fee.amount;
        }
      });

      const percentFeePrice =
        totalPercent && totalPrice.value
          ? (totalPercent / 100) * totalPrice.value
          : 0;

      return percentFeePrice + total;
    });

    const amlTotal = computed(() => {
      const amlApplicants = formData.value.applicants.filter(
        (a) => !!a.aml_plan
      );

      return amlApplicants.reduce((total, curr) => {
        const plan = amlPlans.value.find((p) => p.id == curr.aml_plan);
        total += +(getPlanPriceByCurrency(plan, "usd") || 0);
        return total;
      }, 0);
    });

    const billedAmount = computed(() => {
      return Math.round(
        (totalPrice.value + totalFees.value + amlTotal.value) * 100
      );
    });

    return {
      store,
      horizontalWizardRef,
      previousStep,
      handleStep,
      formSubmit,
      totalSteps,
      currentStepIndex,
      processPayment,
      reference,
      checkoutRef,
      selectedPlanPrice,
      selectedPlan,
      formData,
      onApplicantsUpdate,
      fees,
      totalFees,
      totalPrice,
      hasAgreed,
      billedAmount,
      handleSelectSubscription,
      loadingActiveSubscription,
      activeSubscription,
      useActiveSubscription,
      fetchingResources,
      stripeSecret,
      amlPlans,
      amlTotal,
    };
  },
});
