
import { defineComponent, ref } from "vue";

import { hideModal } from "@/core/helpers/dom";
import { AppModal } from "@/assets/ts/_utils/enums/modals.enum";

export default defineComponent({
  name: "consent-modal",
  components: {},
  props: {},
  setup() {
    const customPlanModalRef = ref<HTMLDivElement | null>(null);

    const submit = () => {
      hideModal(AppModal.CONSENT_MODAL);
    };

    return {
      customPlanModalRef,
      submit,
    };
  },
});
