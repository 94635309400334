
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { FormAttachment } from "@/store/models/formAttachment";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { COMPLETED, IN_REVIEW, SUBMITTED, CENCEL } from "@/constants";

export default defineComponent({
  name: "customer-form-review",
  components: {},
  data() {
    const formattachment: FormAttachment = {};
    return {
      tab: 1,
      screenPdfSrc: "",
      otherPdfSrc: "",
      review: {
        connected_form: this.formID,
        personal_info: true,
        self_reported_info: true,
        screening_disclosure: true,
        other_disclosure: true,
        crimincal_history: true,
        address_history: true,
        education_history: true,
        employment_history: true,
        desc_personal_info: "",
        desc_self_reported_info: "",
        desc_screening_disclosure: "",
        desc_other_disclosure: "",
        desc_employment_history: "",
        desc_crimincal_history: "",
        desc_address_history: "",
        desc_education_history: "",
        kycreview: {},
      },
      screeningDisclouser: formattachment,
      otherDisclouser: formattachment,
      reviewDisable: false,
      reviewErrors: {},
      metadata: {},
      kycReview: {
        kyc: this.kycID,
        official_doc: true,
        official_doc_back: true,
        live_photo: true,
        doc_type: true,
        desc_official_doc: "",
        desc_self_reported_info: "",
        desc_official_doc_back: "",
        desc_live_photo: "",
        desc_doc_type: "",
      },
      docTypes: ["ID Card", "Passport", "Driving License", "Residence Permit"],
    };
  },
  computed: {
    ...mapGetters({
      form: "getForm",
      formReview: "getFormReview",
      errors: "getFormErrors",
      roles: "getRoles",
    }),
    formID() {
      return this.$route.params.formId;
    },
    allApproved(): boolean {
      return (
        this.review.address_history &&
        this.review.personal_info &&
        this.review.screening_disclosure &&
        this.review.employment_history &&
        this.review.education_history &&
        this.review.self_reported_info &&
        this.review.crimincal_history &&
        this.kycReview.official_doc &&
        this.kycReview.official_doc_back &&
        this.kycReview.live_photo &&
        this.kycReview.doc_type
      );
    },
  },
  methods: {
    ...mapActions({
      fetchFormByID: Actions.FETCH_FORM_BY_ID,
      fetchFormReviewByID: Actions.FETCH_FORM_REVIEW_BY_ID,
      fetchUser: Actions.USER_DATA,
      userData: Actions.USER_DATA,
      updateFormStatus: Actions.UPDATE_FORM_STATUS,
    }),
    ...mapMutations({
      setError: Mutations.SET_ERROR,
    }),
    getMonth(date: string) {
      const d = new Date(date);
      return `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? "0" : ""}${
        d.getMonth() + 1
      }`;
    },
    async fetchDisclosureFile(src: string) {
      const response = await ApiService.get(src, {
        responseType: "blob",
      } as any);
      if (ApiService.isAxiosResponse(response)) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        return objectUrl;
      }
      return "";
    },
    async submit(completed: boolean) {
      const isKycReview = this.form && this.form.kyc;
      const reviewMapped = this.review;
      if (isKycReview) {
        reviewMapped.kycreview = this.kycReview;
      }
      const response = await ApiService.post(
        "connectedFormsReview/",
        reviewMapped as any
      );
      if (ApiService.isAxiosResponse(response)) {
        await this.updateFormStatus({
          id: this.form.id,
          data: { status: completed ? COMPLETED : CENCEL },
        });
        this.$router.push({ name: "admin-form-submission" });
      }
      if (ApiService.isAxiosError(response) && response.response) {
        this.reviewErrors = response.response.data;
      }
    },
    async loadData() {
      if (Object.keys(this.formReview).length !== 0) {
        //this.reviewDisable = true;
        this.review.connected_form = this.formReview.connected_form;
        this.review.desc_other_disclosure =
          this.formReview.desc_other_disclosure;
        this.review.desc_personal_info = this.formReview.desc_personal_info;
        this.review.desc_screening_disclosure =
          this.formReview.desc_screening_disclosure;
        this.review.desc_self_reported_info =
          this.formReview.desc_self_reported_info;
        this.review.desc_employment_history =
          this.formReview.desc_employment_history;
        this.review.desc_crimincal_history =
          this.formReview.desc_crimincal_history;
        this.review.desc_address_history = this.formReview.desc_address_history;
        this.review.desc_education_history =
          this.formReview.desc_education_history;
        this.review.crimincal_history = this.formReview.crimincal_history;
        this.review.address_history = this.formReview.address_history;
        this.review.education_history = this.formReview.education_history;
        this.review.employment_history = this.formReview.employment_history;
        this.review.other_disclosure = this.formReview.other_disclosure;
        this.review.personal_info = this.formReview.personal_info;
        this.review.screening_disclosure = this.formReview.screening_disclosure;
        this.review.self_reported_info = this.formReview.self_reported_info;
        if (this.formReview.kycreview) {
          this.kycReview = this.formReview.kycreview;
        }
      }
      if (Object.keys(this.form).length !== 0) {
        if (this.form.status === SUBMITTED) {
          await this.updateFormStatus({
            id: this.form.id,
            data: { status: IN_REVIEW },
          });
        }
        this.review.connected_form = this.form.id;
        this.screeningDisclouser = this.form.form_attachment.find(
          (item: any) =>
            item.media.alt_text === "screenig concent" &&
            item.media.src &&
            item.media.src.length > 10
        );

        if (
          this.screeningDisclouser !== undefined &&
          this.screeningDisclouser.media &&
          typeof this.screeningDisclouser.media !== "number" &&
          this.screeningDisclouser.media.src
        ) {
          this.screenPdfSrc = this.screeningDisclouser.media.src;
        }

        if (this.form && this.form.kyc && this.form.kyc.length > 0) {
          this.metadata = JSON.parse(this.form.kyc.doc_metadata);
        }
      }
    },
    getStatusMessage(isApproved) {
      return isApproved ? "Approved" : "Declined";
    },
  },
  async mounted() {
    //reset error
    this.setError({});
    await this.fetchUser();
    if (this.formID) {
      await this.fetchFormByID(this.formID);
      await this.fetchFormReviewByID(this.formID);
      await this.loadData();
    } else {
      this.$router.push({
        name: this.roles.admin ? "admin-404" : "customer-404",
      });
    }
    if (this.roles.customer) {
      this.reviewDisable = true;
    }
  },
});
