
import { computed, defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import { useStore } from "vuex";
import { RouteLocationRaw, useRouter } from "vue-router";
import Step3 from "@/components/customer/IDVerification/steps/Step3.vue";
import Step2 from "@/components/customer/IDVerification/steps/Step2.vue";
import Step1 from "@/components/customer/IDVerification/steps/Step1.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import useNotify from "@/core/composables/notify";
import {
  CardType,
  IDType,
  IDVerificationType,
} from "@/assets/ts/_utils/enums/id-verification-type.enum";
import { IDVerification } from "@/assets/ts/_utils/models/IDVerification";
import useEmitter from "@/core/composables/emitter";
import { cloneDeep } from "lodash";
import { AppEvent } from "@/assets/ts/_utils/enums/custom-events.enum";
import { showModal } from "@/core/helpers/dom";
import { AppModal } from "@/assets/ts/_utils/enums/modals.enum";
import PlansModal from "@/components/modals/PlansModal.vue";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import { PlanType } from "@/assets/ts/_utils/enums/plan.enum";
import useFees from "@/core/composables/useFees";
import paystack from "@/components/customer/Paystack.vue";
import useGetPlans from "@/core/composables/getPlans";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import useSubscriptions from "@/core/composables/useSubscriptions";
import { Subscription } from "@/assets/ts/_utils/models/SubscriptionModel";
import ConsentModal from "@/components/modals/ConsentModal.vue";
import Step0 from "./steps/Step0.vue";
import { PreMedia } from "@/assets/ts/_utils/models/MediaModel";
import useAppConfig from "@/core/composables/useAppConfig";
import { GHANA_CODE } from "@/constants";
import StripeModal from "@/components/modals/StripeModal.vue";
import useCurrencies from "@/core/composables/useCurrencies";
import { IDVerificationWithAML } from "@/assets/ts/_utils/models/IDVerification";

export default defineComponent({
  name: "IDVerification",
  components: {
    Step1,
    Step2,
    Step3,
    PlansModal,
    // paystack,
    ConsentModal,
    Step0,
    StripeModal,
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const activeSubscription = ref<Subscription | null>(null);
    const useActiveSubscription = ref(false);
    const hasAgreed = ref(false);
    const store = useStore();
    const router = useRouter();
    const { toastError, toastErrorRaw } = useNotify();
    const emitter = useEmitter();
    const { data: appConfig, loading: loadingAppConfig } = useAppConfig();
    const stripeSecret = ref("");

    const { data: plans, loading: loadingPlans } = useGetPlans({
      plan_type: PlanType.ID_VERIFICATION,
    });
    const { data: otherCardVerificationPlans, loading: loadingOtherCardPlans } =
      useGetPlans({
        plan_type: PlanType.OTHER_CARD_VERIFICATIONS,
      });

    const { fetchActiveSubscription, loading: loadingActiveSubscription } =
      useSubscriptions();
    const { getPlanPriceByCurrency, getFeePriceByCurrency } = useCurrencies();
    const { data: amlPlans, loading: loadingAMLPlans } = useGetPlans({
      plan_type: PlanType.AML_VERIFICATION,
    });

    const basePlan = computed(() => {
      return plans.value.find(
        (p) =>
          (p.is_base || p.meta?.is_base) &&
          p.plan_type === PlanType.ID_VERIFICATION
      );
    });

    const bulkPlans = computed(() => {
      if (!basePlan.value) return plans.value;

      return plans.value.filter((p) => p.id !== basePlan.value?.id);
    });

    const verificationType = ref<IDVerificationType | null>(null);
    const country = ref<string>("");

    const verifications = ref<IDVerificationWithAML[]>([]);
    const plan = ref<Plan | null>(null);
    let reference = ref(Math.floor(Math.random() * 1000000000 + 1));
    const checkoutRef = ref(null);

    const maxTableId = computed<number>(() => {
      let max = 0;
      if (verifications.value.length) {
        const ids = verifications.value.map((v) => v.id as number);
        max = Math.max(...ids);
      }
      return max + 1;
    });

    const canAdd = (amount = 1) => {
      if (!useActiveSubscription.value || !activeSubscription.value) {
        return true;
      }

      return (
        amount +
          activeSubscription.value.count_used +
          verifications.value.length <=
        activeSubscription.value.limit
      );
    };

    const addVerification = (values) => {
      const c = canAdd();
      if (c) {
        verifications.value.push({ ...values, id: maxTableId.value });
      } else {
        toastError({
          text: `You can not add a verification as the limit of your subscription will be exceeded`,
        });
      }
    };
    const updateVerification = (values) => {
      const valueIdx = verifications.value.findIndex((v) => v.id == values.id);
      if (valueIdx >= 0) {
        const newVerifications = cloneDeep(verifications.value);
        newVerifications[valueIdx] = cloneDeep(values);
        verifications.value = newVerifications;
      }
    };

    const deleteVerification = (id) => {
      verifications.value = verifications.value.filter((v) => v.id !== id);
    };

    const bulkUpload = (records) => {
      const len = records.length;
      if (canAdd(len)) {
        records.forEach((record, idx) => {
          let cardType = record["Card Type"];
          if (cardType && !Object.keys(CardType).includes(cardType)) {
            cardType = null;
          }

          verifications.value.push({
            id: maxTableId.value,
            id_number: record["ID"],
            first_name: record["First Name"],
            last_name: record["Last Name"],
            card_type: cardType,
          });
        });
      } else {
        toastError({
          text: `You can not add ${len} verification(s) as the limit of your subscription will be exceeded`,
        });
      }
    };

    const isGhana = computed(
      () =>
        country.value === GHANA_CODE ||
        country.value === "Ghana" ||
        country.value === "ghana"
    );

    onMounted(async () => {
      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );
      emitter.on(AppEvent.ID_VERIFICATION_ADD, addVerification);
      emitter.on(AppEvent.ID_VERIFICATION_UPDATE, updateVerification);

      setCurrentPageBreadcrumbs("ID Verifications", ["Veridy IDs"]);
      await store.dispatch(Actions.GET_FEES);

      const sub = await fetchActiveSubscription();
      activeSubscription.value = sub;
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return 0;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const handleStep = () => {
      if (currentStepIndex.value === 2 && !isGhana.value) {
        const allVerificationsHaveMedia = verifications.value.every(
          (v) => !!v.uploaded_id
        );
        if (!allVerificationsHaveMedia) {
          return toastError({
            text: "One or more of your verifications require an uploaded id image",
          });
        }
      }

      let increment = 1;
      // if (currentStepIndex.value === 0 && !isGhana.value) {
      //   increment = 2;
      // }
      currentStepIndex.value += increment;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext(increment);
    };

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }
      let decrement = 1;
      // if (currentStepIndex.value === 2 && !isGhana.value) {
      //   decrement = 2;
      // }

      currentStepIndex.value -= decrement;

      _stepperObj.value.goPrev(decrement);
    };

    const handleSelectVerificationType = (selected) => {
      // if user already filled some verifications, prompt swal
      verificationType.value = selected;
      if (selected === IDVerificationType.BULK) {
        showModal(AppModal.PLANS_MODAL);
      } else {
        plan.value = basePlan.value ? cloneDeep(basePlan.value) : plan.value;
      }
      useActiveSubscription.value = false;
    };

    const totalPrice = computed(() => {
      const usdPrice = getPlanPriceByCurrency(plan.value, "usd");
      if (verificationType.value === IDVerificationType.SINGULAR) {
        return usdPrice * (verifications.value.length || 1);
      }
      return usdPrice;
    });

    const { data: fees, applyFees } = useFees();
    const totalFees = computed(() => applyFees(totalPrice.value));
    const incPTotal = computed(() => {
      let incPTotal = 0;
      const verificationsWithIncP = verifications.value.filter(
        (v) => v.include_picture
      );
      if (verificationsWithIncP.length && appConfig.value?.incp_price) {
        incPTotal = appConfig.value?.incp_price * verificationsWithIncP.length;
      }
      return incPTotal;
    });
    const amlTotal = computed(() => {
      const amlApplicants = verifications.value.filter((a) => !!a.aml_plan);

      return amlApplicants.reduce((total, curr) => {
        const plan = amlPlans.value.find((p) => p.id == curr.aml_plan);
        total += getPlanPriceByCurrency(plan, "usd" || 0);
        return total;
      }, 0);
    });
    const subtotal = computed(() => {
      return (
        +totalFees.value + +totalPrice.value + incPTotal.value + amlTotal.value
      );
    });

    const billedAmount = computed(() => {
      return Math.round(subtotal.value * 100);
    });

    const getVerificationsToAdd = async (subscriptionId?) => {
      const data = await Promise.all(
        verifications.value.map(async (v: any) => {
          const { uploaded_id, uploaded_id_back, fileBlob, ...data } = v;
          const uploadedId = uploaded_id as PreMedia;
          const uploadedIdBack = uploaded_id_back as PreMedia;
          if (uploadedId?.mediaPromise) {
            const file = await uploadedId.mediaPromise;
            data.uploaded_id_file = file.id;
          }
          if (uploadedIdBack?.mediaPromise) {
            const fileBack = await uploadedIdBack.mediaPromise;
            data.uploaded_id_back_file = fileBack.id;
          }
          return {
            ...data,
            subscription: subscriptionId,
            country: country.value,
          };
        })
      );
      return data;
    };

    const createBulkVerifications = async (subscriptionId) => {
      const data = await getVerificationsToAdd(subscriptionId);

      await ApiService.post("bulk-id-verifications/", {
        verifications: data,
      } as any);

      Swal.fire({
        title: "Verifications Submitted!",
        text: "An email would be sent upon completion pf the process. You can also view status of your verifications in the history pages",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "View verifications",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        const params: RouteLocationRaw = {
          name: "customer-verifications",
        };
        router.push(params);
      });
    };

    const handleSubmit = async () => {
      if (!useActiveSubscription.value) {
        reference.value = Math.floor(Math.random() * 1000000000 + 1);
        const data = await getVerificationsToAdd();
        const resp = await ApiService.post("initialize-payment/", {
          amount: subtotal.value,
          description: `Payment for ${verifications.value.length} ID verification(s)`,
          meta: {
            id_verifications: JSON.stringify(data),
          },
        } as any);
        // open modal
        stripeSecret.value = resp.data;
        showModal(AppModal.STRIPE_MODAL);
      } else {
        await createBulkVerifications(activeSubscription.value!.id);
      }
    };

    const processPayStackPayment = async (providerPayment?) => {
      try {
        const payment = await ApiService.post("payments/", {
          company: store.getters.currentUser.id,
          reference_number:
            providerPayment?.paymentIntent?.id || reference.value,
          amount: providerPayment?.paymentIntent?.amount || billedAmount.value,
          gateway: "STRIPE",
          currency: "Dollar",
        } as any);
        // hit create subscription API
        let subscription;
        if (
          isGhana.value &&
          plan.value &&
          plan.value?.id !== basePlan.value?.id
        ) {
          subscription = await ApiService.post("subscriptions/", {
            plan: plan.value.id,
            // limit: plan.value.limit || plan.value.meta?.limit,
            payment: payment.data?.id,
          } as any);
        }

        // hit bulk verifications API
        await createBulkVerifications(subscription?.data?.id);
      } catch (error: any) {
        toastErrorRaw(error);
      }
    };

    const disableContinue = computed(() => {
      if (currentStepIndex.value === 0) {
        return !country.value;
      } else if (currentStepIndex.value === 1) {
        return !verificationType.value && !useActiveSubscription.value;
      } else {
        return verifications.value.length === 0;
      }
    });

    const handleSelectSubscription = () => {
      useActiveSubscription.value = true;
      const subscriptionPlan = plans.value.find(
        (p) => p.id === activeSubscription.value?.plan
      );

      if (subscriptionPlan) {
        plan.value = subscriptionPlan;
      }

      handleStep();
    };

    return {
      store,
      horizontalWizardRef,
      previousStep,
      handleStep,
      totalSteps,
      currentStepIndex,
      verificationType,
      handleSelectVerificationType,
      bulkUpload,
      deleteVerification,
      verifications,
      plan,
      fees,
      subtotal,
      totalPrice,
      handleSubmit,
      totalFees,
      checkoutRef,
      processPayStackPayment,
      reference,
      disableContinue,
      plans,
      basePlan,
      bulkPlans,
      loadingActiveSubscription,
      activeSubscription,
      handleSelectSubscription,
      hasAgreed,
      loadingPlans,
      loadingOtherCardPlans,
      useActiveSubscription,
      appConfig,
      loadingAppConfig,
      incPTotal,
      country,
      isGhana,
      stripeSecret,
      amlPlans,
      amlTotal,
      billedAmount,
    };
  },
});
