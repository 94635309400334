
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import WebCam from "./camera.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "KYC",
  components: {
    WebCam,
  },
  watch: {
    form() {
      if (this.form && this.form.kyc && this.form.kyc.length > 0) {
        this.disable = true;
        this.disableCamera = true;
        this.official_doc = this.form.kyc[0]?.official_doc?.src;
        this.live_photo = this.form.kyc[0]?.live_photo?.src;
      }
    },
  },
  data() {
    const file = new File(["#"], "passport");
    return {
      disable: false,
      disableCamera: false,
      official_doc: "",
      official_doc_url: "",
      live_photo: "",
      live_photo_url: "",
      errors: {},
      kycBtn: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "currentUser",
      form: "getForm",
      formID: "getFormId",
    }),
  },
  methods: {
    ...mapActions({
      fetchForms: Actions.FETCH_FORMS,
      fetchFormByID: Actions.FETCH_FORM_BY_ID,
      fetchUser: Actions.USER_DATA,
    }),
    livephoto(data: { url: string; file: any }) {
      this.live_photo_url = data.url;
      this.live_photo = data.file;
      //this.disableCamera = true;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async previewFile(event: Event) {
      const input = event.target as HTMLInputElement;

      if (!input.files?.length) {
        return;
      }

      const file = input.files[0];
      if (file && file.size / (1024 * 1024) > 2) {
        Swal.fire({
          text: "File cannot be larger than 2 MB",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        return;
      }
      this.official_doc_url = URL.createObjectURL(file);
      this.official_doc = (await this.toBase64(file)) as string;
    },
    showErrorSwal(messsage) {
      Swal.fire({
        text: messsage,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Try again!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      });
    },
    async submitKYC() {
      if (!this.official_doc || !this.live_photo) {
        this.showErrorSwal("Passport Image and Selfie are mandatory");
        return;
      }
      this.kycBtn = true;
      const officialDocResponse = await ApiService.createMedia(
        this.official_doc,
        "passport image",
        "passport"
      );
      const liveMediaResponse = await ApiService.createMedia(
        this.live_photo,
        "selfie",
        "selfie"
      );
      if (officialDocResponse && liveMediaResponse) {
        if (
          ApiService.isAxiosResponse(officialDocResponse) &&
          ApiService.isAxiosResponse(liveMediaResponse)
        ) {
          const kyc: any = {
            doc_type: 1,
            connected_form: this.form.id,
            official_doc: officialDocResponse.data.id,
            live_photo: liveMediaResponse.data.id,
          };
          const response = await ApiService.post("kyc/", kyc);
          if (ApiService.isAxiosResponse(response)) {
            await this.fetchFormByID(this.formID);
          }
          if (ApiService.isAxiosError(response) && response.response) {
            this.errors = response.response.data;
          }
          Swal.fire({
            text: "You have successfully submitted your kyc details for verification!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(async () => {
            this.kycBtn = false;
          });
        }
        if (
          ApiService.isAxiosError(officialDocResponse) &&
          officialDocResponse.response
        ) {
          this.errors = officialDocResponse.response.data;
          this.showErrorSwal(this.errors);
        }
        if (
          ApiService.isAxiosError(liveMediaResponse) &&
          liveMediaResponse.response
        ) {
          this.errors = liveMediaResponse.response.data;
          this.showErrorSwal(this.errors);
        }
      }
    },
  },
  async created() {
    await this.fetchForms(this.user.id);
    if (this.formID) {
      await this.fetchFormByID(this.formID);
      this.official_doc = this.form.kyc[0]?.official_doc?.src;
      this.live_photo = this.form.kyc[0]?.live_photo?.src;
    } else {
      this.disable = true;
      this.disableCamera = true;
    }
  },
});
