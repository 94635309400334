
import { defineComponent, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
enum VerificationType {
  KYC = "KYC",
  ID_VERIFICATION = "ID Verification",
  AML_VERIFICATION = "AML Verification",
}

export default defineComponent({
  name: "customer-setup-verification",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const verificationType = ref<VerificationType | null>(null);

    const proceed = () => {
      if (!verificationType.value) return;

      if (verificationType.value === VerificationType.KYC) {
        router.push({
          name: "customer-addemployee",
        });
      } else if (verificationType.value === VerificationType.ID_VERIFICATION) {
        router.push({
          name: "customer-id-verifications",
        });
      } else if (verificationType.value === VerificationType.AML_VERIFICATION) {
        router.push({
          name: "customer-aml-verifications",
        });
      }
    };

    return { verificationType, VerificationType, proceed };
  },
});
