
import { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "badge",
  props: {
    variant: {
      default: "primary",
      type: String as PropType<
        "primary" | "success" | "danger" | "info" | "warning"
      >,
    },
    light: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    badgeClass() {
      if (this.light) {
        return `badge-light-${this.variant}`;
      }
      return `badge-${this.variant}`;
    },
  },
});
