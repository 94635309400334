export enum SubscriptionScope {
  ID_VERIFICATION = 1,
  KYC = 2,
  BGC = 3,
}

export enum SubscriptionStatus {
  PENDING = 1,
  ACTIVE = 2,
  EXPIRED = 3,
}
