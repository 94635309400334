
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import PlanCard from "@/components/PlanCard.vue";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import { useRoute } from "vue-router";
import { computed } from "vue";
import useCurrencies from "@/core/composables/useCurrencies";

export default defineComponent({
  name: "step-2",
  components: {
    PlanCard,
  },
  props: {
    plan: {
      type: Number,
    },
  },
  emits: ["plan-selected"],
  setup(props, { emit }) {
    // let plans = ref<Plan[]>([]);
    const store = useStore();
    const { getPlanPriceByCurrency } = useCurrencies();

    const plans = computed(() =>
      store.getters.getCustomerPlans?.filter((p) => !p.is_custom && !p.is_adhoc)
    );

    return {
      plans,
      getPriceByCurrency: getPlanPriceByCurrency,
    };
  },
});
