import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-6 col-12 mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTModalCard = _resolveComponent("KTModalCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_KTModalCard, {
        title: "KYC",
        description: "KYC Documents",
        image: _ctx.getIllustrationsPath('8.png'),
        "button-text": "Get Started",
        onButtonSubmit: _ctx.handleKycSubmit
      }, null, 8, ["image", "onButtonSubmit"])
    ])
  ]))
}