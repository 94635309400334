
import { defineComponent, ref, computed, PropType } from "vue";

export default defineComponent({
  components: {},
  props: {
    size: {
      type: String as PropType<"sm" | "lg">,
    },
    disabled: { type: Boolean },
    label: { type: String },
  },
  setup(props) {
    const sizeClass = computed(() => {
      let btnClasses = "form-switch";
      if (props.size) {
        btnClasses += props.size;
      }

      return btnClasses;
    });
    return { sizeClass };
  },
});
