import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import useNotify from "./notify";
import { PaginatedParams } from "@/assets/ts/_utils/models/Api";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { GHANA_CODE } from "@/constants";
import { KYCDocType } from "@/assets/ts/_utils/enums/doc-type.enum";
import useMedia from "./useMedia";
import { cleanData } from "../helpers/kyc";
import { ADMIN, CUSTOMER } from "@/constants";

const useKYCs = () => {
  const loading = ref<boolean>(false);
  const store = useStore();

  const { toastErrorRaw } = useNotify();

  const { uploadFile, dataURLtoFile } = useMedia();

  const fetchKYCs = async (params: PaginatedParams = {}) => {
    const role = store.getters.currentUser
      ? store.getters.currentUser.user_type
      : "";

    const { query, ...otherParams } = params || {};
    try {
      loading.value = true;
      const resp = await ApiService.query(`kyc${query || ""}`, {
        params: otherParams,
        hideLoader: true,
      });
      if (role == ADMIN) {
        console.log("admin - reach here");
        await store.dispatch(
          Actions.GET_ADMIN_DASHBOARD,
          store.getters.currentUser.id
        );
      } else if (role === CUSTOMER) {
        console.log("customer - reach here");
        await store.dispatch(
          Actions.GET_CUSTOMER_DASHBOARD2,
          store.getters.currentUser.id
        );
      }
      return resp.data.results;
    } catch (err: any) {
      toastErrorRaw(err);
    } finally {
      loading.value = false;
    }
  };

  const updateKYC = async (kycId: number, payload: any) => {
    try {
      const resp = await ApiService.patch(`kyc/${kycId}/`, payload);
      return resp.data;
    } catch (err: any) {
      toastErrorRaw(err);
    }
  };

  const verifyCardId = async (kyc, values) => {
    try {
      if (
        values.citizenship_country === GHANA_CODE &&
        values.doc_type === KYCDocType.NATIONAL_IDENTITY
      ) {
        const resp = await store.dispatch(
          Actions.VERIFY_CARD_ID,
          values.id_number
        );
        if (!resp?.is_valid) {
          throw new Error("ID Card not valid");
        }
        return resp;
      } else {
        return true;
      }
    } catch (error) {
      toastErrorRaw(error);
    }
  };

  const updateKYCMedia = async (kyc: any, values: any) => {
    try {
      const { official_doc, official_doc_back } = values;

      const promises: any[] = [];
      if (official_doc?.src) {
        const frontFilename = `${
          official_doc.alt_text
        }_${new Date().getTime()}`;
        promises.push(
          uploadFile({
            file: dataURLtoFile(official_doc.src, frontFilename),
            file_name: frontFilename,
            description: official_doc.media_description,
            alt: official_doc.alt_text,
          })
        );
      }

      if (!official_doc_back?.id && official_doc_back?.src) {
        const backFilename = `${
          official_doc_back.alt_text
        }_${new Date().getTime()}`;

        promises.push(
          uploadFile({
            file: dataURLtoFile(official_doc_back.src, backFilename),
            file_name: backFilename,
            description: official_doc_back.media_description,
            alt: official_doc_back.alt_text,
          })
        );
      }

      const [uploadedFront, uploadedBack] = await Promise.all(promises);
      const resp = await ApiService.patch(`kyc/update-media/${kyc.id}/`, {
        official_doc_file: uploadedFront.id,
        ...(uploadedBack
          ? {
              official_doc_back_file: uploadedBack.id,
            }
          : {}),
      } as any);
      return resp?.data;
    } catch (err: any) {
      toastErrorRaw(err);
    }
  };

  const cleanKYCFormData = (kyc) => {
    kyc = kyc || {};
    return cleanData(kyc);
  };

  const fetchApplicantKYC = async (userId: string) => {
    try {
      loading.value = true;
      const resp = await ApiService.getWithoutSlug(`applicant-kycs/${userId}/`);
      return resp.data;
    } catch (err: any) {
      toastErrorRaw(err);
    } finally {
      loading.value = false;
    }
  };

  const generateDocName = (docType, username) => {
    return `${username}_${docType}_${new Date().getTime()}`;
  };

  const stepUpdateFunctions = {
    // 1: verifyCardId,
    2: updateKYCMedia,
  };

  return {
    loading,
    fetchKYCs,
    updateKYC,
    fetchApplicantKYC,
    stepUpdateFunctions,
    cleanKYCFormData,
    generateDocName,
  };
};

export default useKYCs;
