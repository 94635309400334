export enum IDDocType {
  GHANA_CARD = "1",
  RESIDENCE_CARD = "2",
  PASSPORT = "3",
  NATIONALHEALTHINSURANCE = "4",
  VOTERSID = "5",
  STUDENTID = "6",
  EMPLOYEEID = "7",
  OTHERS = "8",
}

export enum KYCDocType {
  PASSPORT = "1",
  NATIONAL_IDENTITY = "0",
  DRIVING_LICENSE = "2",
  RESIDENCE_PERMIT = "3",
  GHANA_CARD = "4",
}

export const docTypes = Object.freeze({
  [KYCDocType.PASSPORT]: "Passport",
  [KYCDocType.NATIONAL_IDENTITY]: "ID Card",
  [KYCDocType.DRIVING_LICENSE]: "Driving License",
  [KYCDocType.RESIDENCE_PERMIT]: "Residence Permit",
});
export const ghanaDocTypes = Object.freeze({
  ...docTypes,
  [KYCDocType.GHANA_CARD]: "Ghana Card",
});
