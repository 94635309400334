import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import useNotify from "./notify";
// import { SubscriptionScope } from "@/assets/ts/_utils/enums/subscription.enum";

const usePayments = () => {
  const loading = ref<boolean>(false);
  const { toastErrorRaw, toastSuccess } = useNotify();

  const fetchBilling = async () =>
    // params = { scope: SubscriptionScope.ID_VERIFICATION }
    {
      const { toastErrorRaw } = useNotify();

      try {
        loading.value = true;
        const resp = await ApiService.get("payments");
        // console.log("🚀 ~ usePayments ~ resp:", resp);
        return resp.data;
      } catch (err: any) {
        toastErrorRaw(err);
      } finally {
        loading.value = false;
      }
    };

  const fetchBillingChart = async (params) =>
    // params = {}
    {
      const { toastErrorRaw } = useNotify();

      try {
        loading.value = true;
        const resp = await ApiService.query("billing", { params });
        console.log("🚀 ~ usePayments ~ resp:", resp);
        return resp.data;
      } catch (err: any) {
        toastErrorRaw(err);
      } finally {
        loading.value = false;
      }
    };

  // const fetchSubscriptions = async () => {
  //   const { toastErrorRaw } = useNotify();

  //   try {
  //     loading.value = true;
  //     const resp = await ApiService.get("subscriptions");
  //     return resp.data.results;
  //   } catch (err: any) {
  //     toastErrorRaw(err);
  //   } finally {
  //     loading.value = false;
  //   }
  // };

  // const isSubscriptionExceeded = (subscription, total) => {
  //   return subscription.count_used + total > subscription.limit;
  // };

  const createPayment = async (payment) => {
    try {
      const resp = await ApiService.post("payments/", payment);
      return resp.data;
    } catch (error) {
      toastErrorRaw(error);
    }
  };

  const getPayment = async (paymentId, throwOnNotFound?) => {
    try {
      const resp = await ApiService.get(`payments/${paymentId}`);
      return resp.data;
    } catch (error) {
      if (throwOnNotFound) toastErrorRaw(error);
    }
  };

  const getProviderPayment = async (paymentId, throwOnNotFound?) => {
    try {
      const resp = await ApiService.getWithoutSlug(
        `provider-payment/?id=${paymentId}`
      );
      return resp.data;
    } catch (error) {
      if (throwOnNotFound) toastErrorRaw(error);
    }
  };

  return {
    loading,
    fetchBilling,
    fetchBillingChart,
    createPayment,
    getPayment,
    getProviderPayment,
    // fetchActiveSubscription,
    // fetchSubscriptions,
    // isSubscriptionExceeded,
  };
};

export default usePayments;
