
import { defineComponent } from "vue";

export default defineComponent({
  name: "client-dashboard-card",
  props: {
    title: String,
    description: String,
    buttonText: String,
    image: String,
    modalId: String,
  },
  components: {},
  methods: {
    buttonClick() {
      this.$emit("buttonSubmit");
    },
  },
});
