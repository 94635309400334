
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import Step0 from "./steps/Step0.vue";
import Step1 from "./steps/Step1.vue";
import Step2 from "./steps/Step2.vue";
import Step3 from "./steps/Step3.vue";
import Step4 from "./steps/Step4.vue";
import { useForm } from "vee-validate";
import * as Yup from "yup";
import { KYCDocType } from "@/assets/ts/_utils/enums/doc-type.enum";
import { StepperComponent } from "@/assets/ts/components";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import useNotify from "@/core/composables/notify";
import useKYCs from "@/core/composables/useKYCs";
import Spinner from "@/components/Spinner.vue";
import { defaultBGCData } from "@/store/models/form";

interface IStep1 {
  citizenship_country: string;
  doc_type: string;
  id_number?: string;
}

interface IStep2 {
  official_doc: string;
  official_doc_back: string;
}

export default defineComponent({
  name: "kyc-form",
  props: {
    loading: { type: Boolean, default: true },
    kyc: { type: Object as PropType<any>, required: true },
  },
  components: {
    Spinner,
    Step0,
    Step1,
    Step2,
    // Step3,
    // Step4,
  },
  setup(props) {
    const store = useStore();

    const { updateKYC, stepUpdateFunctions } = useKYCs();

    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);

    const currentStepIndex = ref(props.kyc?.current_step || 0);

    const formData = ref<any>({
      citizenship_country: "",
      doc_type: KYCDocType.NATIONAL_IDENTITY,
      official_doc: defaultBGCData.kyc.official_doc,
      official_doc_back: defaultBGCData.kyc.official_doc_back,
      live_photo: defaultBGCData.kyc.live_photo,
      id_number: "",
    });

    const syncWizardUI = () => {
      if (_stepperObj.value) {
        _stepperObj.value.updateCurrentStepIndex(currentStepIndex.value);
        _stepperObj.value.goNext();
      }
    };

    const createAccountSchema = [
      Yup.object({}),
      Yup.object({
        citizenship_country: Yup.string().required().label("Country"),
        doc_type: Yup.string().required().label("Document Type"),
        id_number: Yup.string()
          .label("Enter your ID Number for verification")
          .trim()
          // .min(11, "Min characters must be 11")
          // .max(15, "Characters cannot be more than 15")
          .when("doc_type", {
            is: (doc_type) => doc_type == KYCDocType.NATIONAL_IDENTITY,
            then: Yup.string().required("ID Number is required"),
            otherwise: Yup.string(),
          }),
      }),
      Yup.object({
        official_doc: Yup.object({
          src: Yup.string().required().label("Front Image"),
          alt_text: Yup.string(),
          media_description: Yup.string(),
        }),
        official_doc_back: Yup.object({
          src: Yup.string().label("Back Image"),
          alt_text: Yup.string(),
          media_description: Yup.string(),
        }).nullable(),
      }),
      Yup.object({
        live_photo: Yup.object({
          alt_text: Yup.string(),
          media_description: Yup.string(),
          src: Yup.string()
            .required("Photo required")
            .typeError("")
            .label("Selfie Image"),
        }).nullable(),
      }),
    ];

    const currentSchema = computed(() => {
      return createAccountSchema[currentStepIndex.value];
    });

    const isPassport = computed(
      () => formData.value.doc_type == KYCDocType.PASSPORT
    );

    const isClient = computed(() => !store.getters.getRoles?.admin);

    const {
      resetForm,
      handleSubmit,
      setFieldValue,
      setFieldTouched,
      validateField,
    } = useForm<any>({
      validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return 0;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const handleStep = handleSubmit(async (values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      const stepUpdateFn = stepUpdateFunctions[currentStepIndex.value];
      if (stepUpdateFn) {
        const updateResp = await stepUpdateFn(props.kyc, values);
        if (!updateResp) return;
      }

      const resp = await updateKYC(props.kyc.id, {
        ...values,
        current_step: currentStepIndex.value + 1,
      });

      if (resp) {
        currentStepIndex.value++;
        syncWizardUI();
      }
    });

    const previousStep = async () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;
      _stepperObj.value.goPrev();
    };

    const updateMediaField = (fieldName: string, fieldValue: string) => {
      const srcField = `${fieldName}.src`;
      setFieldValue(srcField as never, fieldValue as never);
      setFieldValue(
        `${fieldName}.media_description` as never,
        `${fieldName} image` as never
      );
      setFieldValue(
        `${fieldName}.alt_text` as never,
        `${fieldName} image alt` as never
      );

      setFieldTouched(srcField as never, true);
      validateField(srcField as never);
    };

    const updateFrontField = (fieldValue) => {
      updateMediaField("official_doc", fieldValue);
    };

    const updateBackField = (fieldValue) => {
      updateMediaField("official_doc_back", fieldValue);
    };

    onMounted(async () => {
      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );
      syncWizardUI();
    });

    watch(
      () => props.kyc,
      (curVal, oldVal) => {
        if (curVal && curVal != oldVal) {
          formData.value = curVal;

          const newCurrStep = curVal.current_step || 0;
          currentStepIndex.value =
            newCurrStep >= totalSteps.value
              ? totalSteps.value - 1
              : newCurrStep;
          syncWizardUI();
        }
      }
    );

    return {
      handleStep,
      horizontalWizardRef,
      previousStep,
      currentStepIndex,
      totalSteps,
      isPassport,
      updateFrontField,
      updateBackField,
      isClient,
    };
  },
});
