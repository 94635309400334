import { RouteRecordRaw } from "vue-router";

import { loginGuard } from "./route-guards";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    redirect: "/sign-in",
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        beforeEnter: loginGuard,
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/confirm-password-reset/:token",
        name: "confirm-password-reset",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/ConfirmPasswordReset.vue"
          ),
      },
      {
        path: "/reviewer-invitation",
        name: "reviewer-invitation",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/ReviewerInvitation.vue"
          ),
      },
      {
        path: "/invitation",
        name: "invitation",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Invitation.vue"),
      },
      {
        path: "/email-validation/:email/:email_otp?",
        name: "email-validation",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/EmailValidation.vue"
          ),
      },
      {
        path: "/plans",
        name: "plans",
        component: () => import("@/components/Plans.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

export default routes;
