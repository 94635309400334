
import { defineComponent, ref, PropType, computed, watch } from "vue";

import { hideModal } from "@/core/helpers/dom";
import { AppModal } from "@/assets/ts/_utils/enums/modals.enum";
import { IDVerification } from "@/assets/ts/_utils/models/IDVerification";
import SummaryCard from "@/components/customer/SummaryCard.vue";
import useNotify from "@/core/composables/notify";
import { IDVerificationStatus } from "@/assets/ts/_utils/enums/id-verification-status.enum";
import ApiService from "@/core/services/ApiService";
import { IFile, Media } from "@/assets/ts/_utils/models/MediaModel";
import {
  IDType,
  CardType,
} from "@/assets/ts/_utils/enums/id-verification-type.enum";
import Spinner from "@/components/Spinner.vue";
import { AMLVerificationStatus } from "@/assets/ts/_utils/enums/id-verification-status.enum";
import { GHANA_CODE, GHANA_NAME } from "@/constants";
import moment from "moment";
import { changeDateFormat } from "@/core/helpers/date";

enum ImageView {
  FRONT = "FRONT",
  BACK = "BACK",
}

export default defineComponent({
  name: "verification-modal",
  components: { SummaryCard, Spinner },
  props: {
    verification: {
      type: Object as PropType<IDVerification | null>,
      required: true,
    },
    isAdmin: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  emits: ["updated"],
  setup(props, { emit }) {
    const viewVerificationModalRef = ref<HTMLDivElement | null>(null);
    const showRaw = ref(false);
    const settingStatus = ref(false);
    const idImage = ref<Media | IFile | null>(null);
    const idImageBack = ref<Media | IFile | null>(null);
    const currentView = ref<ImageView>(ImageView.FRONT);

    const { toastErrorRaw } = useNotify();

    const submit = () => {
      hideModal(AppModal.VIEW_VERIFICATION_MODAL);
    };

    const meta = computed(() => {
      if (
        props.verification?.meta &&
        typeof props.verification?.meta === "string"
      ) {
        return JSON.parse(props.verification.meta);
      }

      if (
        props.verification?.meta &&
        typeof props.verification?.meta === "object"
      ) {
        return props.verification?.meta;
      }
      return {};
    });

    const setStatus = async (status: IDVerificationStatus) => {
      try {
        settingStatus.value = true;
        const resp = await ApiService.patch(
          `id-verifications/${props.verification?.id}/status/`,
          { status } as any
        );
        emit("updated", resp.data);
        submit();
      } catch (error: any) {
        console.log("errorrr", error);
        toastErrorRaw(error);
      }
      settingStatus.value = false;
    };

    const fetchMedia = async (mediaId, mediaBackId?) => {
      // if (!props.isAdmin) {
      //   return;
      // }

      try {
        const media = await ApiService.get(`/media/${mediaId}`);
        idImage.value = media.data;
        if (mediaBackId) {
          const mediaBack = await ApiService.get(`/media/${mediaBackId}`);
          idImageBack.value = mediaBack.data;
        } else {
          idImageBack.value = null;
        }
      } catch (error: any) {
        toastErrorRaw(error);
      }
    };
    const fetchFile = async (fileId, fileBackId?) => {
      try {
        const file = await ApiService.get(`/files/${fileId}`);
        idImage.value = file.data;
        if (fileBackId) {
          const fileBack = await ApiService.get(`/files/${fileBackId}`);
          idImageBack.value = fileBack.data;
        } else {
          idImageBack.value = null;
        }
      } catch (error: any) {
        toastErrorRaw(error);
      }
    };

    watch(
      () => props.verification,
      (curVal, oldVal) => {
        if (curVal?.uploaded_id_file) {
          fetchFile(curVal?.uploaded_id_file, curVal?.uploaded_id_back_file);
        } else if (curVal?.uploaded_id) {
          fetchMedia(curVal?.uploaded_id, curVal?.uploaded_id_back);
        }
      }
    );

    const activeSrc = computed(() => {
      if (currentView.value !== ImageView.BACK) {
        const front = idImage.value as any;
        return (
          front?.url ||
          front?.src ||
          meta.value?.Picture ||
          meta.value?.Photo ||
          meta.value?.DriverImage
        );
      }
      const back = idImageBack.value as any;
      return back?.url || back?.src;
    });

    const toggleView = () => {
      if (currentView.value === ImageView.FRONT) {
        currentView.value = ImageView.BACK;
      } else {
        currentView.value = ImageView.FRONT;
      }
    };

    const omitDisplayedMeta = ["Signature", "ResponseCode", "Picture"];

    const displayedMeta = computed(() => {
      const m = {};
      Object.keys(meta.value || {})
        .filter((k) => !omitDisplayedMeta.includes(k))
        .forEach((k) => (m[k] = meta.value[k]));
      return m;
    });

    // update this to return for global
    const gviveMeta = computed<any>(() => {
      // only ghana verifications processed by GVIVE

      // if (
      //   props.verification?.country !== GHANA_CODE &&
      //   props.verification?.country !== GHANA_NAME
      // ) {
      // eslint-disable-next-line no-constant-condition

      const { front = {}, back = {} } = meta.value || {};

      let name = meta.value.name;
      if (!name) {
        name = `${front.FirstName || props.verification?.first_name} ${
          front.LastName || props.verification?.last_name
        }`;
      }

      return {
        Name: {
          value: name,
          info: "ID holder name",
          icon: "/media/icons/duotune/communication/com001.svg",
          variant: "bg-light-warning",
          color: "warning",
        },
        "ID Number": {
          value: (front.DocumentNumber || props.verification?.id_number) ?? "",
          info: "Document number",
          icon: "/media/icons/duotune/communication/com007.svg",
          variant: "bg-light-info",
          color: "info",
        },
        "D.O.B": {
          value: front.DateOfBirth ?? "",
          info: "Date of Birth",
          icon: "/media/icons/duotune/communication/com001.svg",
          variant: "bg-light-success",
          color: "success",
        },
        Country: {
          value: front.CountryRegion ?? "",
          info: "Issuing Country",
          icon: "/media/icons/duotune/communication/com006.svg",
          variant: "bg-light-warning",
          color: "warning",
        },
        "Expiration Date": {
          value: front.DateOfExpiration ?? "",
          info: "Date of ID Expiration",
          icon: "/media/icons/duotune/communication/com006.svg",
          variant: "bg-light-info",
          color: "info",
        },
        Address: {
          value: front.Address ?? "",
          info: "Holder Address",
          icon: "/media/icons/duotune/communication/com006.svg",
          variant: "bg-light-danger",
          color: "danger",
        },
      };
      // return {
      //   Name: {
      //     value: `${props.verification?.first_name ?? ""} ${
      //       props.verification?.last_name ?? ""
      //     }`,
      //     info: "ID holder name",
      //     icon: "/media/icons/duotune/communication/com001.svg",
      //     variant: "bg-light-warning",
      //     color: "warning",
      //   },
      //   "ID Number": {
      //     value: props.verification?.id_number ?? "",
      //     info: "ID number",
      //     icon: "/media/icons/duotune/communication/com001.svg",
      //     variant: "bg-light-info",
      //     color: "info",
      //   },
      //   Country: {
      //     value: props.verification?.country ?? "",
      //     info: "ID holder country",
      //     icon: "/media/icons/duotune/communication/com001.svg",
      //     variant: "bg-light-danger",
      //     color: "danger",
      //   },
      // };

      // switch (props.verification?.card_type) {
      //   case CardType.DRIVERS_LICENSE:
      //     return {
      //       Name: {
      //         value: meta.value.name,
      //         info: "ID holder name",
      //         icon: "/media/icons/duotune/communication/com001.svg",
      //         variant: "bg-light-warning",
      //         color: "warning",
      //       },
      //       "Date of Birth": {
      //         value: meta.value.DateOfBirth,
      //         info: "Year ID holder was born",
      //         icon: "/media/icons/duotune/communication/com002.svg",
      //         variant: "bg-light-danger",
      //         color: "danger",
      //       },
      //       "Processing Center": {
      //         value: meta.value.ProcessingCenter,
      //         info: "The processing center of origin for this document",
      //         icon: "/media/icons/duotune/communication/com003.svg",
      //         variant: "bg-light-info",
      //         color: "info",
      //       },
      //       "Issue Date": {
      //         value: meta.value.DateOfIssue,
      //         info: "Issue date for this document",
      //         icon: "/media/icons/duotune/communication/com005.svg",
      //         variant: "bg-light-success",
      //         color: "success",
      //       },
      //       "Certificate Date": {
      //         value: meta.value.CertificateDate,
      //         info: "Cert. date for this document",
      //         icon: "/media/icons/duotune/communication/com006.svg",
      //         variant: "bg-light-warning",
      //         color: "warning",
      //       },
      //       "Licence Class": {
      //         value: meta.value.ClassOfLicence,
      //         info: "Licence class for this document",
      //         icon: "/media/icons/duotune/communication/com007.svg",
      //         variant: "bg-light-info",
      //         color: "info",
      //       },
      //       "Expiry Date": {
      //         value: meta.value.ExpiryDate,
      //         info: "Expiry. date for this document",
      //         icon: "/media/icons/duotune/communication/com008.svg",
      //         variant: "bg-light-danger",
      //         color: "danger",
      //       },
      //       PIN: {
      //         value: meta.value.PIN,
      //         info: "Document PIN",
      //         icon: "/media/icons/duotune/communication/com009.svg",
      //         variant: "bg-light-success",
      //         color: "success",
      //       },
      //     };
      //   case CardType.PASSPORT:
      //     return {
      //       "Passport Num": {
      //         value: meta.value.PassportNo,
      //         info: "ID Number",
      //         icon: "/media/icons/duotune/finance/fin006.svg",
      //         variant: "bg-light-warning",
      //         color: "warning",
      //       },
      //       Name: {
      //         value: `${meta.value.FirstName} ${meta.value.MiddleName ?? ""} ${
      //           meta.value.LastName ?? ""
      //         }`,
      //         info: "Passport holder as appears on document",
      //         icon: "/media/icons/duotune/art/art001.svg",
      //         variant: "bg-light-danger",
      //         color: "danger",
      //       },
      //       Nationality: {
      //         value: meta.value.Nationality,
      //         info: "Passport holder country of origin",
      //         icon: "/media/icons/duotune/art/art002.svg",
      //         variant: "bg-light-info",
      //         color: "info",
      //       },
      //       "Date of Birth": {
      //         value: meta.value.DateOfBirth,
      //         info: "Year ID holder was born",
      //         icon: "/media/icons/duotune/art/art003.svg",
      //         variant: "bg-light-success",
      //         color: "success",
      //       },
      //       Gender: {
      //         value: meta.value.Gender,
      //         icon: "/media/icons/duotune/art/art004.svg",
      //         variant: "bg-light-primary",
      //         color: "primary",
      //       },
      //       "Place of Birth": {
      //         value: meta.value.PlaceOfIssue,
      //         icon: "/media/icons/duotune/art/art005.svg",
      //         variant: "bg-light-warning",
      //         color: "warning",
      //       },
      //       "Expiry Date": {
      //         value: meta.value.ExpiryDate,
      //         info: "Expiry. date for this document",
      //         icon: "/media/icons/duotune/art/art006.svg",
      //         variant: "bg-light-info",
      //         color: "info",
      //       },
      //       "Issue Date": {
      //         value: meta.value.IssueDate,
      //         info: "Issue date for this document",
      //       },
      //     };
      //   case CardType.RESIDENCE_CARD:
      //     return {};
      //   case CardType.VOTERS_CARD:
      //     return {
      //       Name: {
      //         value: meta.value.Fullname,
      //         info: "ID holder name",
      //         icon: "/media/icons/duotune/files/fil001.svg",
      //         variant: "bg-light-warning",
      //         color: "warning",
      //       },
      //       "Polling Station": {
      //         value: meta.value.PollingStation,
      //         icon: "/media/icons/duotune/files/fil002.svg",
      //         variant: "bg-light-success",
      //         color: "success",
      //       },
      //       "Voter Id": {
      //         value: meta.value.VoterID,
      //         info: "ID Number",
      //         icon: "/media/icons/duotune/files/fil003.svg",
      //         variant: "bg-light-danger",
      //         color: "danger",
      //       },
      //       Age: {
      //         value: meta.value.Age,
      //         info: "Passport holder age",
      //         icon: "/media/icons/duotune/files/fil004.svg",
      //         variant: "bg-light-info",
      //         color: "info",
      //       },
      //       Sex: {
      //         value: meta.value.Sex,
      //         icon: "/media/icons/duotune/files/fil005.svg",
      //         variant: "bg-light-warning",
      //         color: "warning",
      //       },
      //       "Registration Date": {
      //         value: meta.value.RegDate,
      //         info: "Date of registration for this document",
      //         icon: "/media/icons/duotune/files/fil006.svg",
      //         variant: "bg-light-success",
      //         color: "success",
      //       },
      //       "Place of Birth": {
      //         value: meta.value.PlaceOfIssue,
      //         icon: "/media/icons/duotune/files/fil007.svg",
      //         variant: "bg-light-danger",
      //         color: "danger",
      //       },
      //       "Expiry Date": {
      //         value: meta.value.ExpiryDate,
      //         info: "Expiry. date for this document",
      //         icon: "/media/icons/duotune/files/fil008.svg",
      //         variant: "bg-light-primary",
      //         color: "primary",
      //       },
      //       "Issue Date": {
      //         value: meta.value.DateOfIssue,
      //         info: "Issue date for this document",
      //         icon: "/media/icons/duotune/files/fil009.svg",
      //         variant: "bg-light-success",
      //         color: "success",
      //       },
      //       "Date of Birth": {
      //         value: meta.value.DateOfBirth,
      //         info: "Year ID holder was born",
      //         icon: "/media/icons/duotune/files/fil010.svg",
      //         variant: "bg-light-info",
      //         color: "info",
      //       },
      //     };
      //   case CardType.SSNIT:
      //     return {
      //       FSSNo: {
      //         value: meta.value.FSSNo,
      //         info: "ID Number",
      //         icon: "/media/icons/duotune/finance/fin006.svg",
      //         variant: "bg-light-warning",
      //         color: "warning",
      //       },
      //       Name: {
      //         value: meta.value.FullName,
      //         info: "ID holder name",
      //         icon: "/media/icons/duotune/finance/fin007.svg",
      //         variant: "bg-light-info",
      //         color: "info",
      //       },
      //       Sex: {
      //         value: meta.value.Sex,
      //         icon: "/media/icons/duotune/finance/fin008.svg",
      //         variant: "bg-light-warning",
      //         color: "warning",
      //       },
      //       "Issue Date": {
      //         value: meta.value.DateOfIssue,
      //         info: "Issue date for this document",
      //         icon: "/media/icons/duotune/finance/fin009.svg",
      //         variant: "bg-light-success",
      //         color: "success",
      //       },
      //       "Date of Birth": {
      //         value: meta.value.BirthDate,
      //         info: "Year ID holder was born",
      //         icon: "/media/icons/duotune/finance/fin001.svg",
      //         variant: "bg-light-danger",
      //         color: "danger",
      //       },
      //       "Serial Number": {
      //         value: meta.value.CardSerial,
      //         icon: "/media/icons/duotune/finance/fin002.svg",
      //         variant: "bg-light-warning",
      //         color: "warning",
      //       },
      //     };
      //   case CardType.GHANA_CARD:
      //     return {
      //       Name: {
      //         value: meta.value.name,
      //         info: "ID holder name",
      //         icon: "/media/icons/duotune/finance/fin007.svg",
      //         variant: "bg-light-info",
      //         color: "info",
      //       },
      //       "ID Number": {
      //         value: props.verification?.id_number ?? "",
      //         info: "ID number",
      //         icon: "/media/icons/duotune/communication/com001.svg",
      //         variant: "bg-light-info",
      //         color: "info",
      //       },
      //       Country: {
      //         value: props.verification?.country ?? "",
      //         info: "ID holder country",
      //         icon: "/media/icons/duotune/communication/com001.svg",
      //         variant: "bg-light-danger",
      //         color: "danger",
      //       },
      //     };

      //   default:
      //     return {};
      // }
    });

    const matchedAml = computed<any>(() => {
      const obj =
        props.loading || !props.verification?.aml?.matched_aml
          ? {}
          : JSON.parse(props.verification.aml?.matched_aml);

      return obj;
    });

    const hasExpired = computed<boolean>(() => {
      if (!meta.value) return false;
      const expiryDate = meta.value?.front?.DateOfExpiration;
      if (!expiryDate) {
        return false;
      }

      const formattedDate = changeDateFormat(expiryDate);

      return moment(formattedDate).isBefore();
    });

    return {
      viewVerificationModalRef,
      submit,
      idImage,
      meta,
      showRaw,
      setStatus,
      IDVerificationStatus,
      settingStatus,
      IDType,
      currentView,
      ImageView,
      activeSrc,
      toggleView,
      idImageBack,
      omitDisplayedMeta,
      displayedMeta,
      gviveMeta,
      matchedAml,
      AMLVerificationStatus,
      hasExpired,
    };
  },
});
