import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  AdminDashboard,
  AdminDashboardUnTransformed,
} from "@/store/models/admin";
import { mapDataToLast12Months } from "@/filters";

@Module
export default class AdminModule extends VuexModule {
  errors = {};
  companies = [];
  companyUsers = [];
  dashboardUnTransformed: AdminDashboardUnTransformed = {
    companies: [],
    approved: [],
    inProgress: [],
    inReview: [],
    submitted: [],
    declined: [],
    invited: [],
    acceptedInvites: [],
    kycApproved: [],
    kycInProgress: [],
    kycInReview: [],
    kycSubmitted: [],
    kycDeclined: [],
  };
  dashboard: AdminDashboard = {
    companies: [],
    approved: [],
    inProgress: [],
    inReview: [],
    submitted: [],
    declined: [],
    invited: [],
    acceptedInvites: [],
    kycApproved: [],
    kycInProgress: [],
    kycInReview: [],
    kycSubmitted: [],
    kycDeclined: [],
  };
  dashboardFetched = false;

  /**
   * Get authentification errors
   * @returns array
   */
  get getAdminErrors() {
    return this.errors;
  }

  get getCompanies() {
    return this.companies;
  }

  get getCompanyUsers() {
    return this.companyUsers;
  }

  get getAdminDashboard() {
    return this.dashboard;
  }

  get getAdminDashboardUnTransformed() {
    return this.dashboardUnTransformed;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    if (error && typeof error !== "string") {
      this.errors = { ...error };
    } else {
      this.errors = { error: ["Server Error. Please try again in a while"] };
    }
  }

  @Mutation
  [Mutations.SET_COMPANIES](companies) {
    this.companies = companies ?? [];
  }

  @Mutation
  [Mutations.SET_ADMIN_DASHBOARD_FETCHED](dashboardFetched) {
    this.dashboardFetched = dashboardFetched;
  }

  @Mutation
  [Mutations.SET_COMPANY_USERS](companyUsers) {
    this.companyUsers = companyUsers ?? [];
  }

  @Mutation
  [Mutations.SET_ADMIN_DASHBOARD](dashboard) {
    this.dashboardUnTransformed = dashboard[0] ?? {};
    if (this.dashboardUnTransformed != undefined) {
      this.dashboard.companies = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).companies
      );
      this.dashboard.approved = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).approved
      );
      this.dashboard.declined = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).declined
      );
      this.dashboard.inProgress = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).inProgress
      );
      this.dashboard.inReview = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).inReview
      );
      this.dashboard.invited = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).pendingInvites
      );
      this.dashboard.submitted = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).submitted
      );
      this.dashboard.acceptedInvites = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).acceptedInvites
      );
      this.dashboard.kycApproved = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).kycApproved
      );
      this.dashboard.kycDeclined = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).kycDeclined
      );
      this.dashboard.kycInProgress = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).kycInProgress
      );
      this.dashboard.kycInReview = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).kycInReview
      );
      this.dashboard.kycSubmitted = mapDataToLast12Months(
        (this.dashboardUnTransformed as any).kycSubmitted
      );
    }
  }

  @Action
  [Actions.GET_ADMIN_DASHBOARD]() {
    // if (this.dashboardFetched) {
    //   return;
    // }
    return ApiService.get("admin/dashboard")
      .then((response: any) => {
        this.context.commit(
          Mutations.SET_ADMIN_DASHBOARD,
          response.data.results
        );
        this.context.commit(Mutations.SET_ADMIN_DASHBOARD_FETCHED, true);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.GET_COMPANIES](options?: {
    search?: string;
    user_type?: number;
    page?: number;
  }) {
    return ApiService.query("users", {
      params: options,
      hideLoader: true,
    })
      .then((response: any) => {
        if (response.data.count && response.data.count > 0) {
          this.context.commit(Mutations.SET_COMPANIES, response.data.results);
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.GET_COMPANY_USERS](options?: {
    search?: string;
    user_type?: number;
    parent?: number;
    page?: number;
  }) {
    return ApiService.query("users", { params: options })
      .then((response: any) => {
        if (response.data.count && response.data.count > 0) {
          this.context.commit(
            Mutations.SET_COMPANY_USERS,
            response.data.results
          );
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }
}
