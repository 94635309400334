
import { defineComponent, ref, onMounted, watch } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";
import { formatDate, getBadgeClass, getStatusInt } from "@/filters";

interface IClient {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  last_modified: string;
}

export default defineComponent({
  name: "admin-submitted-clients",
  components: {
    Datatable,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const checkedCustomers = ref([]);
    const formStatuses = ref([
      "In Progress",
      "Approved",
      "Declined",
      "Reviewing",
      "Submitted",
    ]);
    const tableHeader = ref([
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "First Name",
        key: "first_name",
        sortable: true,
      },
      {
        name: "Last Name",
        key: "last_name",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "created",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<IClient>>([]);
    const initCustomers = ref<Array<IClient>>([]);

    const fetchData = async () => {
      tableData.value = [];
      let apiConfig = {};
      if (route.params?.status) {
        apiConfig = getStatusInt(route.params.status)
          ? { status: getStatusInt(route.params.status) }
          : {};
      }
      await store.dispatch(Actions.FETCH_FORMS_QUERY, apiConfig);
      const [errorName] = Object.keys(store.getters.getErrors);
      const error = store.getters.getErrors[errorName];
      if (error) {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        tableData.value = store.getters.getForms;
      }
    };

    onMounted(async () => {
      fetchData();
    });

    watch(
      () => route.params,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          fetchData();
        }
      }
    );

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const reviewForm = (id, status, event) => {
      event.preventDefault();
      if (status == 1) {
        return;
      }

      router.push({ name: "admin-form-review", params: { formId: id } });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<IClient> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const dummy = (event) => {
      event.preventDefault();
      console.log("test");
    };

    return {
      tableData,
      tableHeader,
      deleteCustomer,
      search,
      searchItems,
      checkedCustomers,
      deleteFewCustomers,
      reviewForm,
      formStatuses,
      formatDate,
      getBadgeClass,
    };
  },
});
