
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    id: { type: String, required: true },
    showSeparator: { type: Boolean, default: true },
  },
  setup() {
    // When in a list, accordions parent id must be data-kt-customer-payment-method to ensure only one accordion in a group at a time is open.

    return {};
  },
});
