
import { ref, onMounted, nextTick, PropType } from "vue";

import {
  AMLVerification,
  IDVerification,
} from "@/assets/ts/_utils/models/IDVerification";

import ExcelParser from "@/components/buttons/ExcelParser/ExcelParser.vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import useEmitter from "@/core/composables/emitter";
import { AppEvent } from "@/assets/ts/_utils/enums/custom-events.enum";
import AMLVerificationForm from "../modals/AMLVerificationForm.vue";

const defaultRow = () => ({
  first_name: "",
  last_name: "",
  country: "",
});

export default {
  components: { ExcelParser, Datatable, AMLVerificationForm },
  name: "aml-step-2",
  props: {
    verifications: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["bulk-upload", "delete"],
  setup(props, { emit }) {
    const selectedRow = ref<AMLVerification>(defaultRow());
    const tableKey = ref(1);
    const currentRowId = ref(-1);
    const emitter = useEmitter();

    const tableHeader = ref([
      {
        name: "First Name",
        key: "first_name",
        sortable: true,
      },
      {
        name: "Last Name",
        key: "last_name",
        sortable: true,
      },
      {
        name: "Country",
        key: "country",
      },
      {
        name: "Actions",
        key: "edit",
      },
    ]);

    const editRecord = (values) => {
      selectedRow.value = defaultRow();
      currentRowId.value = values.id;
      selectedRow.value = values;
    };

    const addRecord = (e) => {
      e.preventDefault();
      currentRowId.value = 0;
      selectedRow.value = defaultRow();
    };

    const deleteRecord = async (idx) => {
      emit("delete", idx);
      await nextTick();
      tableKey.value = new Date().getTime();
    };

    const bulkAddRecords = (records) => {
      emit("bulk-upload", records);
    };

    const idVerififcationUploadSheetUrl =
      process.env.VUE_APP_ID_VERIFICATION_UPLOAD_SHEET_URL;

    onMounted(async () => {
      emitter.on(
        AppEvent.AML_VERIFICATION_ADD,
        () => (tableKey.value = new Date().getTime())
      );
      emitter.on(
        AppEvent.AML_VERIFICATION_UPDATE,
        () => (tableKey.value = new Date().getTime())
      );
    });

    return {
      editRecord,
      addRecord,
      idVerififcationUploadSheetUrl,
      bulkAddRecords,
      tableHeader,
      deleteRecord,
      selectedRow,
      tableKey,
    };
  },
};
