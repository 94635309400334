
import { defineComponent, onMounted } from "vue";
import Statistics from "@/components/admin/Dashboard/Statistics.vue";
import OverallStatistics from "@/components/admin/Dashboard/OverallStatistics.vue";
import Trends from "@/components/admin/Dashboard/Trends.vue";
// using only customer barchart to support all
import BarChart from "@/components/customer/Dashboard/BarChart.vue";
import ReportChart from "@/components/admin/Dashboard/ReportChart.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "dashboard",
  components: {
    Statistics,
    Trends,
    BarChart,
    ReportChart,
    OverallStatistics,
  },
  setup() {
    const store = useStore();
    onMounted(async () => {
      await store.dispatch(Actions.GET_ADMIN_DASHBOARD);
    });
  },
});
