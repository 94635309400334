import moment from "moment";
import { Fee, FeeType } from "@/assets/ts/_utils/models/FeeModel";
import { ProgressStatus } from "@/assets/ts/_utils/enums/progress-status.enum";

const filters = {
  formatCurrency(amount: string | number, currency = "USD") {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
      currencyDisplay: "narrowSymbol",
    }).format(+amount || 0);
  },
  formatFee(fee: Fee, currency = "GHS") {
    if (fee.fee_type === FeeType.PRICE) {
      return this.formatCurrency(fee.amount, currency);
    }
    const formattedFee = Number(fee.amount).toFixed(1);
    return `${formattedFee}%`;
  },
  formatPaystackAmount(price: number) {
    return Math.round(price * 100);
  },
  formatDate(date: string, format = "LL") {
    return moment(date).format(format);
  },
  formatTimeFromNow(date: string) {
    return moment(date).fromNow();
  },
  formatSecret(text: string, n: number) {
    const rest = text.length - n;
    return (
      text.slice(0, Math.ceil(rest / 2) + 1) +
      "*".repeat(n) +
      text.slice(-Math.floor(rest / 2) + 1)
    );
  },
  getStatusBadgeVariant(status: ProgressStatus) {
    switch (status) {
      case ProgressStatus.COMPLETED:
        return "success";
      case ProgressStatus.CANCEL:
        return "danger";
      case ProgressStatus.IN_PROGRESS:
        return "warning";
      default:
        return "primary";
    }
  },
  getStatusLabel(status: ProgressStatus) {
    switch (status) {
      case ProgressStatus.COMPLETED:
        return "Approved";
      case ProgressStatus.CANCEL:
        return "Declined";
      case ProgressStatus.IN_PROGRESS:
        return "In Progress";
      case ProgressStatus.SUBMITTED:
        return "Submitted";
      case ProgressStatus.IN_REVIEW:
        return "Reviewing";
      default:
        return "";
    }
  },
};

export default filters;
