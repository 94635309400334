
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import useNotify from "@/core/composables/notify";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import { UserType } from "@/constants";
import { PlanType } from "@/assets/ts/_utils/enums/plan.enum";
import { PropType } from "vue";
import { extractElementFormError } from "@/core/helpers/errors";

export default defineComponent({
  name: "create-client-modal",
  components: {},
  emits: ["saved"],
  props: {
    planType: {
      type: Number as PropType<PlanType>,
      default: PlanType.BGC,
    },
  },
  setup(props, { emit }) {
    const { toastErrorRaw, toastError, toastSuccess } = useNotify();
    const store = useStore();
    const router = useRouter();

    const formRef = ref<null | HTMLFormElement>(null);
    const addUserModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref({
      first_name: "",
      last_name: "",
      email: "",
      date_of_birth: "",
      company: "",
      plan: "",
    });

    const rules = ref({
      first_name: [
        {
          required: true,
          message: "First name is required",
          trigger: "change",
        },
      ],
      last_name: [
        {
          required: true,
          message: "Last name is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },
      ],
      company: [
        {
          required: true,
          message: "Company is required",
          trigger: "change",
        },
      ],
    });

    const searchCompanies = async (queryString, cb) => {
      try {
        const resp = await ApiService.query("users", {
          params: {
            search: queryString,
            user_type: UserType.CUSTOMER,
            page: 1,
          },
          hideLoader: true,
        });
        cb(resp.data.results.map((r) => ({ value: r.id, ...r })));
      } catch (error) {
        toastErrorRaw(error);
      }
    };

    const searchPlans = async (queryString, cb) => {
      try {
        const resp = await ApiService.query("plans/", {
          params: {
            search: queryString,
            plan_type: props.planType,
            page: 1,
          },
          hideLoader: true,
        });
        cb(resp.data.results.map((r) => ({ value: r.id, ...r })));
      } catch (error) {
        toastErrorRaw(error);
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid, fields: any) => {
        if (valid) {
          try {
            loading.value = true;
            await ApiService.post("/client/activate/", formData.value as any);
            await toastSuccess({
              text: "Client successfully created",
            });
            formRef.value?.resetFields();
            emit("saved");
            hideModal(addUserModalRef.value);
          } catch (error) {
            toastErrorRaw(error);
          } finally {
            loading.value = false;
          }
        } else {
          toastError({
            text: extractElementFormError(fields),
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addUserModalRef,
      searchCompanies,
      searchPlans,
    };
  },
});
