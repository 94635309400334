
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { getLast12Months, arraySum } from "@/filters";
import { useStore } from "vuex";
import usePayments from "@/core/composables/usePayments";

export default defineComponent({
  name: "widget-12",
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
  },
  setup(props) {
    const chartRef = ref({});
    const {
      fetchBilling,
      fetchBillingChart,
      loading: loadingBilling,
    } = usePayments();
    const value = ref("monthly");

    const options = [
      {
        value: "monthly",
        label: "Monthly",
      },
      {
        value: "yearly",
        label: "Yearly",
      },
    ];
    const color = ref(props.chartColor);
    const chartData = ref([]);
    const store = useStore();
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");
    const baseColor = getCSSVariableValue("--bs-" + color.value);

    // const chartOptions = ref();
    const chartOptions = computed(() => ({
      chart: {
        fontFamily: "inherit",
        type: "bar",
        height: props.chartHeight,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          // horizontal: false,
          // columnWidth: ["50%"],
          // endingShape: "rounded",
        },
      },
      legend: {
        show: false,
      },
      // dataLabels: {
      //   enabled: false,
      // },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        // type: "category",
        // categories: getLast12Months(),
        categories:
          value.value === "monthly"
            ? [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sept",
                "Oct",
                "Nov",
                "Dec",
              ]
            : chartData.value.map((x: any) => x.year),
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        show: false,
        y: 0,
        offsetX: 0,
        offsetY: 0,
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      fill: {
        type: "solid",
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        theme: "dark",
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "" + val + " ";
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        padding: {
          top: 10,
        },
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    }));

    const series = computed(() => {
      // console.log("🚀 ~ series ~ value:", value.value);
      if (value.value === "monthly") {
        // chartRef.value.refresh();
        return [
          {
            name: "Payments",
            data: chartData.value.map((x: any) => x.total_payment),
          },
        ];
      } else if (value.value === "yearly") {
        return [
          {
            name: "Payments",
            data: chartData.value.map((x: any) => x.total_payment),
          },
        ];
      } else {
        return [];
      }
    });

    // watch(
    //   () => store.getters.getCustomerDashboard.submitted,
    //   (curVal, oldVal) => {
    //     if (curVal != oldVal) {
    //       series.value[0].data = curVal;
    //       series.value[1].data = store.getters.getCustomerDashboard.inReview;
    //     }
    //   }
    // );

    // watch(value, async (newValue) => {
    //   if (newValue === "monthly") await fetchBillingChartMethod({});
    //   if (newValue === "yearly") await fetchBillingChartMethod({ year: true });
    //   // console.log(`x is ${newX}`);
    // });

    const fetchBillingChartMethod = async (param) => {
      chartData.value = await fetchBillingChart(param);
      console.log("this :>> ", this);
      // this.$nextTick(() => {
      //   // Access $refs here
      //   this.$refs.chart.refresh();
      // });
    };

    onMounted(async () => {
      await fetchBillingChartMethod({});
    });

    return {
      chartOptions,
      series,
      getLast12Months,
      arraySum,
      store,
      chartData,
      value,
      options,
      fetchBillingChartMethod,
      chartRef,
    };
  },
});
