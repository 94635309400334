import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled,
    class: _normalizeClass(["btn", _ctx.buttonClasses]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clicked')))
  }, _toDisplayString(_ctx.label), 11, _hoisted_1))
}