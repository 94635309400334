
import { AMLVerification } from "@/assets/ts/_utils/models/IDVerification";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import { computed, PropType, getCurrentInstance } from "vue";
import SummaryCard from "@/components/customer/SummaryCard.vue";
import { Fee } from "@/assets/ts/_utils/models/FeeModel";
import useCurrencies from "@/core/composables/useCurrencies";

export default {
  components: { SummaryCard },
  props: {
    verifications: {
      type: Array as PropType<AMLVerification[]>,
    },
    plan: {
      type: Object as PropType<Plan | null>,
    },
    totalPrice: {
      type: Number,
    },
    totalFees: {
      type: Number,
    },
    subtotal: {
      type: Number,
    },
    fees: {
      type: Array as PropType<Fee[]>,
      default: () => [],
    },
  },

  setup(props) {
    const app = getCurrentInstance();
    const { getPlanPriceByCurrency } = useCurrencies();

    const feeBreakdown = computed(() =>
      props.fees
        .map(
          (fee) =>
            `${
              fee.name
            } - ${app?.appContext.config.globalProperties.$filters.formatFee(
              fee
            )}`
        )
        .join(", ")
    );

    return { feeBreakdown, getPlanPriceByCurrency };
  },
};
