
import { defineComponent } from "vue";
import { themeName, demo } from "@/core/helpers/documentation";

export default defineComponent({
  name: "kt-explore",
  setup() {
    const demos = [
      {
        name: "demo1",
        available: true,
      },
      {
        name: "demo2",
        available: true,
      },
      {
        name: "demo3",
      },
      {
        name: "demo4",
      },
      {
        name: "demo5",
      },
      {
        name: "demo6",
      },
      {
        name: "demo7",
        available: true,
      },
      {
        name: "demo8",
      },
      {
        name: "demo9",
      },
      {
        name: "demo10",
      },
      {
        name: "demo11",
      },
      {
        name: "demo12",
      },
      {
        name: "demo13",
      },
    ];

    return {
      themeName,
      demos,
      demo,
    };
  },
});
