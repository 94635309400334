<template>
  <div id="app" class="web-camera-container">
    <!-- <div class="">
      <button
        type="button"
        class="btn is-rounded"
        :class="{ 'btn-success': !isCameraOpen, 'btn-danger': isCameraOpen }"
        @click="toggleCamera"
      >
        <span v-if="!isCameraOpen">Take Selfie</span>
          <span v-else>Close Camera</span>

      </button>
    </div> -->

    <button
      v-if="isCameraOpen"
      class="btn btn-lg btn-danger mb-3"
      @click="toggleCamera"
    >
      Close Camera
    </button>

    <div v-show="isCameraOpen && isLoading" class="camera-loading">
      <ul class="loader-circle">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>

    <div
      v-if="isCameraOpen"
      v-show="!isLoading"
      class="camera-box position-relative"
      :class="{ flash: isShotPhoto }"
      ref="cameraBox"
    >
      <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

      <video
        ref="camera"
        :width="360"
        :height="337.5"
        autoplay
        playsinline
        @play="handleVideoPlaying"
      ></video>

      <canvas
        v-show="false"
        id="photoTaken"
        ref="canvas"
        :width="360"
        :height="337.5"
      ></canvas>
    </div>

    <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
      <button
        type="button"
        class="button"
        @click="takePhoto"
        :disabled="!allowCapture"
      >
        <img
          src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
        />
      </button>
    </div>

    <div
      v-if="isPhotoTaken && isCameraOpen && enableDownload"
      class="camera-download"
    >
      <a
        id="downloadPhoto"
        download="my-photo.jpg"
        class="button"
        role="button"
        @click="downloadImage"
      >
        Download
      </a>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import * as faceapi from "face-api.js";

import { defineComponent } from "vue";

export default defineComponent({
  name: "WebCam",
  emits: ["photoTaken"],
  props: {
    ensureFaceDetection: {
      type: Boolean,
      default: false,
    },
    enableDownload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
      hasLoadedFaceModels: false,
      canDetectFace: false,
      detectionInterval: null,
    };
  },

  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
        this.$emit("close:camera");
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    async createCameraElement() {
      try {
        this.isLoading = true;
        if (this.ensureFaceDetection && !this.hasLoadedFaceModels) {
          await Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
            faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
          ]);
          this.hasLoadedFaceModels = true;
        }

        const constraints = (window.constraints = {
          audio: false,
          video: true,
        });

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch((e) => {
            this.isLoading = false;
            this.isCameraOpen = false;
            alert(
              "We are unable to launch your camera. Please check your browser and camera privacy settings."
            );
          });
      } catch (error) {
        this.isCameraOpen = false;
        alert(
          "We are unable to launch your camera. Please check your browser and camera privacy settings."
        );
      }
      this.isLoading = false;
    },

    stopCameraStream() {
      if (this.$refs.camera) {
        const tracks = this.$refs.camera.srcObject.getTracks();

        tracks.forEach((track) => {
          track.stop();
        });
      }
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 360, 337.5);
      // custom
      const image = this.dataURLtoBlob(
        document.getElementById("photoTaken").toDataURL("image/jpeg")
      );
      const image_url = URL.createObjectURL(image);
      this.$emit("photoTaken", {
        url: image_url,
        file: document.getElementById("photoTaken").toDataURL(),
      });

      this.stopCameraStream();
      this.toggleCamera();
    },

    dataURLtoBlob(dataurl) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },
    handleVideoPlaying() {
      if (this.isCameraOpen && this.ensureFaceDetection) {
        const video = this.$refs.camera;
        const canvas = faceapi.createCanvasFromMedia(video);
        canvas.setAttribute("id", "detect-face");
        this.$refs.cameraBox.append(canvas);
        const displaySize = { width: video.width, height: video.height };
        faceapi.matchDimensions(canvas, displaySize);

        this.detectionInterval = setInterval(async () => {
          const detections = await faceapi.detectAllFaces(
            video,
            new faceapi.TinyFaceDetectorOptions()
          );
          this.canDetectFace = detections.length > 0;
          const resizedDetections = faceapi.resizeResults(
            detections,
            displaySize
          );
          canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
          faceapi.draw.drawDetections(canvas, resizedDetections);
        }, 200);
      }
    },
  },
  computed: {
    allowCapture() {
      if (!this.ensureFaceDetection) return true;
      return this.canDetectFace;
    },
  },
  watch: {
    isCameraOpen(newValue, oldValue) {
      if (!newValue && this.detectionInterval) {
        clearInterval(this.detectionInterval);
      }
    },
  },
});
</script>

<style scoped>
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* padding: 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  /* width: 500px; */
}
.web-camera-container .camera-button {
  margin-bottom: 2rem;
}
.web-camera-container .camera-box .camera-shutter {
  opacity: 0;
  width: 360px;
  height: 337.5px;
  background-color: #fff;
  position: absolute;
}
.web-camera-container .camera-box .camera-shutter.flash {
  opacity: 1;
}
.web-camera-container .camera-shoot {
  margin: 1rem 0;
}
.web-camera-container .camera-shoot button {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.web-camera-container .camera-shoot button img {
  height: 35px;
  object-fit: cover;
}
.web-camera-container .camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
}
.web-camera-container .camera-loading ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}
.web-camera-container .camera-loading .loader-circle {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0;
}
.web-camera-container .camera-loading .loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #999;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 100%;
}
.web-camera-container .camera-loading .loader-circle li:nth-child(2) {
  animation-delay: 0.2s;
}
.web-camera-container .camera-loading .loader-circle li:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes preload {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
</style>

<style>
#detect-face {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
