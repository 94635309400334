
import { computed, defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import { useStore } from "vuex";
import { RouteLocationRaw, useRouter } from "vue-router";
import Step3 from "@/components/customer/AMLVerification/steps/Step3.vue";
import Step2 from "@/components/customer/AMLVerification/steps/Step2.vue";
import Step1 from "@/components/customer/AMLVerification/steps/Step1.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import useNotify from "@/core/composables/notify";
import { AMLVerification } from "@/assets/ts/_utils/models/IDVerification";
import useEmitter from "@/core/composables/emitter";
import { cloneDeep } from "lodash";
import { AppEvent } from "@/assets/ts/_utils/enums/custom-events.enum";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import { PlanType } from "@/assets/ts/_utils/enums/plan.enum";
import useFees from "@/core/composables/useFees";
import { showModal } from "@/core/helpers/dom";
import paystack from "@/components/customer/Paystack.vue";
import useGetPlans from "@/core/composables/getPlans";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ConsentModal from "@/components/modals/ConsentModal.vue";
import useAppConfig from "@/core/composables/useAppConfig";
import useCurrencies from "@/core/composables/useCurrencies";
import StripeModal from "@/components/modals/StripeModal.vue";
import { AppModal } from "@/assets/ts/_utils/enums/modals.enum";

export default defineComponent({
  name: "AMLVerification",
  components: {
    Step1,
    Step2,
    Step3,
    // paystack,
    ConsentModal,
    StripeModal,
  },
  setup() {
    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const hasAgreed = ref(false);
    const store = useStore();
    const router = useRouter();
    const { toastError, toastErrorRaw } = useNotify();
    const emitter = useEmitter();
    const { data: appConfig, loading: loadingAppConfig } = useAppConfig();

    const { data: plans, loading: loadingPlans } = useGetPlans({
      plan_type: PlanType.AML_VERIFICATION,
    });

    const { getPlanPriceByCurrency } = useCurrencies();
    const stripeSecret = ref("");

    const verifications = ref<AMLVerification[]>([]);
    const plan = ref<Plan | null>(null);
    let reference = ref(Math.floor(Math.random() * 1000000000 + 1));
    const checkoutRef = ref(null);

    const maxTableId = computed<number>(() => {
      let max = 0;
      if (verifications.value.length) {
        const ids = verifications.value.map((v) => v.id as number);
        max = Math.max(...ids);
      }
      return max + 1;
    });

    const addVerification = (values) => {
      verifications.value.push({ ...values, id: maxTableId.value });
    };

    const updateVerification = (values) => {
      const valueIdx = verifications.value.findIndex((v) => v.id == values.id);
      if (valueIdx >= 0) {
        const newVerifications = cloneDeep(verifications.value);
        newVerifications[valueIdx] = cloneDeep(values);
        verifications.value = newVerifications;
      }
    };

    const deleteVerification = (id) => {
      verifications.value = verifications.value.filter((v) => v.id !== id);
    };

    const bulkUpload = (records) => {
      records.forEach((record, idx) => {
        verifications.value.push({
          id: maxTableId.value,
          first_name: record["First Name"],
          last_name: record["Last Name"],
          country: record["Country"],
        });
      });
    };

    onMounted(async () => {
      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );
      emitter.on(AppEvent.AML_VERIFICATION_ADD, addVerification);
      emitter.on(AppEvent.AML_VERIFICATION_UPDATE, updateVerification);

      setCurrentPageBreadcrumbs("AML Verifications", ["AML Checks"]);
      await store.dispatch(Actions.GET_FEES);
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return 0;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const handleStep = () => {
      let increment = 1;
      // if (currentStepIndex.value === 0 && !isGhana.value) {
      //   increment = 2;
      // }
      currentStepIndex.value += increment;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext(increment);
    };

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }
      let decrement = 1;
      // if (currentStepIndex.value === 2 && !isGhana.value) {
      //   decrement = 2;
      // }

      currentStepIndex.value -= decrement;

      _stepperObj.value.goPrev(decrement);
    };

    const totalPrice = computed(() => {
      const p = getPlanPriceByCurrency(plan.value, "usd");
      return (p || 0) * (verifications.value.length || 1);
    });

    const { data: fees, applyFees } = useFees();
    const totalFees = computed(() => applyFees(totalPrice.value));

    const subtotal = computed(() => {
      return +totalFees.value + +totalPrice.value;
    });
    const billedAmount = computed(() => {
      return Math.round((+totalFees.value + +totalPrice.value) * 100);
    });

    const createBulkVerifications = async () => {
      await ApiService.post("aml-verifications/", {
        verifications: verifications.value.map((v) => ({
          ...v,
          plan: plan.value?.id,
        })),
      } as any);

      Swal.fire({
        title: "Verifications Submitted!",
        text: "View status of your verifications in the history page",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "View verifications",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        const params: RouteLocationRaw = {
          name: "customer-verifications",
        };
        router.push(params);
      });
    };

    const handleSubmit = async () => {
      reference.value = Math.floor(Math.random() * 1000000000 + 1);
      // (checkoutRef.value as any).payWithPaystack();
      const resp = await ApiService.post("initialize-payment/", {
        amount: totalPrice.value + totalFees.value,
        description: `Payment for ${verifications.value.length} AML verification(s)`,
        meta: {
          applicants: JSON.stringify(verifications.value),
        },
      } as any);
      // open modal
      stripeSecret.value = resp.data;
      showModal(AppModal.STRIPE_MODAL);
    };

    const processPayment = async () => {
      try {
        await ApiService.post("payments/", {
          company: store.getters.currentUser.id,
          reference_number: reference.value,
          amount: billedAmount.value,
          gateway: "STRIPE",
          currency: "Dollar",
        } as any);

        await createBulkVerifications();
      } catch (error: any) {
        toastErrorRaw(error);
      }
    };

    const disableContinue = computed(() => {
      if (currentStepIndex.value > 1) {
        return verifications.value.length === 0;
      }
      return false;
    });

    return {
      store,
      horizontalWizardRef,
      previousStep,
      handleStep,
      totalSteps,
      currentStepIndex,
      bulkUpload,
      deleteVerification,
      verifications,
      plan,
      fees,
      subtotal,
      totalPrice,
      handleSubmit,
      totalFees,
      checkoutRef,
      processPayment,
      reference,
      disableContinue,
      plans,
      hasAgreed,
      loadingPlans,
      appConfig,
      loadingAppConfig,
      stripeSecret,
      billedAmount,
    };
  },
});
