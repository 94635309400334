
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { getLast12Months, arraySum } from "@/filters";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "widget-7",
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
  },
  setup(props) {
    const color = ref(props.chartColor);
    const store = useStore();
    const labelColor = getCSSVariableValue("--bs-" + "gray-800");
    const strokeColor = getCSSVariableValue("--bs-" + "gray-300");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-" + color.value);
    const lightColor = getCSSVariableValue("--bs-light-" + color.value);

    const chartData = ref([]);
    const chartOptions = computed(() => ({
      // series: [
      //   {
      //     name: "Invited Applicants",
      //     data: [0, 2, 0, 4, 2, 5],
      //   },
      // ],
      chart: {
        fontFamily: "inherit",
        // type: "area",
        height: props.chartHeight,
        toolbar: {
          show: false,
        },
        zoom: {
          // enabled: true,
        },
        sparkline: {
          // enabled: true,
        },
      },
      plotOptions: {
        bar: {
          // horizontal: false,
          // columnWidth: ["50%"],
          // endingShape: "rounded",
          dataLabels: {
            position: "top", // You can adjust the position as needed
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        position: "top",
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        // curve: "smooth",
        // show: true,
        // width: 3,
        // colors: [baseColor],
      },
      xaxis: {
        categories: chartData.value.map((x: any) => x.name),
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          trim: true,
          rotate: 0,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        // crosshairs: {
        //   show: false,
        //   position: "front",
        //   stroke: {
        //     color: strokeColor,
        //     width: 1,
        //     dashArray: 3,
        //   },
        // },
        // tooltip: {
        //   enabled: false,
        // },
      },
      yaxis: {
        // min: 0,
        // max: 10,
        show: false,
        labels: {
          show: true,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        theme: "dark",
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "" + val + " ";
          },
        },
      },
      // colors: [lightColor],
      markers: {
        colors: [lightColor],
        strokeColor: [baseColor],
        strokeWidth: 1,
      },
      grid: {
        padding: {
          top: 10,
        },
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    }));

    const series = computed(() => [
      {
        name: "No of uses",
        data: chartData.value.map((x: any) => x.no_uses),
      },
      {
        name: "Limit",
        data: chartData.value.map((x: any) => x.limit),
      },
    ]);

    // watch(
    //   () => store.getters.getCustomerDashboard.approved,
    //   (curVal, oldVal) => {
    //     if (curVal != oldVal) {
    //       series.value[0].data = curVal;
    //       chartOptions.value = {
    //         ...chartOptions.value,
    //         //Disabled for demo
    //         ...{
    //           series: series.value,
    //         },
    //         ...{
    //           yaxis: {
    //             max: Math.max(...curVal) + 4,
    //             min: Math.min(...curVal),
    //             labels: {
    //               show: false,
    //               style: {
    //                 colors: labelColor,
    //                 fontSize: "12px",
    //               },
    //             },
    //           },
    //         },
    //       };
    //     }
    //   }
    // );

    const fetchData = async () => {
      try {
        const resp = await ApiService.get("api-keys");
        console.log("🚀 ~ fetchData ~ resp:", resp);
        chartData.value = resp.data.results;
      } catch (error: any) {
        console.log("🚀 ~ fetchData ~ error:", error);
        // toastErrorRaw(error);
      }
    };

    onMounted(async () => {
      await fetchData();
    });

    return {
      chartOptions,
      chartData,
      series,
      arraySum,
      store,
    };
  },
});
