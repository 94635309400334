import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import useNotify from "./notify";
import { PaginatedParams } from "@/assets/ts/_utils/models/Api";
import useEmitter from "./emitter";
import { AppEvent } from "@/assets/ts/_utils/enums/custom-events.enum";
import useMedia from "./useMedia";
import { cleanData } from "../helpers/kyc";

const useBGCs = () => {
  const loading = ref<boolean>(false);
  const { toastErrorRaw, toastSuccess } = useNotify();
  const { uploadFile, dataURLtoFile } = useMedia();
  const emitter = useEmitter();

  const fetchBGCForms = async (params: PaginatedParams = {}) => {
    const { query, ...otherParams } = params || {};
    try {
      loading.value = true;
      const resp = await ApiService.query(`connectedForms${query || ""}`, {
        params: otherParams,
        hideLoader: true,
      });
      return resp.data.results;
    } catch (err: any) {
      toastErrorRaw(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchApplicantBGC = async (userId: string) => {
    try {
      loading.value = true;
      const resp = await ApiService.getWithoutSlug(
        `background-checks/${userId}/`
      );
      return resp.data;
    } catch (err: any) {
      toastErrorRaw(err);
    } finally {
      loading.value = false;
    }
  };

  const updateStep = async (bgcId: number, step: number) => {
    try {
      const resp = await ApiService.put(`connectedFormsCurrentStep/${bgcId}/`, {
        step,
        connected_form: bgcId,
      } as any);
      return resp.data;
    } catch (err: any) {
      toastErrorRaw(err);
    }
  };

  const createStep = async (bgcId: number, step: number) => {
    try {
      const resp = await ApiService.post(`connectedFormsCurrentStep/`, {
        step,
        connected_form: bgcId,
      } as any);
      return resp.data;
    } catch (err: any) {
      toastErrorRaw(err);
    }
  };

  const updateBGC = async (bgcId: number, payload: any) => {
    try {
      const resp = await ApiService.patch(`connectedForms/${bgcId}/`, payload);
      return resp.data;
    } catch (err: any) {
      toastErrorRaw(err);
    }
  };

  const fetchCriminalHistory = async (userId: number) => {
    try {
      const resp = await ApiService.query(`criminal-history-list/`, {
        params: { applicant: userId },
      });

      return resp.data?.results || [];
    } catch (err: any) {
      toastErrorRaw(err);
    }
  };

  const fetchEducationHistory = async (userId: number) => {
    try {
      const resp = await ApiService.query(`education-histories/`, {
        params: { applicant: userId },
      });

      return resp.data?.results || [];
    } catch (err: any) {
      toastErrorRaw(err);
    }
  };

  const fetchEmploymentHistory = async (userId: number) => {
    try {
      const resp = await ApiService.query(`employment-histories/`, {
        params: { applicant: userId },
      });

      return resp.data?.results || [];
    } catch (err: any) {
      toastErrorRaw(err);
    }
  };

  const fetchAddressHistory = async (userId: number) => {
    try {
      const resp = await ApiService.query(`addresses/`, {
        params: { applicant: userId },
      });

      return resp.data?.results || [];
    } catch (err: any) {
      toastErrorRaw(err);
    }
  };

  // const updateKYC = async (bgc: any, values: any) => {
  //   try {
  //     const kycId = bgc.kyc.id;
  //     const payload = "kyc" in values ? values.kyc : values;
  //     const resp = await ApiService.patch(`kyc/${kycId}/`, payload);
  //     if (resp?.data) {
  //       setTimeout(
  //         () => emitter.emit(AppEvent.BGC_KYC_UPDATED, resp.data),
  //         300
  //       );
  //     }

  //     return resp.data;
  //   } catch (err: any) {
  //     toastErrorRaw(err);
  //   }
  // };

  // const updateKYCMedia = async (
  //   bgc: any,
  //   values: any,
  //   updatedKYCDocs?: string[]
  // ) => {
  //   try {
  //     const payload = "kyc" in values ? values.kyc : values;
  //     const promises: any[] = [];

  //     const { official_doc, official_doc_back } = payload;
  //     if (official_doc?.src && updatedKYCDocs?.includes("front")) {
  //       const frontFilename = `${
  //         official_doc.alt_text
  //       }_${new Date().getTime()}`;
  //       promises.push(
  //         uploadFile({
  //           file: dataURLtoFile(official_doc.src, frontFilename),
  //           file_name: frontFilename,
  //           description: official_doc.media_description,
  //           alt: official_doc.alt_text,
  //         })
  //       );
  //     }

  //     if (official_doc_back?.src && updatedKYCDocs?.includes("back")) {
  //       const backFilename = `${
  //         official_doc_back.alt_text
  //       }_${new Date().getTime()}`;

  //       promises.push(
  //         uploadFile({
  //           file: dataURLtoFile(official_doc_back.src, backFilename),
  //           file_name: backFilename,
  //           description: official_doc_back.media_description,
  //           alt: official_doc_back.alt_text,
  //         })
  //       );
  //     }

  //     if (promises.length > 0) {
  //       const [uploadedFront, uploadedBack] = await Promise.all(promises);
  //       const resp = await ApiService.patch(`kyc/update-media/${bgc.kyc.id}/`, {
  //         ...(uploadedFront
  //           ? {
  //               official_doc_file: uploadedFront.id,
  //             }
  //           : {}),
  //         ...(uploadedBack
  //           ? {
  //               official_doc_back_file: uploadedBack.id,
  //             }
  //           : {}),
  //       } as any);

  //       if (resp?.data) {
  //         setTimeout(() => emitter.emit(AppEvent.BGC_KYC_UPDATED, resp), 300);
  //       }

  //       return resp?.data;
  //     } else {
  //       return true;
  //     }
  //   } catch (err: any) {
  //     toastErrorRaw(err);
  //   }
  // };

  const cleanKYCFormData = (kyc) => {
    kyc = kyc || {};
    return cleanData(kyc);
  };

  // use when no form data is required to be updated from step
  const proxyUpdateBGC = () => true;

  // todo: make dynamic based on if admin logged in or not
  const stepUpdateFunctions = {
    3: proxyUpdateBGC,
    4: proxyUpdateBGC,
    5: proxyUpdateBGC,
    // 6: updateKYC,
    // 7: updateKYCMedia,
  };

  const generateDocName = (docType, username) => {
    return `${username}_${docType}_${new Date().getTime()}`;
  };

  return {
    loading,
    fetchBGCForms,
    fetchApplicantBGC,
    updateStep,
    updateBGC,
    stepUpdateFunctions,
    fetchCriminalHistory,
    fetchEducationHistory,
    fetchEmploymentHistory,
    fetchAddressHistory,
    proxyUpdateBGC,
    // updateKYC,
    cleanKYCFormData,
    // updateKYCMedia,
    createStep,
    generateDocName,
  };
};

export default useBGCs;
