
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import {
  AMLVerificationStatus,
  IDVerificationStatus,
} from "@/assets/ts/_utils/enums/id-verification-status.enum";
import { cloneDeep, debounce } from "lodash";
import useNotify from "@/core/composables/notify";
import {
  AMLVerification,
  IDVerification,
} from "@/assets/ts/_utils/models/IDVerification";
import { IDType } from "@/assets/ts/_utils/enums/id-verification-type.enum";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import AMLVerificationModal from "@/components/modals/AMLVerificationModal.vue";

export default defineComponent({
  name: "AMLVerificationHistory",
  components: {
    Datatable,
    AMLVerificationModal,
  },
  setup() {
    const selectedRow = ref<AMLVerification | null>(null);
    let countries = ref<any>([]);

    const route = useRoute();
    const { toastError, toastSuccess, toastErrorRaw } = useNotify();
    const store = useStore();

    const isAdmin = computed(() => {
      return store.getters.currentUser?.user_type === 2;
    });

    const tableHeader = ref([
      {
        name: "First Name",
        key: "first_name",
        sortable: true,
      },
      {
        name: "Last Name",
        key: "last_name",
        sortable: true,
      },
      {
        name: "Country",
        key: "country",
      },
      {
        name: "Status",
        key: "status_display",
      },
      {
        name: "Actions",
        key: "edit",
      },
    ]);

    const tableData = ref<Array<IDVerification>>([]);
    const tableKey = ref(1);

    const getStatusEnum = () => {
      const { progressStatus } = route.params || {};
      if (!progressStatus) return;

      let status;
      switch (progressStatus) {
        case "pending":
          status = AMLVerificationStatus.PENDING;
          break;
        case "valid":
          status = AMLVerificationStatus.NOT_FOUND;
          break;
        case "invalid":
          status = AMLVerificationStatus.FOUND;
          break;

        default:
          break;
      }
      return status;
    };

    const fetchData = async (search?: string) => {
      try {
        tableData.value = [];

        const params: any = { status: getStatusEnum() };
        if (search) {
          params.search = search;
        }

        const resp = await ApiService.query("aml-verifications/", {
          params,
          hideLoader: !!search,
        });
        tableData.value = resp.data.results;
        setCurrentPageBreadcrumbs("AML Verifications", [
          "Submitted Verifications",
        ]);
      } catch (error: any) {
        toastErrorRaw(error);
      }
    };

    onMounted(async () => {
      await fetchData();
      await store.dispatch(Actions.GET_COUNTRIES);
      countries.value = store.getters.getCountries;
    });

    watch(
      () => route.params,
      (curVal, oldVal) => {
        if (curVal != oldVal && store.getters.currentUser?.id) {
          fetchData();
        }
      }
    );

    const search = ref<string>("");
    const searchItems = debounce(() => {
      fetchData(search.value);
    }, 1000);

    const getBadgeClass = (verificationStatus) => {
      let badge = "";
      switch (verificationStatus) {
        case IDVerificationStatus.PENDING:
          badge = "badge-light";
          break;
        case AMLVerificationStatus.FOUND:
          badge = "badge-danger";
          break;
        case AMLVerificationStatus.NOT_FOUND:
          badge = "badge-success";
          break;

        default:
          break;
      }

      return badge;
    };

    const viewVerification = (verification) => {
      selectedRow.value = verification;
    };

    const handleVerificationUpdated = (verification) => {
      selectedRow.value = null;
      const newTableData = cloneDeep(tableData.value);
      const idx = newTableData.findIndex((d) => d.id === verification.id);
      if (idx >= 0) {
        newTableData[idx] = {
          ...verification,
          uploaded_id: newTableData[idx].uploaded_id,
        };
        tableData.value = newTableData;
        toastSuccess({ text: "ID Verification has been updted successfully" });
        tableKey.value = new Date().getTime();
      }
    };

    return {
      tableData,
      tableHeader,
      search,
      searchItems,
      getBadgeClass,
      viewVerification,
      selectedRow,
      handleVerificationUpdated,
      tableKey,
      isAdmin,
      IDType,
      countries,
    };
  },
});
