
import { defineComponent, computed, onMounted, watch } from "vue";
import {
  IDType,
  cardOptions,
} from "@/assets/ts/_utils/enums/id-verification-type.enum";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ref } from "vue";

export default defineComponent({
  name: "step-0",
  emits: ["selectCountry", "selectCardType"],

  setup(props, { emit }) {
    const store = useStore();
    let countries = ref<any>([]);
    let country = ref<any>(null);

    const searchCountries = (queryString, cb) => {
      let resp = countries.value;
      if (queryString) {
        resp = countries.value.filter((c) =>
          c.name.toLowerCase().includes(queryString.toLowerCase())
        );
      }

      cb(resp.map((r) => ({ value: r.name, ...r })));
    };

    watch(
      () => country.value,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          emit("selectCountry", curVal);
        }
      }
    );

    onMounted(async () => {
      await store.dispatch(Actions.GET_COUNTRIES);
      countries.value = store.getters.getCountries;
    });

    return {
      IDType,
      country,
      countries,
      cardOptions,
      searchCountries,
    };
  },
});
