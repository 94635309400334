
import { defineComponent, onMounted } from "vue";
import KTModalCard from "@/components/client/DashboardCard.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getIllustrationsPath } from "@/core/helpers/assets";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "client-dashboard",
  components: {
    KTModalCard,
  },
  setup() {
    const router = useRouter();

    onMounted(() => {
      setCurrentPageBreadcrumbs("SwiftIdentity", ["Dashboard"]);
    });

    const handleKycSubmit = () => {
      window.open(process.env.VUE_APP_KYC_URL, "_blank");
    };

    return {
      getIllustrationsPath,
      handleKycSubmit,
    };
  },
});
