import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";

const useGetPlans = (params = {}) => {
  const data = ref<Plan[]>([]);
  const loading = ref<boolean>(false);
  const error = ref<string>("");

  const load = (params) => {
    loading.value = true;
    ApiService.query("plans/", { params })
      .then((resp) => {
        data.value = resp.data?.results || [];
      })
      .catch(({ response }) => {
        error.value = response;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  load(params);

  return { data, loading, error, load };
};

export default useGetPlans;
