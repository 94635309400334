
import { PropType, defineComponent, ref } from "vue";
import WebCamSelfie from "@/components/client/KYC/camera_selfie.vue";
import WebCamDocument from "@/components/client/KYC/camera_document.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import useMedia from "@/core/composables/useMedia";
import useBGCs from "@/core/composables/useBGCs";
import { useStore } from "vuex";
import { watch } from "vue";

export default defineComponent({
  name: "UploadInput",
  components: {
    WebCamSelfie,
    WebCamDocument,
  },
  props: {
    title: { type: String, default: "Upload front document" },
    subtitle: { type: String, default: "Upload the front of your document" },
    list: { type: Array, default: () => ["Ensure you are in a well-lit area"] },
    name: { type: String, default: "upload" },
    mode: {
      type: String,
      default: "upload",
      validator(value: string) {
        // The value must match one of these strings
        return ["upload", "selfie"].includes(value);
      },
    },
    shouldUpload: { type: Boolean, default: false },
    fileType: {
      type: String as PropType<"official_doc_file" | "official_doc_back_file">,
      default: "official_doc_file",
    },
    capturedDoc: { type: String },
  },
  setup(props: any, { emit }) {
    const { uploading, uploadFile, dataURLtoFile, fetchFiles } = useMedia();
    const { generateDocName } = useBGCs();
    const store = useStore();

    let official_doc = ref("");
    let live_photo = ref("");
    let live_photo_url = ref("");
    let hideCamera = ref(false);
    let isCameraOpen = ref(false);
    let webCam = ref<any>(null);

    const uploadImage = async (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (!input.files?.length) {
        return;
      }
      const file = input.files[0];
      if (file && validateImage(input)) {
        official_doc.value = (await toBase64(file)) as string;
        await uploadAndEmitFile(official_doc.value);
      }
    };

    const uploadAndEmitFile = async (value, scan?: boolean) => {
      // if (props.shouldUpload) {
      const officialFileName = generateDocName(
        props.title,
        store.getters.currentUser.username
      );
      const doc: any = {
        file: dataURLtoFile(value, officialFileName),
        file_name: officialFileName,
        description: `${props.title} for ${store.getters.currentUser.username}`,
        alt: `${props.title}`,
        ...(scan ? { fileProps: { scan: true } } : null),
      };
      try {
        const uploadResp = await uploadFile(doc);
        // console.log("🚀 ~ uploadAndEmitFile ~ uploadResp:", uploadResp);
        emit("update:file", uploadResp.id);
        emit("update:fileObject", uploadResp.url);
      } catch (error) {
        removeFile();
      }
      // } else {
      //   emit("update:file", uploadResp.id);
      // }
    };

    const toBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    const validateImage = (input: HTMLInputElement) => {
      if (!input.files?.length) {
        return;
      }

      const file = input.files[0];
      if (file && file.size / (1024 * 1024) > 2) {
        Swal.fire({
          text: "File cannot be larger than 2 MB",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        return false;
      }
      if (file.type === "image/webp") {
        Swal.fire({
          text: "File type not supported",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        return false;
      }
      return true;
    };

    const livephoto = async (data: { url: string; file: any }) => {
      // live_photo_url.value = data.url;
      official_doc.value = data.file;
      hideCamera.value = true;
      //this.disableCamera = true;
      await uploadAndEmitFile(official_doc.value, true);
    };

    const livephotoSelfie = async (data: { url: string; file: any }) => {
      // live_photo_url.value = data.url;
      official_doc.value = data.file;
      hideCamera.value = true;
      //this.disableCamera = true;
      await uploadAndEmitFile(official_doc.value);
    };

    const openCamera = () => {
      isCameraOpen.value = true;
      webCam.value.toggleCamera();
    };
    const closeCamera = () => {
      isCameraOpen.value = false;
    };

    // onMounted(() => {
    //   console.log("🚀 ~ onMounted ~ webCam:", webCam.value);
    // });

    const removeFile = () => {
      official_doc.value = "";
      emit("update:file", "");
    };

    watch(
      () => props.capturedDoc,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          official_doc.value = curVal;
        }
      }
    );

    return {
      isCameraOpen,
      closeCamera,
      uploadImage,
      official_doc,
      removeFile,
      livephoto,
      livephotoSelfie,
      live_photo_url,
      live_photo,
      hideCamera,
      openCamera,
      webCam,
      uploading,
    };
  },
});
