export enum IDVerificationType {
  SINGULAR = "Singular",
  BULK = "Bulk",
}

export enum IDType {
  GHANA_CARD = 1,
  OTHER = 2,
}

export enum CardType {
  RESIDENCE_CARD = 1,
  PASSPORT = 2,
  VOTERS_CARD = 3,
  OTHER_CARD = 4,
  SSNIT = 5,
  DRIVERS_LICENSE = 6,
  GHANA_CARD = 7,
}

export const cardOptions = Object.freeze({
  [CardType.RESIDENCE_CARD]: "Residence Card",
  [CardType.PASSPORT]: "Passport",
  [CardType.VOTERS_CARD]: "Voters Card",
  [CardType.DRIVERS_LICENSE]: "Driver's License",
  [CardType.OTHER_CARD]: "Other",
});

export const ghanaCardOptions = Object.freeze({
  ...cardOptions,
  [CardType.SSNIT]: "SSNIT Card",
  [CardType.GHANA_CARD]: "Ghana Card",
});
