
import { defineComponent, onMounted } from "vue";
import StatisticsWidget5 from "@/components/widgets/statsistics/Widget5.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { arraySum } from "@/filters";
import { useStore } from "vuex";

export default defineComponent({
  name: "widgets-statistics",
  components: {
    StatisticsWidget5,
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      setCurrentPageBreadcrumbs("Statistics", ["Widgets"]);
    });

    return {
      store,
      arraySum,
    }
  },
});
