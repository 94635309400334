import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "me-3" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "text-primary fw-bold" }
const _hoisted_4 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_5 = {
  key: 0,
  class: "text-gray-800 ms-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("b", null, _toDisplayString(_ctx.title), 1)
        ])
      ]),
      (_ctx.date)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Last updated " + _toDisplayString(_ctx.$filters.formatDate(_ctx.date)), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.status)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.status) + " ", 1),
          _createElementVNode("span", {
            class: _normalizeClass(["svg-icon svg-icon-1 ms-2", _ctx.passed ? 'svg-icon-success' : 'svg-icon-danger'])
          }, [
            _createVNode(_component_inline_svg, {
              src: 
          _ctx.passed
            ? 'media/icons/duotune/general/gen043.svg'
            : 'media/icons/duotune/general/gen040.svg'
        
            }, null, 8, ["src"])
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}