import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "capture-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CaptureQR = _resolveComponent("CaptureQR")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_UploadInput = _resolveComponent("UploadInput")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: "text-center mb-8 cursor-pointer d-none d-md-block text-primary",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showOrHideQR && _ctx.showOrHideQR(...args)))
      }, [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("b", null, _toDisplayString(_ctx.showQR ? "Capture with desktop" : "Capture with phone"), 1)
        ])
      ])
    ]),
    (_ctx.showQR)
      ? (_openBlock(), _createBlock(_component_CaptureQR, {
          key: 0,
          loading: _ctx.fetchingQR,
          fetchingCaptures: _ctx.fetchingCapture,
          token: _ctx.qRToken,
          "show-back": _ctx.showBack,
          onOnComplete: _ctx.checkCapturedFiles,
          onEmailSent: _cache[1] || (_cache[1] = ($event) => (_ctx.qRToken = $event))
        }, null, 8, ["loading", "fetchingCaptures", "token", "show-back", "onOnComplete"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_ErrorMessage, {
            name: "official_doc",
            class: "fv-plugins-message-container invalid-feedback mb-4"
          }),
          _createVNode(_component_UploadInput, {
            title: "FRONT DOCUMENT Upload",
            subtitle: "Upload the front of your document",
            list: [],
            "onUpdate:file": _ctx.updateFrontImage,
            "onUpdate:fileObject": _cache[2] || (_cache[2] = ($event) => _ctx.updateImageObject($event, 'front')),
            name: "official_doc",
            "should-upload": "",
            "file-type": "official_doc_file",
            capturedDoc: _ctx.official_doc
          }, null, 8, ["onUpdate:file", "capturedDoc"]),
          _createVNode(_component_Field, {
            style: {"display":"none"},
            name: "official_doc",
            class: "form-control form-control-lg form-control-solid"
          }),
          (_ctx.showBack)
            ? (_openBlock(), _createBlock(_component_UploadInput, {
                key: 0,
                class: "my-10",
                title: "Back DOCUMENT Upload",
                subtitle: "Upload the back of your document",
                list: [],
                name: "official_doc_back",
                "onUpdate:file": _ctx.updateBackImage,
                "onUpdate:fileObject": _cache[3] || (_cache[3] = ($event) => _ctx.updateImageObject($event, 'back')),
                "should-upload": "",
                "file-type": "official_doc_back_file",
                capturedDoc: _ctx.official_doc_back
              }, null, 8, ["onUpdate:file", "capturedDoc"]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}