
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { formatDate } from "@/filters";
import { debounce } from "lodash";
import { CUSTOMER, ADMIN, REVIEWER, CLIENT } from "@/constants";

interface IClient {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  last_modified: string;
}

export default defineComponent({
  name: "admin-companies",
  components: {
    Datatable,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const checkedCustomers = ref([]);
    const formStatuses = ref([
      "In Progress",
      "Approved",
      "Declined",
      "Reviewing",
      "Submitted",
    ]);
    const loading = ref(false);
    const tableHeader = ref([
      {
        name: "Company Name",
        key: "company_name",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "First Name",
        key: "first_name",
        sortable: true,
      },
      {
        name: "Last Name",
        key: "last_name",
        sortable: true,
      },
      {
        name: "Date Joined",
        key: "date_joined",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<IClient>>([]);
    const initCustomers = ref<Array<IClient>>([]);
    const tableKey = ref(1);

    const loadData = async (searchVal?: string) => {
      loading.value = true;
      await store.dispatch(Actions.GET_COMPANIES, {
        user_type: CUSTOMER,
        search: searchVal,
      });
      const [errorName] = Object.keys(store.getters.getErrors);
      const error = store.getters.getErrors[errorName];
      if (error) {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        tableData.value = store.getters.getCompanies;
        tableKey.value = new Date().getTime();
      }
      loading.value = false;
    };

    onMounted(async () => {
      loadData();
    });

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const reviewForm = (id, event) => {
      event.preventDefault();
      router.push({ name: "admin-company-details", params: { companyId: id } });
    };

    const search = ref<string>("");
    const searchItems = debounce(() => {
      loadData(search.value);
    }, 1000);

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const dummy = (event) => {
      event.preventDefault();
      console.log("test");
    };

    return {
      tableData,
      tableHeader,
      deleteCustomer,
      search,
      searchItems,
      checkedCustomers,
      deleteFewCustomers,
      reviewForm,
      formStatuses,
      formatDate,
      tableKey,
      loading,
    };
  },
});
