
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "upload-applicants-modal",
  props: {
    helpText: { type: String },
    criticalText: { type: String },
    exampleSheetUrl: { type: String },
  },
  setup() {
    const uploadApplicantModalRef = ref(null);
    return { uploadApplicantModalRef };
  },
});
