
import { defineComponent, ref, onMounted, watch } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";
import { formatDate, getBadgeClass, getStatusInt } from "@/filters";
import KTLoader from "@/components/CircleLoader.vue";
import useKYCs from "@/core/composables/useKYCs";
import { debounce } from "lodash";

interface IClient {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  last_modified: string;
}

export default defineComponent({
  name: "customer-kyc-progress",
  components: {
    Datatable,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const checkedCustomers = ref([]);
    const loading = ref(false);
    const formStatuses = ref([
      "In Progress",
      "Approved",
      "Declined",
      "Reviewing",
      "Submitted",
    ]);
    const tableKey = ref(1);

    const { fetchKYCs, loading: loadingKYCs } = useKYCs();

    const tableHeaderWithoutActions = ref([
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "First Name",
        key: "first_name",
        sortable: true,
      },
      {
        name: "Last Name",
        key: "last_name",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "created",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
      },
    ]);

    const tableHeaderWithActions = ref([
      ...tableHeaderWithoutActions.value,
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<IClient>>([]);
    const initCustomers = ref<Array<IClient>>([]);
    const allowedStatusesToReview: number[] = [2, 3, 4];

    const fetchData = async (searchVal?: string) => {
      let apiConfig: any = {};
      const { progressStatus } = route.params || {};
      if (searchVal) {
        apiConfig.search = searchVal;
      }

      if (progressStatus) {
        if (progressStatus === "inProgress") {
          apiConfig.query = `?status_in=5&status_in=4`;
        } else {
          const status = getStatusInt(progressStatus);
          if (status) {
            apiConfig.status = status;
          }
        }
      }
      const resp = await fetchKYCs(apiConfig);
      tableData.value = resp;
      tableKey.value = new Date().getTime();
    };

    onMounted(async () => {
      fetchData();
    });

    watch(
      () => route.params,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          resetTable();
          fetchData();
        }
      }
    );

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const reviewForm = (id, status, event) => {
      event.preventDefault();
      if (status == 1) {
        return;
      }
      router.push({ name: "customer-kyc-review", params: { kycId: id } });
    };

    const resetTable = () => {
      tableData.value = [];
      tableKey.value = new Date().getTime();
    };

    const search = ref<string>("");

    const searchItems = debounce(() => {
      fetchData(search.value);
    }, 1000);

    return {
      tableData,
      tableHeaderWithActions,
      tableHeaderWithoutActions,
      deleteCustomer,
      search,
      searchItems,
      checkedCustomers,
      deleteFewCustomers,
      reviewForm,
      formStatuses,
      formatDate,
      getBadgeClass,
      loading,
      tableKey,
      loadingKYCs,
      allowedStatusesToReview,
    };
  },
});
