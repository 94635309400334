
import {
  defineComponent,
  onMounted,
  ref,
  nextTick,
  computed,
  PropType,
  watch,
} from "vue";
import { useStore } from "vuex";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import { useRoute } from "vue-router";
import PlanCard from "@/components/PlanCard.vue";
import useCurrencies from "@/core/composables/useCurrencies";

export default defineComponent({
  name: "aml-step-2",
  components: {
    PlanCard,
  },
  props: {
    plan: {
      type: Number,
    },
    plans: {
      type: Array as PropType<Plan[]>,
    },
  },
  emits: ["plan-selected"],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const { getPlanPriceByCurrency } = useCurrencies();

    const selectedPlan = ref<Plan | null>(null);

    watch(
      () => selectedPlan.value,
      (curVal, oldVal) => {
        if (curVal != oldVal && curVal) {
          emit("plan-selected", curVal);
        }
      }
    );

    return {
      selectedPlan,
      getPlanPriceByCurrency,
    };
  },
});
