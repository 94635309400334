
import { defineComponent, ref, onMounted } from "vue";
import Statistics from "@/components/customer/Dashboard/Statistics.vue";
import Trends from "@/components/customer/Dashboard/Trends.vue";
import BarChart from "@/components/customer/Dashboard/BarChart.vue";
import ReportChart from "@/components/customer/Dashboard/ReportChart.vue";
import Progress from "@/components/customer/Progress.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import CustomerVerifications from "@/components/customer/CustomerVerifications.vue";

export default defineComponent({
  name: "dashboard",
  components: {
    Statistics,
    Trends,
    BarChart,
    ReportChart,
    CustomerVerifications,
  },
  setup() {
    const store = useStore();
    onMounted(async () => {
      await store.dispatch(
        Actions.GET_CUSTOMER_DASHBOARD2,
        store.getters.currentUser.id
      );
    });
  },
});
