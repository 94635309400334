
import { defineComponent, onMounted, PropType, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { IEducation } from "@/store/models/form";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { extractElementFormError } from "@/core/helpers/errors";

export default defineComponent({
  name: "add-customer-modal",
  components: {},
  props: {
    initialData: { type: Object as PropType<IEducation> },
    isReview: { type: Boolean, default: false },
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const addCustomerModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref<IEducation | undefined>(props.initialData);
    const store = useStore();

    onMounted(() => {
      console.log(props.initialData);
    });

    watch(
      () => props.initialData,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          formData.value = props.initialData;
        }
      }
    );

    const rules = ref({
      institute_name: [
        {
          required: true,
          message: "Institute name is required",
          trigger: "change",
        },
      ],
      degree_name: [
        {
          required: true,
          message: "Degree is required",
          trigger: "change",
        },
      ],
      "address.street_address": [
        {
          required: true,
          message: "Street Address is required",
          trigger: "change",
        },
      ],
      "address.city": [
        {
          required: true,
          message: "City is required",
          trigger: "change",
        },
      ],
      "address.state": [
        {
          required: true,
          message: "State is required",
          trigger: "change",
        },
      ],
      "address.zip_code": [
        {
          required: true,
          message: "Zip code is required",
          trigger: "change",
        },
      ],
      start_date: [
        {
          required: true,
          message: "Start Date is required",
          trigger: "change",
        },
      ],
      end_date: [
        {
          required: true,
          message: "End Date is required",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid, fields: any) => {
        if (valid) {
          loading.value = true;

          //reset error
          store.commit(Mutations.SET_ERROR, {});

          formData.value?.id
            ? await store.dispatch(Actions.UPDATE_EDUCATION_HISTORY, {
                id: formData.value.id,
                data: formData.value,
              })
            : await store.dispatch(
                Actions.SAVE_EDUCATION_HISTORY,
                formData.value
              );
          loading.value = false;

          const [errorName] = Object.keys(store.getters.getFormErrors);
          const error = store.getters.getFormErrors[errorName];

          if (error) {
            Swal.fire({
              text: error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            return;
          }

          Swal.fire({
            text: "Education History has been successfully added/updated!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(async () => {
            await store.dispatch(Actions.GET_EDUCATION_HISTORY);
            hideModal(addCustomerModalRef.value);
          });
        } else {
          Swal.fire({
            text: extractElementFormError(fields),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addCustomerModalRef,
    };
  },
});
