
import { defineComponent, ref } from "vue";
import WebCam from "@/components/client/KYC/camera.vue";
import { Field, ErrorMessage } from "vee-validate";
import Button from "@/components/buttons/Button.vue";
import UploadInput from "@/components/client/KYC/uploadInput.vue";

export default defineComponent({
  name: "step-3",
  components: {
    // WebCam,
    UploadInput,
    // Field,
    // ErrorMessage,
    // Button,
  },
  setup(props, { emit }) {
    // let live_photo = ref("");
    // let live_photo_url = ref("");
    // let hideCamera = ref(false);

    // const livephoto = (data: { url: string; file: any }) => {
    //   live_photo_url.value = data.url;
    //   live_photo.value = data.file;
    //   hideCamera.value = true;
    //   //this.disableCamera = true;
    //   emit("updateLiveField", live_photo.value);
    // };

    const handleSelfieUpload = (value: any) => {
      emit("updateLiveField", value);
    };

    const updateImageObject = async (
      value: string,
      type: "front" | "back" | "selfie"
    ) => {
      if (value) {
        emit("updateFileObject", { value, type });
      } else {
        return;
      }
    };

    return {
      // live_photo_url,
      // live_photo,
      // hideCamera,
      // livephoto,
      handleSelfieUpload,
      updateImageObject,
    };
  },
});
