import { IFile, PreMedia } from "@/assets/ts/_utils/models/MediaModel";
import { ref } from "vue";
import ApiService from "../services/ApiService";
import useNotify from "./notify";
import { PaginatedParams } from "@/assets/ts/_utils/models/Api";

const useMedia = () => {
  const { toastError, toastErrorRaw } = useNotify();

  const uploading = ref(false);

  const validateFile = (file) => {
    if (!file) {
      return false;
    }

    if (file && file.size / (1024 * 1024) > 2) {
      toastError({ text: "File cannot be larger than 2 MB" });
      return false;
    }

    return true;
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadMedia = async (payload: PreMedia) => {
    try {
      uploading.value = true;
      let resp;
      if (payload.id) {
        resp = ApiService.updateMedia(
          payload.id,
          payload.fileBlob,
          payload.description || "",
          payload.alt || ""
        );
      } else {
        resp = await ApiService.createMedia(
          payload.fileBlob,
          payload.description || "",
          payload.alt || "",
          true
        );
      }

      return resp.data;
    } catch (error: any) {
      toastErrorRaw(error);
    } finally {
      uploading.value = false;
    }
  };

  const uploadFile = async (
    payload: Omit<PreMedia, "fileBlob"> & {
      fileProps?: IFile;
      token?: string;
      meta?: string;
    }
  ) => {
    try {
      uploading.value = true;
      const resp = await ApiService.createFile(
        payload.file as File,
        payload.description || "",
        payload.alt || "",
        payload.file_name || "",
        payload.fileProps || {},
        payload.token || "",
        payload.meta || ""
      );

      return resp.data;
    } catch (error: any) {
      toastErrorRaw(error);
      return Promise.reject(error);
    } finally {
      uploading.value = false;
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const formatObjectForFileApi = (obj, fileName) => {
    return {
      ...obj,
      src: dataURLtoFile(obj.src, fileName),
      file_name: fileName,
      source: "SWIFT",
    };
  };

  const fetchFiles = async (params: PaginatedParams = {}) => {
    const { query, ...otherParams } = params || {};

    try {
      const resp = await ApiService.query(`files${query || ""}`, {
        hideLoader: true,
        params: otherParams,
      });
      return resp.data.results;
    } catch (err: any) {
      toastErrorRaw(err);
    }
  };

  return {
    validateFile,
    toBase64,
    uploading,
    uploadMedia,
    uploadFile,
    dataURLtoFile,
    formatObjectForFileApi,
    fetchFiles,
  };
};

export default useMedia;
