
import { defineComponent } from "vue";
import SummaryCard from "@/components/customer/SummaryCard.vue";
import { computed } from "vue";
import { PropType } from "vue";
import { separateWords } from "@/core/helpers/string";

export default defineComponent({
  name: "ocr-modal",
  props: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta: { type: Object as PropType<any> },
    isAdmin: { type: Boolean },
  },
  components: { SummaryCard },

  setup(props) {
    const getRandomIcon = () => {
      const icons = [
        "/media/icons/duotune/art/art006.svg",
        "/media/icons/duotune/art/art005.svg",
        "/media/icons/duotune/art/art004.svg",
        "/media/icons/duotune/art/art003.svg",
        "/media/icons/duotune/art/art002.svg",
        "/media/icons/duotune/art/art001.svg",
      ];
      const randomIndex = Math.floor(Math.random() * icons.length);
      return icons[randomIndex];
    };
    const getRandomColor = () => {
      const colors = ["primary", "warning", "info", "danger", "success"];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    };

    const displayedMeta = computed(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { front, back } = props.meta || {};
      const { MachineReadableZone: mrzFront, ...restOfFront } = front || {};
      const { MachineReadableZone: mrzBack, ...restOfBack } = back || {};

      return { front: restOfFront, back: restOfBack };
    });

    return { displayedMeta, separateWords, getRandomIcon, getRandomColor };
  },
});
