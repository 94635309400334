import { onMounted, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

const useCountries = () => {
  const loading = ref<boolean>(false);
  const countries = ref<any>([]);
  const store = useStore();

  const searchCountries = (queryString, cb) => {
    let resp = countries.value;
    if (queryString) {
      resp = countries.value.filter((c) =>
        c.name.toLowerCase().includes(queryString.toLowerCase())
      );
    }

    cb(resp.map((r) => ({ value: r.name, ...r })));
  };

  onMounted(async () => {
    await store.dispatch(Actions.GET_COUNTRIES);
    countries.value = store.getters.getCountries;
  });

  return { loading, countries, searchCountries };
};

export default useCountries;
