import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
            class: "form-control form-control-solid w-250px ps-15",
            placeholder: "Search Companies"
          }, null, 544), [
            [_vModelText, _ctx.search]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["card-body pt-0", { 'opacity-50': _ctx.loading }])
    }, [
      (_openBlock(), _createBlock(_component_Datatable, {
        "table-data": _ctx.tableData,
        "table-header": _ctx.tableHeader,
        "enable-items-per-page-dropdown": true,
        loading: _ctx.loading,
        key: _ctx.tableKey
      }, {
        "cell-email": _withCtx(({ row: customer }) => [
          _createTextVNode(_toDisplayString(customer.email), 1)
        ]),
        "cell-first_name": _withCtx(({ row: customer }) => [
          _createTextVNode(_toDisplayString(customer.first_name), 1)
        ]),
        "cell-last_name": _withCtx(({ row: customer }) => [
          _createTextVNode(_toDisplayString(customer.last_name), 1)
        ]),
        "cell-date_joined": _withCtx(({ row: customer }) => [
          _createTextVNode(_toDisplayString(_ctx.formatDate(customer.date_joined)), 1)
        ]),
        "cell-company_name": _withCtx(({ row: customer }) => [
          _createTextVNode(_toDisplayString(customer.company_name), 1)
        ]),
        "cell-actions": _withCtx(({ row: customer }) => [
          _createElementVNode("a", {
            href: "#",
            onClick: _withModifiers(($event: any) => (_ctx.reviewForm(customer.id, $event)), ["stop"]),
            class: "badge badge-light-primary"
          }, " View Details ", 8, _hoisted_6)
        ]),
        _: 1
      }, 8, ["table-data", "table-header", "loading"]))
    ], 2)
  ]))
}