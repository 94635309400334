import { Modal } from "bootstrap";

const hideModal = (modalEl: HTMLElement | null | string): void => {
  if (!modalEl) {
    return;
  }

  if (typeof modalEl === "string") {
    modalEl = document.getElementById(modalEl);
  }

  const myModal = Modal.getInstance(modalEl);
  myModal.hide();
};

const showModal = (id: string): void => {
  const myModal = new Modal(document.getElementById(id), {
    keyboard: false,
  });

  myModal.show();
};

const removeModalBackdrop = (): void => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

export { removeModalBackdrop, hideModal, showModal };
