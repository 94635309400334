
import { PropType, defineComponent, ref, watch } from "vue";
import ApplicantModal from "@/components/customer/NewApplicant/modals/ApplicantModal.vue";
import { Field, ErrorMessage } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ExcelParser from "@/components/buttons/ExcelParser/ExcelParser.vue";
import { Subscription } from "@/assets/ts/_utils/models/SubscriptionModel";
import useSubscriptions from "@/core/composables/useSubscriptions";
import useNotify from "@/core/composables/notify";
import ActiveSubscriptionCard from "@/components/ActiveSubscriptionCard.vue";
import { computed } from "vue";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";

export default defineComponent({
  name: "step-1",
  components: {
    ApplicantModal,
    Datatable,
    ErrorMessage,
    Field,
    ExcelParser,
    ActiveSubscriptionCard,
  },
  props: {
    isReview: { type: Boolean, default: false },
    initialTableData: { type: Object as PropType<any> },
    activeSubscription: {
      type: Object as PropType<Subscription | null>,
      required: false,
    },
    isSubscriptionSelected: { type: Boolean, default: false },
    amlPlans: { type: Array as PropType<Plan[]> },
  },
  emits: ["subscriptionSelected", "applicantsUpdate"],
  setup(props, { emit }) {
    const { isSubscriptionExceeded } = useSubscriptions();
    const { toastError } = useNotify();

    let currentRowId = ref(0);
    let selectedRow = ref({});
    const tableHeader = ref([
      {
        name: "First Name",
        key: "first_name",
        sortable: true,
      },
      {
        name: "Last Name",
        key: "last_name",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "Date of Birth",
        key: "date_of_birth",
        sortable: true,
      },
      {
        name: "Actions",
        key: "edit",
      },
    ]);

    const applicantUploadSheetUrl =
      process.env.VUE_APP_APPLICANT_UPLOAD_SHEET_URL;

    const tableData = ref<Array<any>>(props.initialTableData || []);

    const reloadTableData = (tableD) => {
      tableData.value = [];
      setTimeout(() => {
        tableData.value = tableD as Array<any>;
      }, 100);
    };

    const onApplicantUpdate = (data) => {
      if (currentRowId.value) {
        var existingData = tableData.value.find(
          (x) => x.id == currentRowId.value
        );
        existingData.first_name = data.formData.first_name;
        existingData.last_name = data.formData.last_name;
        existingData.email = data.formData.email;
        existingData.date_of_birth = data.formData.date_of_birth;
        existingData.aml_plan = data.formData.aml_plan;
      } else {
        if (props.isSubscriptionSelected && props.activeSubscription) {
          const isExceeded = isSubscriptionExceeded(
            props.activeSubscription,
            tableData.value.length + 1
          );
          if (isExceeded) {
            return toastError({
              text: `You can not add an applicant as the limit of your subscription will be exceeded`,
            });
          }
        }

        const tableIds = tableData.value.map((x) => x.id) as number[];
        const nextRowId =
          tableIds && tableIds.length > 0 ? Math.max(...tableIds) + 1 : 1;
        tableData.value.push({
          id: nextRowId,
          first_name: data.formData.first_name,
          last_name: data.formData.last_name,
          email: data.formData.email,
          date_of_birth: data.formData.date_of_birth,
          aml_plan: data.formData.aml_plan,
        });
      }
      emit("applicantsUpdate", tableData.value);
    };

    const addRecord = (e) => {
      e.preventDefault();
      currentRowId.value = 0;
      selectedRow.value = {
        id: 0,
      };
    };

    const editRecord = (rowId: number, e) => {
      e.preventDefault();
      currentRowId.value = rowId;
      selectedRow.value = tableData.value.find((x) => x.id == rowId);
    };

    const deleteRecord = (rowId: number, e) => {
      tableData.value = tableData.value.filter((x) => x.id != rowId);
      reloadTableData(tableData.value);
      emit("applicantsUpdate", tableData.value);
    };

    watch(
      () => props.initialTableData,
      (curVal, oldVal) => {
        if (curVal && curVal != oldVal) {
          reloadTableData(curVal);
        }
      }
    );

    const bulkAddRecords = (records) => {
      const tableIds = tableData.value.map((x) => x.id) as number[];
      const nextRowId =
        tableIds && tableIds.length > 0 ? Math.max(...tableIds) + 1 : 1;
      records.forEach((record, idx) => {
        tableData.value.push({
          id: nextRowId + idx,
          first_name: record["First Name"],
          last_name: record["Last Name"],
          email: record["Email"],
          date_of_birth: record["Date Of Birth"],
        });
      });
      emit("applicantsUpdate", tableData.value);
    };

    const remainingVerifications = computed(() => {
      if (!props.activeSubscription) {
        return 0;
      }
      const { count_used, limit } = props.activeSubscription;
      return limit - count_used;
    });

    const selectSubscription = () => {
      emit("subscriptionSelected");
    };

    return {
      currentRowId,
      onApplicantUpdate,
      addRecord,
      editRecord,
      deleteRecord,
      tableHeader,
      tableData,
      selectedRow,
      applicantUploadSheetUrl,
      bulkAddRecords,
      selectSubscription,
      remainingVerifications,
    };
  },
});
