
import { defineComponent } from "vue";
import SummaryCard from "@/components/customer/SummaryCard.vue";
import { PropType } from "vue";
import { Fee } from "@/assets/ts/_utils/models/FeeModel";
import { getCurrentInstance } from "vue";
import { computed } from "vue";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import useCurrencies from "@/core/composables/useCurrencies";

export default defineComponent({
  name: "step-3",
  components: { SummaryCard },
  props: {
    formData: {
      type: Object,
      default: () => ({ applicants: [] }),
    },
    plan: { type: Object as PropType<Plan> },
    fees: { type: Array as PropType<Fee[]>, default: () => [] },
    totalFees: { type: Number, default: 0 },
    totalPrice: { type: Number, default: 0 },
    useActiveSubscription: {
      type: Boolean,
    },
    billedAmount: { type: Number, default: 0 },
    amlTotal: { type: Number, default: 0 },
  },
  setup(props) {
    const app = getCurrentInstance();
    const { getPlanPriceByCurrency } = useCurrencies();

    const feeBreakdown = computed(() =>
      props.fees
        .map(
          (fee) =>
            `${
              fee.name
            } - ${app?.appContext.config.globalProperties.$filters.formatFee(
              fee
            )}`
        )
        .join(", ")
    );
    const amlApplicants = computed(
      () => props.formData.applicants.filter((a) => !!a.aml_plan).length
    );

    return { feeBreakdown, amlApplicants, getPlanPriceByCurrency };
  },
});
