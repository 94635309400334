
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Field, ErrorMessage } from "vee-validate";
import { defaultBGCData } from "@/store/models/form";
import { watch } from "vue";

export default defineComponent({
  name: "step-2",
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    showBack: { type: Boolean, default: true },
    kyc: { type: Object },
  },
  setup(props, { emit }) {
    const backRef = ref<HTMLElement | null>(null);
    const frontRef = ref<HTMLElement | null>(null);

    const formData = ref<any>({
      official_doc: defaultBGCData.kyc.official_doc,
      official_doc_back: defaultBGCData.kyc.official_doc_back,
    });

    let official_doc = ref("");
    let official_doc_back = ref("");
    let official_doc_reset = ref("");
    let official_doc_back_reset = ref("");
    const removeImage = (avatarType) => {
      if (avatarType == "1") {
        official_doc.value = "";
        official_doc_reset.value = "";
        emit("updateFrontField", official_doc.value);
      } else {
        official_doc_back.value = "";
        official_doc_back_reset.value = "";
        emit("updateBackField", official_doc.value);
      }
    };

    const validateImage = (input: HTMLInputElement) => {
      if (!input.files?.length) {
        return;
      }

      const file = input.files[0];
      if (file && file.size / (1024 * 1024) > 2) {
        Swal.fire({
          text: "File cannot be larger than 2 MB",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        return false;
      }

      return true;
    };

    const changeFrontImage = async (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (!input.files?.length) {
        return;
      }
      const file = input.files[0];
      if (file && validateImage(input)) {
        official_doc.value = (await toBase64(file)) as string;
        emit("updateFrontField", official_doc.value);
      }
    };

    const changeBackImage = async (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (!input.files?.length) {
        return;
      }
      const file = input.files[0];
      if (validateImage(input)) {
        official_doc_back.value = (await toBase64(file)) as string;
        emit("updateBackField", official_doc.value);
      }
    };

    const toBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    const handleTriggerBackFile = () => {
      backRef?.value?.click();
    };
    const handleTriggerFrontFile = () => {
      frontRef?.value?.click();
    };

    const setBlobFields = (source) => {
      if (source) {
        official_doc.value = source.official_doc.src;
        official_doc_back.value = source.official_doc_back?.src;
      }
    };

    const triggerFrontUpload = () => {
      (document.getElementById("official_doc") as any).click();
    };

    const triggerBackUpload = () => {
      (document.getElementById("official_doc_back") as any).click();
    };

    watch(
      () => props.kyc,
      (curVal, oldVal) => {
        if (curVal != oldVal) {
          const newData = {
            official_doc:
              curVal?.official_doc || defaultBGCData.kyc.official_doc,
            official_doc_back:
              curVal?.official_doc_back || defaultBGCData.kyc.official_doc_back,
          };
          formData.value = newData;
          setBlobFields(newData);
        }
      }
    );

    return {
      official_doc,
      official_doc_back,
      removeImage,
      changeFrontImage,
      changeBackImage,
      official_doc_reset,
      official_doc_back_reset,
      handleTriggerBackFile,
      handleTriggerFrontFile,
      formData,
      setBlobFields,
      triggerFrontUpload,
      triggerBackUpload,
    };
  },
});
