
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { COMPLETED, IN_REVIEW, SUBMITTED, CENCEL } from "@/constants";
import Accordion from "@/components/Accordion.vue";

import ReviewItemHeader from "@/components/customer/ReviewItemHeader.vue";
import PhotoMatch from "@/components/customer/PhotoMatch.vue";
import Notice from "@/components/Notice.vue";
import { docTypes as KYCDocTypes } from "@/assets/ts/_utils/enums/doc-type.enum";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { AMLVerificationStatus } from "@/assets/ts/_utils/enums/id-verification-status.enum";
import OCRModal from "@/components/modals/OCRModal.vue";
import { changeDateFormat } from "@/core/helpers/date";
import moment from "moment";
import Badge from "../Badge.vue";

export default defineComponent({
  name: "customer-kyc-review",
  components: {
    Accordion,
    ReviewItemHeader,
    PhotoMatch,
    Notice,
    OCRModal,
    Badge,
  },
  data() {
    return {
      AMLVerificationStatus,
      tab: 1,
      screenPdfSrc: "",
      otherPdfSrc: "",
      review: {
        kyc: this.kycID,
        official_doc: false,
        official_doc_back: false,
        live_photo: false,
        doc_type: false,
        desc_official_doc: "",
        desc_self_reported_info: "",
        desc_official_doc_back: "",
        desc_live_photo: "",
        desc_doc_type: "",
      },
      reviewDisable: false,
      reviewErrors: {},
      metadata: {},
      docTypes: ["ID Card", "Passport", "Driving License", "Residence Permit"],
      KYCDocTypes,
      COMPLETED,
    };
  },
  computed: {
    ...mapGetters({
      kyc: "getKyc",
      kycReview: "getKycReview",
      errors: "getKycErrors",
      roles: "getRoles",
      currentUser: "currentUser",
    }),
    kycID() {
      return this.$route.params.kycId;
    },
    allApproved(): boolean {
      return (
        this.review.official_doc &&
        this.review.official_doc_back &&
        this.review.live_photo &&
        this.review.doc_type
      );
    },
    percentageValidity(): number {
      if (!this.review) return 0;

      const ratings = [
        this.kycReview.official_doc,
        this.kycReview.official_doc_back,
        this.kycReview.live_photo,
        this.kycReview.doc_type,
      ];
      const trueCount = ratings.filter(Boolean).length;
      const totalCount = ratings.length;
      const percentage = (trueCount / totalCount) * 100;
      return Math.round(percentage);
    },
    chartOptions() {
      const color =
        this.percentageValidity >= 80
          ? "success"
          : this.percentageValidity >= 50 && this.percentageValidity < 80
          ? "primary"
          : "danger";

      const baseColor = getCSSVariableValue("--bs-" + color);
      const lightColor = getCSSVariableValue("--bs-light-" + color);
      const labelColor = getCSSVariableValue("--bs-" + "gray-700");
      const warningColor = getCSSVariableValue("--bs-" + "warning");

      return {
        chart: {
          fontFamily: "inherit",
          height: 100,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "50%",
            },
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
                fontWeight: "700",
              },
              value: {
                color: labelColor,
                fontSize: "12px",
                fontWeight: "700",
                offsetY: 6,
                show: true,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
            track: {
              background: warningColor,
              strokeWidth: "100%",
            },
          },
        },
        colors: [baseColor],
        stroke: {
          lineCap: "round",
        },
        labels: ["Progress"],
      };
    },
    meta(): any {
      if (!this.kyc?.meta) return null;
      if (typeof this.kyc.meta === "string") return JSON.parse(this.kyc.meta);
      if (typeof this.kyc.meta === "object") return this.kyc.meta;
      return null;
    },
    hasIdExpired(): boolean {
      const expiryDate = this.meta.value?.front?.DateOfExpiration;
      if (!expiryDate) {
        return false;
      }
      const formattedDate = changeDateFormat(expiryDate);
      return moment(formattedDate).isBefore();
    },
  },
  methods: {
    ...mapActions({
      fetchKycByID: Actions.FETCH_KYC_BY_ID,
      fetchKycReviewByID: Actions.FETCH_KYC_REVIEW_BY_ID,
      fetchUser: Actions.USER_DATA,
      userData: Actions.USER_DATA,
      updateKycStatus: Actions.UPDATE_KYC_STATUS,
      getCountries: Actions.GET_COUNTRIES,
      resetKYC: Actions.RESET_KYC,
    }),
    ...mapMutations({
      setError: Mutations.SET_ERROR,
    }),
    getMonth(date: string) {
      const d = new Date(date);
      return `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? "0" : ""}${
        d.getMonth() + 1
      }`;
    },
    async fetchDisclosureFile(src: string) {
      const response = await ApiService.get(src, {
        responseType: "blob",
      } as any);
      if (ApiService.isAxiosResponse(response)) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        return objectUrl;
      }
      return "";
    },
    async submit(completed: boolean) {
      const response = await ApiService.post("kycReview/", this.review as any);
      if (ApiService.isAxiosResponse(response)) {
        await this.updateKycStatus({
          id: this.kyc.id,
          data: { status: completed ? COMPLETED : CENCEL },
        });
        this.$router.push({ name: "admin-kyc-submission" });
      }
      if (ApiService.isAxiosError(response) && response.response) {
        this.reviewErrors = response.response.data;
      }
    },
    async loadData() {
      if (Object.keys(this.kycReview).length !== 0) {
        //this.reviewDisable = true;
        this.review.kyc = this.kycReview.kyc;
        this.review.desc_official_doc = this.kycReview.desc_official_doc;
        this.review.desc_official_doc_back =
          this.kycReview.desc_official_doc_back;
        this.review.desc_live_photo = this.kycReview.desc_live_photo;
        this.review.desc_doc_type = this.kycReview.desc_doc_type;
        this.review.official_doc = this.kycReview.official_doc;
        this.review.official_doc_back = this.kycReview.official_doc_back;
        this.review.live_photo = this.kycReview.live_photo;
        this.review.doc_type = this.kycReview.doc_type;
      }
      if (Object.keys(this.kyc).length !== 0) {
        // if (this.kyc.status === SUBMITTED) {
        //   await this.updateKycStatus({
        //     id: this.kyc.id,
        //     data: { status: IN_REVIEW },
        //   });
        // }
        this.review.kyc = this.kyc.id;

        if (this.kyc) {
          this.metadata = JSON.parse(this.kyc.doc_metadata);
        }
      }
    },
    getStatusMessage(isApproved) {
      return isApproved ? "Approved" : "Declined";
    },
  },
  async mounted() {
    //reset error
    this.setError({});
    this.resetKYC();

    await this.fetchUser();
    if (this.kycID) {
      const fetchedKyc = await this.fetchKycByID(this.kycID);
      console.log("fetchedKyc.review_id :>> ", fetchedKyc.review_id);
      if (fetchedKyc.review_id) {
        await this.fetchKycReviewByID(fetchedKyc.review_id);
      }

      await this.loadData();
    } else {
      this.$router.push({
        name: this.roles.admin ? "admin-404" : "customer-404",
      });
    }
    if (this.roles.customer) {
      this.reviewDisable = true;
    }

    await this.getCountries();
  },
});
