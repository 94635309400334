
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Field, ErrorMessage } from "vee-validate";
import UploadInput from "@/components/client/KYC/uploadInput.vue";
import CaptureQR from "@/components/CaptureQR.vue";
import useMedia from "@/core/composables/useMedia";
import useNotify from "@/core/composables/notify";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "step-2",
  components: {
    Field,
    ErrorMessage,
    UploadInput,
    CaptureQR,
  },
  props: {
    showBack: { type: Boolean, default: true },
    uploadedDocs: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    const backRef = ref<HTMLElement | null>(null);
    const frontRef = ref<HTMLElement | null>(null);
    let showQR = ref(false);
    let fetchingQR = ref(false);
    const qRToken = ref("");
    const fetchingCapture = ref(false);

    let official_doc = ref("");
    let official_doc_back = ref("");
    let official_doc_reset = ref("");
    let official_doc_back_reset = ref("");

    const { toastErrorRaw } = useNotify();
    const { fetchFiles } = useMedia();

    const removeImage = (avatarType) => {
      if (avatarType == "1") {
        official_doc.value = "";
        official_doc_reset.value = "";
        emit("updateFrontField", official_doc.value);
      } else {
        official_doc_back.value = "";
        official_doc_back_reset.value = "";
        emit("updateBackField", official_doc.value);
      }
    };

    const validateImage = (input: HTMLInputElement) => {
      if (!input.files?.length) {
        return;
      }

      const file = input.files[0];
      if (file && file.size / (1024 * 1024) > 2) {
        Swal.fire({
          text: "File cannot be larger than 2 MB",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        return false;
      }

      return true;
    };

    const changeFrontImage = async (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (!input.files?.length) {
        return;
      }
      const file = input.files[0];
      if (file && validateImage(input)) {
        official_doc.value = (await toBase64(file)) as string;
        emit("updateFrontField", official_doc.value);
      }
    };

    const changeBackImage = async (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (!input.files?.length) {
        return;
      }
      const file = input.files[0];
      if (validateImage(input)) {
        official_doc_back.value = (await toBase64(file)) as string;
        emit("updateBackField", official_doc.value);
      }
    };

    const toBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    const handleTriggerBackFile = () => {
      backRef?.value?.click();
    };
    const handleTriggerFrontFile = () => {
      frontRef?.value?.click();
    };

    const updateFrontImage = async (value: any) => {
      console.log("🚀 ~ updateFrontImage ~ value:", value);
      if (value) {
        emit("updateFrontField", value);
      } else {
        return;
      }
    };

    const updateImageObject = async (
      value: string,
      type: "front" | "back" | "selfie"
    ) => {
      if (value) {
        emit("updateFileObject", { value, type });
      } else {
        return;
      }
    };

    const updateBackImage = async (value: any) => {
      if (value) {
        emit("updateBackField", value);
      } else {
        return;
      }
    };

    const showOrHideQR = async () => {
      if (!showQR.value && !qRToken.value) {
        try {
          fetchingQR.value = true;
          const tokenResp = await ApiService.post(`capture-tokens/`, {});
          qRToken.value = tokenResp.data?.token;
        } catch (error) {
          toastErrorRaw(error);
        } finally {
          fetchingQR.value = false;
        }
      }
      showQR.value = !showQR.value;
    };

    const checkCapturedFiles = async () => {
      if (!qRToken.value) {
        throw new Error("Capture not found. Try again shortly");
      }

      try {
        fetchingCapture.value = true;
        const files = await fetchFiles({ search: qRToken.value });
        if (!files?.length) {
          throw new Error("Capture not found. Try again shortly");
        }

        console.log("files :>> ", files);

        const frontDoc = files.find(
          (f) => f.meta?.fileType === "official_doc_file"
        );
        const backDoc = files.find(
          (f) => f.meta?.fileType === "official_doc_back_file"
        );
        // const selfie = files.find((f) => f.meta?.fileType === "live_photo");

        // emit("update:file", file.id);

        if (frontDoc) {
          official_doc.value = frontDoc.url;
          updateFrontImage(frontDoc.id);
        }
        if (backDoc) {
          official_doc_back.value = backDoc.url;
          updateBackImage(backDoc.id);
        }
        // if (selfie) {
        //   official_doc_back.value = backDoc.url;
        //   updateBackImage(backDoc.id);
        // }

        showQR.value = false;
      } catch (error) {
        toastErrorRaw(error);
      } finally {
        fetchingCapture.value = false;
      }
    };

    return {
      official_doc,
      official_doc_back,
      removeImage,
      changeFrontImage,
      changeBackImage,
      official_doc_reset,
      official_doc_back_reset,
      frontRef,
      backRef,
      handleTriggerBackFile,
      handleTriggerFrontFile,
      updateFrontImage,
      updateBackImage,
      showOrHideQR,
      showQR,
      fetchingQR,
      qRToken,
      checkCapturedFiles,
      fetchingCapture,
      updateImageObject,
    };
  },
});
