
import { defineComponent, computed } from "vue";
import { IDVerificationType } from "@/assets/ts/_utils/enums/id-verification-type.enum";
import ActiveSubscriptionCard from "@/components/ActiveSubscriptionCard.vue";
import useCurrencies from "@/core/composables/useCurrencies";

export default defineComponent({
  name: "step-1",
  props: ["selectedVerificationType", "basePlan", "activeSubscription"],
  emits: ["selected", "subscriptionSelected"],
  setup(props, { emit }) {
    const { getPlanPriceByCurrency } = useCurrencies();

    const isBulk = computed(() => {
      return props.selectedVerificationType === IDVerificationType.BULK;
    });
    const isSingle = computed(() => {
      return props.selectedVerificationType === IDVerificationType.SINGULAR;
    });
    const selectVerificationType = (verificationType) => {
      emit("selected", verificationType);
    };
    const selectSubscription = () => {
      emit("subscriptionSelected");
    };
    const remainingVerifications = computed(() => {
      if (!props.activeSubscription) {
        return 0;
      }
      const { count_used, limit } = props.activeSubscription;
      return limit - count_used;
    });
    return {
      isBulk,
      isSingle,
      IDVerificationType,
      selectVerificationType,
      remainingVerifications,
      selectSubscription,
      getPlanPriceByCurrency,
    };
  },
  components: { ActiveSubscriptionCard },
});
